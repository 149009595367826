import {
  Autocomplete,
  Box,
  Button,
  InputAdornment,
  TextField,
  useMediaQuery
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useLidsStore} from "../../../../../../store/lidsStore";
import {useCoatingsStore} from "../../../../../../store/coatingsStore";
const Filter = () => {
  const { t } = useTranslation();
  const media = useMediaQuery('(max-width: 550px)')
  const [isShort, setIsShort] = useState(!media);

  const filter = useLidsStore(state => state.filter)
  const setFilter = useLidsStore(state => state.setFilter)
  const resetFilter = useLidsStore(state => state.resetFilter)

  const coatings = useCoatingsStore(state => state.tableData.data)

  const handleSearch = (e: any, field: string) => {
    setFilter({
      ...filter,
      filters: filter.filters?.find(el => el.field_name === field) ?
        filter.filters?.map(el => el.field_name === field ? ({ ...el, value: e.target.value   }) : el)
        : [
          ...filter.filters,
          {
            field_name: field,
            rule: 'contains',
            value: e.target.value
          }]
      ,
    })
  }
  const handleSelect = (newValue: any, field: string) => {
    setFilter({
      ...filter,
      filters: filter.filters?.find(el => el.field_name === field) ?
        filter.filters?.map(el => el.field_name === field ? ({ ...el, value: newValue?.id ?? '' }) : el)
        : [
          ...filter.filters,
          {
            field_name: field,
            rule: 'contains',
            value: newValue?.id ?? ''
          }]
      ,
    })
  }

  const getValue = (field: string) => {
    return filter?.filters?.find(el => el?.field_name === field)?.value ?? ""
  }

  useEffect(() => {
    return () => {
      resetFilter()
    }
  },[])
  useEffect(() => {
    setIsShort(media)
  },[media])

  return (
    <>
      <Box sx={{
        display: 'flex',
        gap: '10px',
        flexWrap: 'wrap',
        alignItems: 'flex-end',
      }}>
        <TextField
          label={t('common.searchBy.title') +' '+ t('common.searchBy.description_uk') }
          type="search"
          variant="standard"
          onChange={(e) => handleSearch(e, 'name_uk')}
          sx={{
            minWidth: '250px',
          }}
          value={getValue('name_uk')}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        {!isShort && <>
            <TextField
                label={t('common.searchBy.title') +' '+ t('common.searchBy.description_en') }
                type="search"
                variant="standard"
                onChange={(e) => handleSearch(e, 'name_en')}
                sx={{
                  minWidth: '250px',
                }}
                value={getValue('name_en')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
            />
            <TextField
                label={t('common.searchBy.title') +' '+ t('common.searchBy.article') }
                type="search"
                variant="standard"
                onChange={(e) => handleSearch(e, 'article_prefix_1')}
                sx={{
                  minWidth: '250px',
                }}
                value={getValue('article_prefix_1')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
            />

        </>}
        <br />
      </Box>
      {media &&
      <Button
          onClick={() => setIsShort(prev => !prev)}
          variant={'outlined'}
          endIcon={<ExpandMoreIcon sx={{ transform: !isShort ? 'rotate(180deg)' : 'rotate(0deg)' }}/>}
          color={'warning'}
          sx={{
            transition: 'all 0.3s ease',
            '& svg': {
              transition: 'all 0.3s ease',
            }
          }}
      >
        {isShort ? t('common.showMore') : t('common.showLess')}
      </Button>}
  </>
  )
}
export default Filter