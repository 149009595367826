import {TableHead, TableRow} from "@mui/material";
import {useTranslation} from "react-i18next";
import {StyledTableCell} from "./style";

const TraysTableHead = () => {
  const { t } = useTranslation()

  return <TableHead sx={{
    borderBottom: '3px double #ccc'
  }}>
    <TableRow>
      <StyledTableCell>
        {t('trays.id')}
      </StyledTableCell>
      <StyledTableCell>
        {t('trays.name_en')}
      </StyledTableCell>
      <StyledTableCell>
        {t('trays.name_uk')}
      </StyledTableCell>
      <StyledTableCell>
        {t('trays.article_prefix_1')}
      </StyledTableCell>
      <StyledTableCell>
        {t('trays.article_prefix_2')}
      </StyledTableCell>
      <StyledTableCell>
        {t('trays.type')}
      </StyledTableCell>

        {/*<StyledTableCell>*/}
        {/*  {t('trays.coating')}*/}
        {/*</StyledTableCell>*/}
        {/*<StyledTableCell>*/}
        {/*  {t('trays.unit_of_measurement')}*/}
        {/*</StyledTableCell>*/}
        {/*<StyledTableCell>*/}
        {/*  {t('trays.width')}*/}
        {/*</StyledTableCell>*/}
        {/*<StyledTableCell>*/}
        {/*  {t('trays.height')}*/}
        {/*</StyledTableCell>*/}
        {/*<StyledTableCell>*/}
        {/*  {t('trays.thickness')}*/}
        {/*</StyledTableCell>*/}
        {/*<StyledTableCell>*/}
        {/*  {t('trays.length')}*/}
        {/*</StyledTableCell>*/}
        {/*<StyledTableCell>*/}
        {/*  {t('trays.weight')}*/}
        {/*</StyledTableCell>*/}
        {/*<StyledTableCell>*/}
        {/*  {t('trays.angle')}*/}
        {/*</StyledTableCell> example */}
        <StyledTableCell>
          {/* Add any additional columns if needed */}
        </StyledTableCell>
      </TableRow>
  </TableHead>
}

export default TraysTableHead