import {useTranslation} from "react-i18next";
import useToast from "../../../../../hooks/useToast";
import React, {useState} from "react";
import useAsyncWrapper from "../../../../../hooks/useAsyncWrapper";
import {Controller, useForm} from "react-hook-form";
import {
  IRecommendation,
  IEditRecommendation, Product, Installation,
} from "../../../../../interfaces/general";
import {yupResolver} from "@hookform/resolvers/yup";
import {Box, Button, Checkbox, Grid, ListItemText, MenuItem, Select, TextField} from "@mui/material";
import {StyledFormControlLabel} from "../../Settings/SettingsForm/style";
import ButtonLoadingWrapper from "../../../../Wrappers/ButtonLoading";
import createFilteredPayload from "../../../../../utils/removeDuplicate";
import {installations, products} from "../../../../../constants";
import {useCoatingsStore} from "../../../../../store/coatingsStore";
import {getRecommendationEditValidationSchema} from "../../../../../validations/recommendations/editRecommendation";
import {useRecommendationsStore} from "../../../../../store/recommendationsStore";


interface IProps {
  item: IRecommendation;
  handleClose: () => void;

}

const EditRecommendationForm = ({ item, handleClose } : IProps) => {
  const { t } = useTranslation()
  const { errorToast, successToast } = useToast();

  const [isLoading, setIsLoading] = useState(false);

  const editRecommendation = useRecommendationsStore(state => state.editRecommendation);
  const coatings = useCoatingsStore(state => state.tableData.data);

  const editRecommendationHandler = useAsyncWrapper(editRecommendation)

  const initialFormState = {
    place_installation_uk: item?.place_installation_uk ?? '',
    place_installation_en: item?.place_installation_en ?? '',
    coating_ids: item?.coatings?.map(el => el.id) ?? [],
    product: (item.product ?? products[0]) as Product,
    installation: (item.installation ?? installations[0]) as Installation ,
  }
  const schema = getRecommendationEditValidationSchema(t);
  const {handleSubmit, control, watch} = useForm<IEditRecommendation>({
    defaultValues: initialFormState,
    resolver: yupResolver(schema),
  });


  const handleEditSubmit = async (body: IEditRecommendation) => {
    const payload = createFilteredPayload(body, initialFormState)

    setIsLoading(true);
    const { error , status} = await editRecommendationHandler( [item?.id, payload] );
    setIsLoading(false);

    if (status === 400) {
      const errors = error?.response?.data?.message ?? [];
      if(error?.response?.data?.message.length > 0) {
        errors.forEach((err: any) => errorToast(t('error.error'), t('editRecommendation.error.400.' + err?.field)))
      }

      return
    }
    if(!error) {
      successToast(t('editRecommendation.success'))
      handleClose()
      return;
    }
    errorToast(t('editRecommendation.error.default'))
  }


  return (
    <Box
      component={'form'}
      onSubmit={handleSubmit(handleEditSubmit)}
      sx={{
        '@media (min-width: 600px)': {
          minWidth: '350px',
        }
      }}
    >
      <Grid
        container
        spacing={1}
        mb={2}
      >
        <Grid item xs={12} md={12}>
          <Controller
            name={"coating_ids"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                label={t("recommendation.coating")}
                control={(
                  <Select
                    fullWidth
                    sx={{
                      padding: '0px',
                      '& .MuiSelect-select': {
                        padding: '10px 0 10px 10px',
                      }
                    }}
                    renderValue={(selected) => selected.map(id => coatings.find(el => el.id === id)?.code).join(', ')}
                    multiple
                    value={value}
                    onChange={onChange}
                    error={error?.ref?.name === name}
                  >
                    {coatings.map((coating) => {
                      return (
                        <MenuItem key={coating.id} value={coating.id}>
                          <Checkbox checked={value?.includes(coating.id)}/>
                          <ListItemText primary={coating.code}/>
                        </MenuItem>
                      );
                    })}
                  </Select>
                )
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name={"installation"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                label={t("recommendation.installation")}
                control={(
                  <Select
                    fullWidth
                    sx={{
                      padding: '0px',
                      '& .MuiSelect-select': {
                        padding: '10px 0 10px 10px',
                      }
                    }}
                    value={value}
                    onChange={onChange}
                    error={error?.ref?.name === name}
                  >
                    {installations.map((installation) => {
                      return (
                        <MenuItem key={installation} value={installation}>
                          {t('common.'+installation)}
                        </MenuItem>
                      );
                    })}
                  </Select>)
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name={"product"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                label={t("recommendation.product")}
                control={(
                  <Select
                    fullWidth
                    sx={{
                      padding: '0px',
                      '& .MuiSelect-select': {
                        padding: '10px 0 10px 10px',
                      }
                    }}
                    value={value}
                    onChange={onChange}
                    error={error?.ref?.name === name}
                  >
                    {products.map((product) => {
                      return (
                        <MenuItem key={product} value={product}>
                          {product}
                        </MenuItem>
                      );
                    })}
                  </Select>)
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name={"place_installation_uk"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                label={t("recommendation.place_installation_uk")}
                control={
                  <TextField
                    fullWidth
                    onChange={onChange}
                    value={value}
                    error={error?.ref?.name === name}
                    helperText={error ? error.message : ' '}
                  />
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name={"place_installation_en"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                label={t("recommendation.place_installation_en")}
                control={
                  <TextField
                    fullWidth
                    onChange={onChange}
                    value={value}
                    error={error?.ref?.name === name}
                    helperText={error ? error.message : ' '}
                  />
                }
              />
            )}
          />
        </Grid>
      </Grid>
      <ButtonLoadingWrapper isLoading={isLoading}>
        <Button
          variant="contained"
          type="submit"
          fullWidth
          size="large"
          sx={{
            background: '#373737',
            '&:hover': {
              background: '#666',
            }
          }}
        >

          {t("handBook.submitEdit")}
        </Button>
      </ButtonLoadingWrapper>
    </Box>
  )
}
export default EditRecommendationForm