// Initialize i18next
import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import {translationUa, translationEn} from "locales";



i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: translationEn,
            },
            ua: {
                translation: translationUa,
            },
        },
        lng: localStorage.getItem('lang') ?? 'en', // Set the initial language
        fallbackLng: 'en', // Fallback language if the translation is not available
        interpolation: {
            escapeValue: false, // Disable HTML escaping for translations
        },
    });

export default i18n;