import {api} from "../index";
import {
  IFilter,
  IHandbookCreate,
} from "../../interfaces/general";

export const handbooks_api = {
  handbooks: async (params: IFilter) => {
    return await api.get(`handbooks`,{
          params: {
            ...params,
            filters: JSON.stringify(params.filters)
          }
      })
  },
  getHandbook: async (id: number) => {
    return await api.get(`handbooks/${id}`)
  },
  editHandbook: async (id: number, payload: IHandbookCreate) => {
    return await api.post(`handbooks/${id}`, payload, {headers: {
        'Content-Type': 'multipart/form-data'
      }})
  },
  createHandbook: async (data: IHandbookCreate) => {
    return await api.post(`handbooks`, data, {headers: {
        'Content-Type': 'multipart/form-data'
    }})
  },
  deleteHandbook: async (id: number) => {
    return await api.delete(`handbooks/${id}`)
  }

};

