import {Box, Button, FormControlLabel, Grid, Paper, TextField} from "@mui/material";
import ButtonLoadingWrapper from "../../../Wrappers/ButtonLoading";
import {useState} from "react";
import {useUserStore} from "../../../../store/userStore";
import {IRegister} from "../../../../interfaces/general";
import useAsyncWrapper from "../../../../hooks/useAsyncWrapper";
import {Controller, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {yupResolver} from "@hookform/resolvers/yup";
import {getSignUpSchema} from "../../../../validations/auth/signUp";

import useToast from "../../../../hooks/useToast";

import {StyledFormControlLabel} from "./style";
import {useNavigate} from "react-router-dom";
import {RouteUrls} from "../../../../interfaces/enum";

interface IRegisterForm extends IRegister {
    passwordConfirm?: any;
}
const initialFormState = {
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    passwordConfirm: '',
    phone_number: '',
    company: '',
    patronymic: '',
};

const SignUp = () => {
    const { t } = useTranslation();
    const nav = useNavigate()
    const {errorToast, successToast} = useToast()

    const [isLoading, setIsLoading] = useState(false);

    const registerUserAcc = useUserStore(state => state.register);
    const registerUserAccHandler = useAsyncWrapper(registerUserAcc)

    const schema = getSignUpSchema(t)

    const {handleSubmit, control, formState: {errors}} = useForm<IRegisterForm>({
        defaultValues: initialFormState,
        resolver: yupResolver(schema),
    });


    const signUpUser = async (body: IRegisterForm) => {
      delete body.passwordConfirm
      setIsLoading(true)
      const {data, error, status} = await registerUserAccHandler([body])
      setIsLoading(false)
      if (!error) {
        successToast(t('signUp.success'))
        nav(RouteUrls.SIGN_IN)
        return;
      }

      if (status === 400) {
        const errors = error?.response?.data?.message ?? [];
        if(error?.response?.data?.message.length > 0) {
          errors.forEach((err: any) => errorToast(t('error.error'), t('signUp.error.400.' + err?.field)))
        }

        return
      }
      errorToast(t('signUp.error.userExists'))
    }


    return (
        <Box
            component={'form'}
            onSubmit={handleSubmit(signUpUser)}
            sx={{
                maxWidth: '900px',
            }}
        >
            <Grid
                container
                spacing={1}
            >
                <Grid item xs={12} md={6}>
                    <Controller
                        name={"first_name"}
                        control={control}
                        render={({
                                     fieldState: {error},
                                     field: {onChange, value, name},
                                 }) => (
                            <StyledFormControlLabel
                                labelPlacement="top"
                                sx={{
                                    alignItems: 'flex-start'
                                }}
                                label={t("signUp.first_name")}
                                control={
                                    <TextField
                                        fullWidth
                                        onChange={onChange}
                                        value={value}
                                        error={error?.ref?.name === name}
                                        helperText={error ? error.message : ' '}
                                    />
                                }
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        name={"last_name"}
                        control={control}
                        render={({
                                     fieldState: {error},
                                     field: {onChange, value, name},
                                 }) => (
                            <StyledFormControlLabel
                                labelPlacement="top"
                                sx={{
                                    alignItems: 'flex-start'
                                }}
                                label={t("signUp.last_name")}
                                control={
                                    <TextField
                                        fullWidth
                                        onChange={onChange}
                                        value={value}
                                        error={error?.ref?.name === name}
                                        helperText={error ? error.message : ' '}
                                    />
                                }
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        name={"patronymic"}
                        control={control}
                        render={({
                                     fieldState: {error},
                                     field: {onChange, value, name},
                                 }) => (
                            <StyledFormControlLabel
                                labelPlacement="top"
                                sx={{
                                    alignItems: 'flex-start'
                                }}
                                label={t("signUp.patronymic")}
                                control={
                                    <TextField
                                        fullWidth
                                        onChange={onChange}
                                        value={value}
                                        error={error?.ref?.name === name}
                                        helperText={error ? error.message : ' '}
                                    />
                                }
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        name={"email"}
                        control={control}
                        render={({
                                     fieldState: {error},
                                     field: {onChange, value, name},
                                 }) => (
                            <StyledFormControlLabel
                                labelPlacement="top"
                                sx={{
                                    alignItems: 'flex-start'
                                }}
                                label={t("signUp.email")}
                                control={
                                    <TextField
                                        fullWidth
                                        onChange={onChange}
                                        value={value}
                                        error={error?.ref?.name === name}
                                        helperText={error ? error.message : ' '}
                                    />
                                }
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        name={"company"}
                        control={control}
                        render={({
                                     fieldState: {error},
                                     field: {onChange, value, name},
                                 }) => (
                            <StyledFormControlLabel
                                labelPlacement="top"
                                sx={{
                                    alignItems: 'flex-start'
                                }}
                                label={t("signUp.company")}
                                control={
                                    <TextField
                                        fullWidth
                                        onChange={onChange}
                                        value={value}
                                        error={error?.ref?.name === name}
                                        helperText={error ? error.message : ' '}
                                    />
                                }
                            />
                        )}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <Controller
                        name={"phone_number"}
                        control={control}
                        render={({
                                     fieldState: {error},
                                     field: {onChange, value, name},
                                 }) => (
                            <StyledFormControlLabel
                                labelPlacement="top"
                                sx={{
                                    alignItems: 'flex-start'
                                }}
                                label={t("signUp.phone_number")}
                                control={
                                    <TextField
                                        fullWidth
                                        onChange={onChange}
                                        value={value}
                                        error={error?.ref?.name === name}
                                        helperText={error ? error.message : ' '}
                                    />
                                }
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        name={"password"}
                        control={control}
                        render={({
                                     fieldState: {error},
                                     field: {onChange, value, name},
                                 }) => (
                            <StyledFormControlLabel
                                labelPlacement="top"
                                sx={{
                                    alignItems: 'flex-start'
                                }}
                                label={t("signUp.password")}
                                control={
                                    <TextField
                                        fullWidth
                                        type={'password'}
                                        onChange={onChange}
                                        value={value}
                                        error={error?.ref?.name === name}
                                        helperText={error ? error.message : ' '}
                                    />
                                }
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        name={"passwordConfirm"}
                        control={control}
                        render={({
                                     fieldState: {error},
                                     field: {onChange, value, name},
                                 }) => (
                            <StyledFormControlLabel
                                labelPlacement="top"
                                sx={{
                                    alignItems: 'flex-start'
                                }}
                                label={t("signUp.passwordConfirm")}
                                control={
                                    <TextField
                                        fullWidth
                                        type={'password'}
                                        onChange={onChange}
                                        value={value}
                                        error={error?.ref?.name === name}
                                        helperText={error ? error.message : ' '}
                                    />
                                }
                            />
                        )}
                    />
                </Grid>
            </Grid>
            <ButtonLoadingWrapper isLoading={isLoading}>
                <Button
                    variant="contained"
                    type="submit"
                    fullWidth
                    size="large"
                    sx={{
                        background: '#373737',
                        '&:hover': {
                          background: '#666',
                        }
                    }}
                >

                    {t("signUp.submit")}
                </Button>
            </ButtonLoadingWrapper>
        </Box>)
};

export default SignUp;
