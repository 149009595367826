import React from "react";
import {useProjectStore} from "../../../../../../store/projectStore";
import {Box} from "@mui/material";
import useLang from "../../../../../../hooks/useLang";
import {useTranslation} from "react-i18next";
import CartItem from "./CartItem";


const CartList = () => {
  const { getTranslate } = useLang()
  const { t } = useTranslation()
  const project_positions = useProjectStore(state => state.project_positions)

  return <Box sx={{
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    fontSize: '0.875rem',
    flexGrow: 1,
    paddingTop: '5px',
    overflowY: 'auto',
    height: '100%',
    border: '1px solid #ccc',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
    borderRadius: '0 0 12px 12px',
  }}>
    {project_positions?.map((el, index) => <CartItem key={el.id} tray={el} index={index}/> )}
  </Box>
}

export default CartList