import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {StyledFormControlLabel} from "./style";
import {Box, Button, Grid, MenuItem, Select, TextField} from "@mui/material";
import ButtonLoadingWrapper from "../../../../Wrappers/ButtonLoading";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import useAsyncWrapper from "../../../../../hooks/useAsyncWrapper";
import useToast from "hooks/useToast";
import {useImagesStore} from "../../../../../store/imagesStore";
import FileUpload from "../../../Images/components/FileUpload";



const initialFormState = {
  description: '',
}

interface IProps {
  handleClose: () => void;
}

const CreateImageForm = ({ handleClose }: IProps) => {
  const { t } = useTranslation()
  const { errorToast, successToast } = useToast();


  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState<any[]>([]);

  const createImage = useImagesStore(state => state.createImage);
  const createImageHandler = useAsyncWrapper(createImage)

  // const schema = getImagesValidationSchema(t);
  // const {handleSubmit, control } = useForm<IImageCreate>({
  //   defaultValues: initialFormState,
  //   resolver: yupResolver(schema),
  // });

  const handleSetFiles = (files: any) => {
    if(files.length > 1) {
      errorToast(t('images.error.filesLength'));
      return
    }
    setFiles(files)
  }


  const handleCreateSubmit = async (e: any) => { // body: IImageCreate
    e.preventDefault()
    if(!files.length) {
      errorToast(t('images.error.fileRequired'));
      return
    }

    const file = files[0];
    const formData = new FormData();

    formData.append('file', file)


    setIsLoading(true)
    const { error, status } = await createImageHandler( [formData] )
    setIsLoading(false)

    if(!error) {
      successToast(t('images.success'))
      handleClose()
      return;
    }
    if (status === 400) {
      const errors = error?.response?.data?.message ?? [];
      if(error?.response?.data?.message.length > 0) {
        errors.forEach((err: any) => errorToast(t('error.error'), t('images.error.400.' + err?.field)))
      }

      return
    }

    errorToast(t('images.error.default'))
  }


  return (
    <Box
      component={'form'}
      onSubmit={handleCreateSubmit}
      sx={{
        '@media (min-width: 600px)': {
          minWidth: '450px',
        }
      }}
    >
      <Grid
        container
        spacing={1}
        mb={2}
      >
        {/*<Grid item xs={12} md={12}>*/}
        {/*  <Controller*/}
        {/*    name={"description"}*/}
        {/*    control={control}*/}
        {/*    render={({*/}
        {/*               fieldState: {error},*/}
        {/*               field: {onChange, value, name},*/}
        {/*             }) => (*/}
        {/*      <StyledFormControlLabel*/}
        {/*        labelPlacement="top"*/}
        {/*        sx={{*/}
        {/*          alignItems: 'flex-start',*/}
        {/*          '& .MuiInputBase-root': {*/}
        {/*            height: '120px',*/}
        {/*            alignItems: 'flex-start',*/}
        {/*            '& textarea': {*/}
        {/*              overflow: 'auto!important',*/}
        {/*              height: '100%!important'*/}
        {/*            }*/}
        {/*          }*/}
        {/*        }}*/}
        {/*        label={t("images.description")}*/}
        {/*        control={*/}
        {/*          <TextField*/}
        {/*            fullWidth*/}
        {/*            onChange={onChange}*/}
        {/*            value={value}*/}
        {/*            multiline*/}
        {/*            error={error?.ref?.name === name}*/}
        {/*            helperText={error ? error.message : ' '}*/}
        {/*          />*/}
        {/*        }*/}
        {/*      />*/}
        {/*    )}*/}
        {/*  />*/}
        {/*</Grid>*/}
        <Grid item xs={12} md={12}>
          <FileUpload handleChange={handleSetFiles} file={files}/>
        </Grid>
      </Grid>
      <ButtonLoadingWrapper isLoading={isLoading}>
        <Button
          variant="contained"
          type="submit"
          fullWidth
          size="large"
          sx={{
            background: '#373737',
            '&:hover': {
              background: '#666',
            }
          }}
        >

          {t("images.submitCreate")}
        </Button>
      </ButtonLoadingWrapper>
    </Box>
  )
}
export default CreateImageForm