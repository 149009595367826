import {Controller, useForm} from "react-hook-form";
import {ICreateUserForm, IEditUserForm} from "../../../../../interfaces/general";
import {yupResolver} from "@hookform/resolvers/yup";
import {StyledFormControlLabel} from "./style";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Tooltip
} from "@mui/material";
import ButtonLoadingWrapper from "../../../../Wrappers/ButtonLoading";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import useAsyncWrapper from "../../../../../hooks/useAsyncWrapper";
import useToast from "hooks/useToast";
import {getCreateUserValidationSchema} from "../../../../../validations/usersTable/createUser";
import {useUsersTableStore} from "../../../../../store/usersTableStore";
import {useGeneralStore} from "../../../../../store/generalStore";
import {useLocation, useNavigate} from "react-router-dom";
import {useAdminsTableStore} from "../../../../../store/adminsTableStore";
import {adminRoles} from "../../../../../constants";
import usePermission from "../../../../../hooks/usePermission";


interface IProps {
  handleClose: () => void;
}

const CreateUserForm = ({ handleClose }: IProps) => {
  const { t } = useTranslation()
  const isAdmin = useLocation().pathname === '/admins'
  const { errorToast, successToast } = useToast();
  const { isRoot } = usePermission()

  const [isLoading, setIsLoading] = useState(false);

  const roles = useGeneralStore(state => state.roles);
  const createUserAdmin = useAdminsTableStore(state => state.createUser)
  const createUser = useUsersTableStore(state => state.createUser);
  const permissions = useGeneralStore(state => state.permissions);


  const getUsers = useUsersTableStore(state => state.getUsers)
  const filter = useUsersTableStore(state => state.filter);
  const page = useUsersTableStore(state => state.page);

  const getAdmins = useAdminsTableStore(state => state.getUsers)
  const filterAdmins = useAdminsTableStore(state => state.filter);
  const pageAdmins = useAdminsTableStore(state => state.page);

  const getAdminsHandler = useAsyncWrapper(getAdmins);
  const getUsersHandler = useAsyncWrapper(getAdminsHandler);
  const createUserHandler = useAsyncWrapper(isAdmin ? createUserAdmin : createUser)

  const schema = getCreateUserValidationSchema(t);
  const initialFormState = {
    email: '',
    first_name: '',
    last_name: '',
    phone_number: '',
    company: '',
    patronymic:  '',
    role_id: roles?.find(el => el.name === (isAdmin ? 'ROLE_ADMIN' : 'ROLE_USER'))?.id ?? 1,
    passwordConfirm: '',
    password: '',
    permission_ids: []
  }

  const {handleSubmit, control, watch } = useForm<ICreateUserForm>({
    defaultValues: initialFormState,
    resolver: yupResolver(schema),
  });


  const handleCreateSubmit = async (body: ICreateUserForm) => {
    delete body.passwordConfirm

    setIsLoading(true)
    const { error, status } = await createUserHandler( [body] )
    setIsLoading(false)

    if(!error) {
      successToast(t('createUser.success'))
      handleClose()
      if(isAdmin) {
        const customFilter = [
          {"field_name":"role_id","rule":"contains","value": `${roles?.find(el => el.name === 'ROLE_SUPER_ADMIN')?.id ?? ''}`},
          {"field_name":"role_id","rule":"contains","value": `${roles?.find(el => el.name === 'ROLE_ADMIN')?.id ?? ''}`}]

        const props = {
          ...filter,
          filters: [...customFilter, ...filterAdmins.filters]
        }
        await getAdminsHandler([props, pageAdmins])
        return;
      }

      const customFilter = [
        {"field_name":"role_id","rule":"contains","value": `${roles?.find(el => el.name === 'ROLE_USER')?.id ?? ''}`}]

      const props = {
        ...filter,
        filters: [...customFilter, ...filter.filters]
      }

      await getUsersHandler([props, page]);
      return;
    }
    if (status === 400) {
      const errors = error?.response?.data?.message ?? [];
      if(error?.response?.data?.message.length > 0) {
        errors.forEach((err: any) => errorToast(t('error.error'), t('createUser.error.400.' + err?.field)))
      }

      return
    }

    errorToast(t('createUser.error.default'))
  }


  return (
    <Box
      component={'form'}
      onSubmit={handleSubmit(handleCreateSubmit)}
      sx={{
        '@media (min-width: 600px)': {
          minWidth: '350px',
        }
      }}
    >
      <Grid
        container
        spacing={1}
        mb={2}
      >
        <Grid item xs={12} md={12}>
          <Controller
            name={"role_id"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                label={t("createUser.role")}
                control={(
                  <Select
                    fullWidth
                    sx={{
                      padding: '0px',
                      maxHeight: '40px',
                      '& .MuiSelect-select': {
                        padding: '10px 0 10px 10px',
                      }
                    }}
                    value={value}
                    onChange={onChange}
                    disabled={!isAdmin}
                    error={error?.ref?.name === name}
                  >
                    {roles.map((role) => {
                      if(!isAdmin || adminRoles.includes(role.name) )
                        return 'ROLE_SUPER_ADMIN' === role?.name && !isRoot ? <></> :(
                          <MenuItem key={role.id} value={role.id}>
                            {role.name}
                          </MenuItem>
                        );
                    })}
                  </Select>)
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name={"first_name"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                label={t("createUser.first_name")}
                control={
                  <TextField
                    fullWidth
                    onChange={onChange}
                    value={value}
                    error={error?.ref?.name === name}
                    helperText={error ? error.message : ' '}
                  />
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name={"last_name"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                label={t("createUser.last_name")}
                control={
                  <TextField
                    fullWidth
                    onChange={onChange}
                    value={value}
                    error={error?.ref?.name === name}
                    helperText={error ? error.message : ' '}
                  />
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name={"patronymic"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                label={t("createUser.patronymic")}
                control={
                  <TextField
                    fullWidth
                    onChange={onChange}
                    value={value}
                    error={error?.ref?.name === name}
                    helperText={error ? error.message : ' '}
                  />
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name={"email"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                label={t("createUser.email")}
                control={
                  <TextField
                    fullWidth
                    onChange={onChange}
                    value={value}
                    error={error?.ref?.name === name}
                    helperText={error ? error.message : ' '}
                  />
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name={"company"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                label={t("createUser.company")}
                control={
                  <TextField
                    fullWidth
                    onChange={onChange}
                    value={value}
                    error={error?.ref?.name === name}
                    helperText={error ? error.message : ' '}
                  />
                }
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            name={"phone_number"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                label={t("createUser.phone_number")}
                control={
                  <TextField
                    fullWidth
                    onChange={onChange}
                    value={value}
                    error={error?.ref?.name === name}
                    helperText={error ? error.message : ' '}
                  />
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name={"password"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                label={t("signUp.password")}
                control={
                  <TextField
                    fullWidth
                    type={'password'}
                    onChange={onChange}
                    value={value}
                    error={error?.ref?.name === name}
                    helperText={error ? error.message : ' '}
                  />
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name={"passwordConfirm"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                label={t("signUp.passwordConfirm")}
                control={
                  <TextField
                    fullWidth
                    type={'password'}
                    onChange={onChange}
                    value={value}
                    error={error?.ref?.name === name}
                    helperText={error ? error.message : ' '}
                  />
                }
              />
            )}
          />
        </Grid>
        {isAdmin && <Grid item xs={12} md={12}>
          <Controller
            name={"permission_ids"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <Tooltip title={t(watch('role_id') === roles?.find(el => el?.name === 'ROLE_ADMIN')?.id  ? '' : watch('role_id') === roles?.find(el => el?.name === 'ROLE_SUPER_ADMIN')?.id ? 'common.allPermissions' : 'common.userPermissions')}>
                <StyledFormControlLabel
                  labelPlacement="top"
                  sx={{
                    alignItems: 'flex-start'
                  }}
                  label={t("createUser.permissions")}
                  control={(
                      <Select
                        fullWidth
                        disabled={roles.find(el => el.name === 'ROLE_ADMIN')?.id !== watch('role_id')}
                        sx={{
                          padding: '0px',
                          maxHeight: '40px',
                          '& .MuiSelect-select': {
                            padding: '10px 0 10px 10px',
                          }
                        }}
                        value={value}
                        onChange={onChange}
                        multiple
                        renderValue={(selected) => selected.map(id => t('permissions.' + permissions.find(el => el.id === id)?.technical_name)).join(', ')}
                        error={error?.ref?.name === name}
                      >
                        {permissions.map((per) => {
                          return (
                            <MenuItem key={per.id} value={per.id}>
                              <Checkbox checked={value?.includes(per.id)}/>
                              <ListItemText primary={t('permissions.' +per.technical_name)}/>
                            </MenuItem>
                          );
                        })}
                      </Select>)
                  }
                />
              </Tooltip>
            )}
          />
        </Grid>}
      </Grid>
      <ButtonLoadingWrapper isLoading={isLoading}>
        <Button
          variant="contained"
          type="submit"
          fullWidth
          size="large"
          sx={{
            background: '#373737',
            '&:hover': {
              background: '#666',
            }
          }}
        >

          {t("createUser.submit")}
        </Button>
      </ButtonLoadingWrapper>
    </Box>
  )
}
export default CreateUserForm