import {StyledWrapper} from "./style";
import {IHandbook} from "../../../../../../interfaces/general";
import {env, handbooksType} from "../../../../../../constants";
import {Box, Button, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import EditHandbook from "../../../../Modals/EditHandbook";
import {useState} from "react";
import ConfirmDelete from "../../../../Modals/ConfirmDelete";
import {useHandbooksStore} from "../../../../../../store/handbooksStore";
import useAsyncWrapper from "../../../../../../hooks/useAsyncWrapper";
import useToast from "../../../../../../hooks/useToast";
import usePermissions from "../../../../../../hooks/usePermission";
import {PermissionsUser} from "../../../../../../interfaces/enum";

interface IProps {
  handbook: IHandbook
}

const getTypeColor = (type: number) => {
  if(type === 1) return '#3876BF';
  if(type === 2) return '#DA281A';
  if(type === 3) return '#EE9322';
  return '#fff'
}

const HandbookCard = ({ handbook } : IProps) => {
  const { t } = useTranslation();
  const { successToast, errorToast } = useToast();
  const { hasPermission, isUser } = usePermissions()
  const [open, setOpen] = useState({ del: false, edit: false })

  const deleteHandbook = useHandbooksStore(state => state.deleteHandbook)
  const deleteHandbookHandler = useAsyncWrapper(deleteHandbook)


  const handleDeleteHandbook = async (id: number) => {
    const {error} =  await deleteHandbookHandler([id]);

    if(!error) {
      successToast(t('deleteHandbook.success'))
      return
    }
    errorToast(t('deleteHandbook.error'))
  }

  const handleClose = () => {
    setOpen({ del: false, edit: false })
  }

  const handleOpenEdit = () => {
    setOpen({ del: false, edit: true })
  }


  const handleOpenDelete = () => {
    setOpen({ del: true, edit: false })
  }


  const handleOpenFile = () => {
    window.open(env.REACT_APP_DEV_BASE_API_URL + '/' + handbook?.file_url, '_blank')
  }

  return <StyledWrapper sx={{
    backgroundColor: handbook.hidden ? 'rgb(238,36,0, 0.1)' : 'rgba(0, 171, 102, 0.1)',
    borderColor: handbook.hidden ? 'rgb(238,36,0, 0.8)' : 'rgba(0, 171, 102, 0.8)',
  }}>
    <Box
      onClick={handleOpenFile}
      component={'img'}
      sx={{
        position: 'absolute',
        top: '0px',
        cursor: 'pointer',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        border: '5px solid #fff',
        height: '90px',
        width: '90px',
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
        borderRadius: '50%',
        '@media (max-width: 1350px)': {
          height: '70px',
          width: '70px',
        },
        '@media (max-width: 1200px)': {
          height: '60px',
          width: '60px'
        }
      }}
      src={'./pdf.png'} alt={handbook.file_name}
    />
    <Typography sx={{
      display: 'inline-block',
      borderRadius: '8px',
      background: handbook.hidden ? 'red' : 'green',
      position: 'absolute',
      top: '10px',
      right: '10px',
      fontSize: '14px',
      fontWeight: '600',
      padding: '5px 8px',
      color: '#fff',
      '@media (max-width: 1350px)': {
        fontSize: '12px',
        padding: '3px 6px',
      },
      '@media (max-width: 1200px)': {
        fontSize: '10px',
      }
    }}>
      {t(handbook.hidden ? 'handBook.hidden' : 'handBook.nonHidden')}

    </Typography>
   <Typography sx={{
      display: 'inline-block',
      borderRadius: '8px',
      background: getTypeColor(+handbook.type),
      position: 'absolute',
      top: '10px',
      left: '10px',
      fontSize: '14px',
      fontWeight: '600',
      padding: '5px 8px',
      color: '#fff',
     '@media (max-width: 1350px)': {
       fontSize: '12px',
       padding: '3px 6px',
     },
     '@media (max-width: 1200px)': {
       fontSize: '10px',
     }
    }}>
     {t('handBook.type.' + handbooksType.find(item => +item.value === +handbook.type)?.label)}
    </Typography>

    <Typography
      variant='h5'
      textAlign={'center'}
      sx={{
        marginTop: '60px',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        '@media (max-width: 1350px)': {
          marginTop: '40px',
        },
        '@media (max-width: 1200px)': {
          marginTop: '35px',
        }
      }}
    >
      {handbook.name}
    </Typography>
    <Typography
      variant='body2'
      textAlign={'center'}
      sx={{
        display: '-webkit-box',
        WebkitLineClamp: 4,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        flexGrow: 1,
      }}
    >
      {handbook.description}
    </Typography>
    {!isUser && <Box sx={{
      display: 'flex',
      justifyContent: 'space-evenly',
      gap: '15px'
    }}>
      <Button
        variant={'outlined'}
        color={'warning'}
        disabled={!hasPermission(PermissionsUser.handBookUpdate)}
        fullWidth
        onClick={handleOpenEdit}
        sx={{
          fontWeight: '600'
        }}
        startIcon={<EditIcon color={'warning'}/>}
      >
        {t('common.edit')}
      </Button>
      <Button
        variant={'outlined'}
        fullWidth
        disabled={!hasPermission(PermissionsUser.handBookDestroy)}
        sx={{
          fontWeight: '600'
        }}
        color={'error'}
        onClick={handleOpenDelete}
        startIcon={<DeleteIcon color={'error'}/>}
      >
        {t('common.delete')}
      </Button>

    </Box>}
    <ConfirmDelete
      text={t('deleteHandbook.submissionText')}
      title={t('deleteHandbook.title')}
      handleSubmit={() => handleDeleteHandbook(handbook.id)}
      handleClose={handleClose}
      open={open.del}
    />
    <EditHandbook id={handbook.id} open={open.edit} handleClose={handleClose}/>

  </StyledWrapper>
}

export default HandbookCard