import {Box, FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useHandbooksStore} from "../../../../../store/handbooksStore";
import {handbookLangs, handbooksType} from "../../../../../constants";

const Filter = () => {
  const { t } = useTranslation();

  const filter = useHandbooksStore(state => state.filter)
  const setFilter = useHandbooksStore(state => state.setFilter)
  const resetFilter = useHandbooksStore(state => state.resetFilter)

  const handleSearch = (e: any, field: string) => {

    setFilter({
      ...filter,
      filters: filter.filters?.find(el => el.field_name === field) ?
        filter.filters?.map(el => el.field_name === field ? ({ ...el, value: e.target.value   }) : el)
        : [
          ...filter.filters,
          {
            field_name: field,
            rule: 'contains',
            value: e.target.value
          }]
      ,
    })
  }


  const getValue = (field: string) => {
    return filter?.filters?.find(el => el?.field_name === field)?.value ?? ""
  }

  useEffect(() => {
    return () => {
      resetFilter()
    }
  },[])
  return (
    <Box sx={{
      display: 'flex',
      gap: '10px',
      flexWrap: 'wrap',
      alignItems: 'flex-end',
    }}>
      <TextField
        label={t('common.searchBy.title') +' '+ t('common.searchBy.name') }
        type="search"
        variant="standard"
        onChange={(e) => handleSearch(e, 'name')}
        sx={{
          minWidth: '250px',
        }}
        value={getValue('name')}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <FormControl variant="filled" sx={{
        minWidth: 120,
        '& .MuiFormLabel-root': {
          top: getValue('locale') ? '0' : '10px'
        }
      }}>
        <InputLabel id="demo-simple-select-filled-label">{t('common.searchBy.locale')}</InputLabel>
        <Select
          sx={{
            padding: '0px',
            maxHeight: '40px',
            minWidth: '210px',
            '& .MuiSelect-select': {
              padding: '10px 0 10px 10px',
            }
          }}
          variant={'standard'}
          value={getValue('locale')}
          labelId="filter"
          placeholder={t('common.fieldForSearch')}
          onChange={(e) => handleSearch(e, 'locale')}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {handbookLangs.map((lang) => {
            return (
              <MenuItem key={lang} value={lang}>
                {lang}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <FormControl variant="filled" sx={{
        minWidth: 120,
        '& .MuiFormLabel-root': {
          top: getValue('type') ? '0' : '10px'
        }
      }}>
        <InputLabel id="demo-simple-select-filled-label">{t('common.searchBy.type')}</InputLabel>
        <Select
          sx={{
            padding: '0px',
            maxHeight: '40px',
            minWidth: '210px',
            '& .MuiSelect-select': {
              padding: '10px 0 10px 10px',
            }
          }}
          variant={'standard'}
          value={getValue('type')}
          labelId="filter"
          placeholder={t('common.fieldForSearch')}
          onChange={(e) => handleSearch(e, 'type')}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {handbooksType.map((type) => {
            return (
              <MenuItem key={type.value + type.label} value={type.value}>
                {t('handBook.type.' + type.label)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  )
}
export default Filter