import {api} from "../index";
import {
  ICreateCoatingForm,
  ICreateUserForm, IEditCoating,
  IFilter,
} from "../../interfaces/general";

interface ICreateUserApi extends ICreateUserForm {

}

export const coatings_api = {
  coatings: async (params: IFilter) => {
    return await api.get(`coatings`,{
          params: {
            ...params,
            filters: JSON.stringify(params.filters)
          }
      })
  },
  getCoating: async (id: number) => {
    return await api.get(`coatings/${id}`)
  },
  editCoating: async (id: number, payload: IEditCoating) => {
    return await api.patch(`coatings/${id}`, payload)
  },
  createCoating: async (data: ICreateCoatingForm) => {
    return await api.post(`coatings`, data)
  },
  deleteCoating: async (id: number) => {
    return await api.delete(`coatings/${id}`)
  }

};

