import {api} from "../index";
import {ICreateUserForm, IEditUser, IEditUserForm, IFilter, ILogin, IRegister} from "../../interfaces/general";

interface ICreateUserApi extends ICreateUserForm {
  active: boolean
  blocked: boolean
  permission_ids?: number[]
}

export const users_api = {
  users: async (params: IFilter) => {
    return await api.get(`users`,{
          params: {
            ...params,
            filters: JSON.stringify(params.filters)
          }
      })
  },
  getUser: async (id: number) => {
    return await api.get(`users/${id}`)
  },
  editUser: async (id: number, payload: IEditUser) => {
    return await api.patch(`users/${id}`, payload)
  },
  createUser: async (data: ICreateUserApi) => {
    return await api.post(`users`, data)
  },
  deleteUser: async (id: number) => {
    return await api.delete(`users/${id}`)
  }

};

