import { create } from "zustand";
import {
  IFilter, IHandbookCreate,
  IResponseTable,

} from "../interfaces/general";

import {handbooks_api} from "../api/apis/handbooks.api";

interface IHandbooksState {
  isLoading: boolean,
  isTableLoading: boolean,
  tableData: IResponseTable
  filter: IFilter,
  page: number,
}
interface IHandbooksStore extends IHandbooksState{
  setPage: (page: number) => void
  setFilter: (filter: IFilter) => void
  getHandbooks: (params: IFilter, page: number) => Promise<any>,
  createHandbook: (payload: IHandbookCreate) => Promise<any>,
  deleteHandbook: (id: number) => Promise<any>,
  getHandbook: (id: number) => Promise<any>,
  resetFilter: () => void,
  editHandbook: (id: number, payload: IHandbookCreate) => Promise<any>,
}

const initialState = {
  isLoading: false,
  tableData: {
    data: [],
    meta: {
      total: 0,
      current_page: 0,
      last_page: 0,
      perpage: 0,
    }
  },
  filter: {
    filters: [],
    perpage: 15,
    page: -1,
  },
  page: 0,
  isTableLoading: true,
}

export const useHandbooksStore = create<IHandbooksStore>((set) => ({
  ...initialState,
  setPage: (page) => {
    set(state => ({...state, page}))
  },
  setFilter: (filter) => {
    set(state => ({...state, filter, page: 0 }))
  },
  resetFilter: () => {
    set(state => ({...state, filter: initialState.filter, page: 0 }))
  },
  getHandbooks: async (params, page) => {
    set(state => ({...state, isTableLoading: true}))
    const response = await handbooks_api.handbooks({...params, page: page + 1})
      .finally(() => set(state => ({...state, isTableLoading: false})));

    const { status, data } = response;

    if (!status || !data) {
      throw new Error("No status code or data returned from server.");
    }

    if (status !== 200) {
      throw new Error(data);
    }

    set((state) => ({
      ...state,
      tableData: data
    }));
  },
  createHandbook: async (payload) => {
    const response = await handbooks_api.createHandbook(payload);

    const { status, data } = response;

    if (!status || !data) {
      throw new Error("No status code or data returned from server.");
    }

    if (status !== 201) {
      throw new Error(data);
    }

    set((state) => ({
      ...state,
      tableData: {
        ...state.tableData,
        data: [...state.tableData.data, data.data]
      }
    }));

    return data;

  },
  deleteHandbook: async (id) => {
    const response = await handbooks_api.deleteHandbook(id);

    const { status, data } = response;

    if (status !== 204) {
      throw new Error(data);
    }
    set((state) => ({
      ...state,
      tableData: {
        ...state.tableData,
        data: state.tableData.data.filter((item) => item.id !== id),
      },
    }));
  },
  getHandbook: async (id) => {
    const response = await handbooks_api.getHandbook(id);

    const { status, data } = response;

    if (status !== 200) {
      throw new Error(data);
    }
    set((state) => ({
      ...state,

    }));
    return data;
  },
  editHandbook: async (id, payload) => {
    const response = await handbooks_api.editHandbook(id, payload)

    const { status, data } = response;


    set((state) => ({
      ...state,
      tableData: {
        ...state.tableData,
        data: state.tableData.data.map((item) => item.id === id ? data.data : item),
      },
    }));
    return data;
  }
}));

export const useHandbooksStoreOut = useHandbooksStore.getState();