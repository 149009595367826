import { create } from "zustand";
import {
  ICreateTrayForm,
  IEditTray,
  IFilter,
  IResponseTable,
  IRole
} from "../interfaces/general";
import {trays_api} from "../api/apis/trays.api";


interface ITrayState {
  isLoading: boolean,
  isTableLoading: boolean,
  tableData: IResponseTable
  filter: IFilter,
  page: number,
}
interface ITrayStore  extends ITrayState{
  setPage: (page: number) => void
  setFilter: (filter: IFilter) => void
  getTrays: (params: IFilter, page: number) => Promise<any>,
  createTray: (payload: ICreateTrayForm) => Promise<any>,
  deleteTray: (id: number) => Promise<any>,
  resetFilter: () => void,
  getTray: (id: number) => Promise<any>,
  editTray: (id: number, payload: IEditTray) => Promise<any>,
}

const initialState = {
  isLoading: false,
  tableData: {
    data: [],
    meta: {
      total: 0,
      current_page: 0,
      last_page: 0,
      perpage: 0,
    }
  },
  filter: {
    filters: [],
    perpage: 15,
    page: -1,
  },
  page: 0,
  isTableLoading: true,
}

export const useTraysStore = create<ITrayStore>((set) => ({
  ...initialState,
  setPage: (page) => {
    set(state => ({...state, page}))
  },
  setFilter: (filter) => {
    set(state => ({...state, filter, page: 0 }))
  },
  resetFilter: () => {
    set(state => ({...state, filter: initialState.filter, page: 0 }))
  },
  getTrays: async (params, page) => {
    set(state => ({...state, isTableLoading: true}))
    const response = await trays_api.trays({...params, page: page + 1})
      .finally(() => set(state => ({...state, isTableLoading: false})));

    const { status, data } = response;

    if (!status || !data) {
      throw new Error("No status code or data returned from server.");
    }

    if (status !== 200) {
      throw new Error(data);
    }

    set((state) => ({
      ...state,
      tableData: data
    }));
  },
  createTray: async (payload: ICreateTrayForm) => {
    const response = await trays_api.createTray({
      ...payload,
    });

    const { status, data } = response;

    if (!status || !data) {
      throw new Error("No status code or data returned from server.");
    }

    if (status !== 201) {
      throw new Error(data);
    }

    set((state) => ({
      ...state,
      tableData: {
        ...state.tableData,
        data: [data.data, ...state.tableData.data]
      }
    }));

    return data;

  },
  deleteTray: async (id) => {
    const response = await trays_api.deleteTray(id);

    const { status, data } = response;

    if (status !== 204) {
      throw new Error(data);
    }
    set((state) => ({
      ...state,
      tableData: {
        ...state.tableData,
        data: state.tableData.data.filter((item) => item.id !== id),
      },
    }));
  },
  getTray: async (id) => {
    const response = await trays_api.getTray(id);

    const { status, data } = response;

    if (status !== 200) {
      throw new Error(data);
    }
    set((state) => ({
      ...state,
      // tableData: {
      //   ...state.tableData,
      //   data: state.tableData.data.filter((item) => item.id !== id),
      // },
    }));
    return data;
  },
  editTray: async (id, payload) => {
    const response = await trays_api.editTray(id, payload);

    const { status, data } = response;


    set((state) => ({
      ...state,
      tableData: {
        ...state.tableData,
        data: state.tableData.data.map((item) => item.id === id ? data.data : item),
      },
    }));
    return data;
  }
}));

export const useTraysStoreOut = useTraysStore.getState();