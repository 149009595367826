import SignUp from "../../components/UI/Auth/SignUp";
import {Box, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import AlreadyHaveAnAccount from "../../components/UI/AlreadyhHaveAnAccount";
import Logo from "../../components/UI/Logo";
import React, {useEffect} from "react";
import {useUserStore} from "../../store/userStore";
import {useNavigate} from "react-router-dom";
import {RouteUrls} from "../../interfaces/enum";
import ButtonPrivacyPolicy from "../../components/UI/ButtonPrivacyPolicy";

const SignUpPage = () => {
  const { t } = useTranslation()
  const nav = useNavigate()
  const user = useUserStore(state => state.user)

  useEffect(() => {
    if(user) {
      nav(RouteUrls.PROJECTS)
    }
  },[user])
  return (
    <Box
        component={'section'}
        sx={{
            padding: '20px 20px',
            height: '100%',
            width: 'fit-content',
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            justifyContent: 'center',
            flexGrow: 1,
            alignSelf: 'center',
        }}
    >
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '2px solid #373737',
        paddingBottom: '5px',
      }}>
        <Typography variant={'h4'}
                    fontWeight={600}
                    color={'#2d2d2d'}
        >
          {t('signUp.title')}
        </Typography>
        <Logo width={'150px'}/>
      </Box>
      <SignUp />
      <AlreadyHaveAnAccount />
      <ButtonPrivacyPolicy />
    </Box>
  )
};

export default SignUpPage;
