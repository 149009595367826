import React, {useEffect} from "react";
import useAsyncWrapper from "../../hooks/useAsyncWrapper";
import UsersTable from "../../components/UI/Tables/UsersTable";
import {useUsersTableStore} from "../../store/usersTableStore";
import LidsTable from "../../components/UI/Tables/LidsTable";
import {useLidsStore} from "../../store/lidsStore";
import {useCoatingsStore} from "../../store/coatingsStore";
import ChooseImage from "../../components/UI/Modals/ChooseImages";
import {useImagesStore} from "../../store/imagesStore";

let prevFilter = {}
const Lids = () => {

  const getLids = useLidsStore(state => state.getLids)
  const filter = useLidsStore(state => state.filter);
  const getCoatings = useCoatingsStore(state => state.getCoatings);
  const filterSelect = useCoatingsStore(state => state.filterSelect);
  const filterImages = useImagesStore(state => state.modal.filterModal);
  const getImages = useImagesStore(state => state.getImages);


  const getLidsHandler = useAsyncWrapper(getLids);
  const getCoatingsHandler = useAsyncWrapper(getCoatings);
  const getImagesHandler = useAsyncWrapper(getImages);

  const page = useLidsStore(state => state.page);


  useEffect(() => {
    (async () => {
      if(JSON.stringify(prevFilter) !== JSON.stringify(filter) || filter.page !== page) {
        prevFilter = filter
        await getLidsHandler([filter, page])
      }
    })();
  },[filter, page])

  useEffect(() => {
    (async () => {
      await getCoatingsHandler([filterSelect, 0])
      await getImagesHandler([filterImages, 0, true])

    })();
  },[])

  return (
      <>
        <LidsTable />
        <ChooseImage />
      </>
  );
};

export default Lids;
