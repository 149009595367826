import React, {useEffect} from "react";
import useAsyncWrapper from "../../hooks/useAsyncWrapper";
import {useAdminsTableStore} from "../../store/adminsTableStore";
import AdminsTable from "../../components/UI/Tables/AdminsTable";
import {useUsersTableStore} from "../../store/usersTableStore";
import {useGeneralStore} from "../../store/generalStore";

let prevFilter = {}
const Users = () => {

  const getUsers = useAdminsTableStore(state => state.getUsers)
  const filter = useAdminsTableStore(state => state.filter);
  const roles = useGeneralStore(state => state.roles);

  const getUsersHandler = useAsyncWrapper(getUsers);

  const page = useAdminsTableStore(state => state.page);

  useEffect(() => {
    (async () => {
      if(JSON.stringify(prevFilter) !== JSON.stringify(filter) || filter.page !== page) {
        prevFilter = filter

        const customFilter = [
          {"field_name":"role_id","rule":"contains","value": `${roles?.find(el => el.name === 'ROLE_SUPER_ADMIN')?.id ?? ''}`},
          {"field_name":"role_id","rule":"contains","value": `${roles?.find(el => el.name === 'ROLE_ADMIN')?.id ?? ''}`}]

        const props = {
          ...filter,
          filters: [...customFilter, ...filter.filters]
        }

        await getUsersHandler([props, page])
      }
    })();
  },[filter, page])
  return (
    <>
      <AdminsTable />
    </>

  );
};

export default Users;
