import { create } from "zustand";
import {IRole} from "../interfaces/general";
import {roles_api} from "../api/apis/roles.api";
import {permissions_api} from "../api/apis/permissions.api";
import {system_api} from "../api/apis/system.api";


interface State {
  roles: IRole[],
  permissions: any[]
  emails: any[]
}

interface IStore extends State {
  getRoles: () => Promise<any>,
  getPermissions: () => Promise<any>
  getSystemSettings: () => Promise<any>
  editEmails: (payload: any) => Promise<any>

}

const initialState: State = {
  roles: [],
  permissions: [],
  emails: []
};

export const useGeneralStore = create<IStore>((set) => ({
  ...initialState,
  getRoles: async () => {
    const params = {
      filters: [],
      perpage: 100,
      page: 1,
    }
    const response = await roles_api.roles(params);

    const { status, data } = response;

    if (!status || !data) {
      throw new Error("No status code or data returned from server.");
    }

    if (status !== 200) {
      throw new Error(data);
    }

    set((state) => ({
      ...state,
      roles: data.data
    }));
    return response;
  },
  getPermissions: async () => {
    const params = {
      filters: [],
      perpage: 100,
      page: 1,
    }
    const response = await permissions_api.permissions(params);

    const { status, data } = response;

    if (!status || !data) {
      throw new Error("No status code or data returned from server.");
    }
    if (status !== 200) {
      throw new Error(data);
    } else {
      set((state) => ({
        ...state,
        permissions: data.data
      }));
      return response;
    }
  },
  getSystemSettings: async () => {
    const response = await system_api.getSystem();

    const { status, data } = response;

    if (!status || !data) {
      throw new Error("No status code or data returned from server.");
    }
    if (status !== 200) {
      throw new Error(data);
    } else {
      set((state) => ({
        ...state,
        emails: data?.data?.mail_for_sending_project
      }));
      return response;
    }
  },
  editEmails: async (payload) => {
    const response = await system_api.editSystem(payload);

    const { status, data } = response;

    if (status !== 200) {
      throw new Error(data);
    } else {
      set((state) => ({
        ...state,
        emails: data.data
      }));
      return response;
    }
  },
}));
