import {api} from "../index";
import {
  ICreateLidForm,
  ICreateUserForm, IEditLid,
  IEditUser,
  IEditUserForm,
  IFilter,
  ILogin,
  IRegister
} from "../../interfaces/general";

interface ICreateUserApi extends ICreateUserForm {

}

export const lids_api = {
  lids: async (params: IFilter) => {
    return await api.get(`lids`,{
          params: {
            ...params,
            filters: JSON.stringify(params.filters)
          }
      })
  },
  importLids: async (params: IFilter) => {
    return await api.get(`lids/import`,{
          params: {
            ...params,
            filters: JSON.stringify(params.filters)
          }
      })
  },
  getLid: async (id: number) => {
    return await api.get(`lids/${id}`)
  },
  editLid: async (id: number, payload: IEditLid) => {
    return await api.patch(`lids/${id}`, payload)
  },
  createLid: async (data: ICreateLidForm) => {
    return await api.post(`lids`, data)
  },
  deleteLid: async (id: number) => {
    return await api.delete(`lids/${id}`)
  }

};

