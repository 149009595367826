import React, {memo} from "react";

const CloseSvg = (props: any) => <svg
  style={{
    cursor: 'pointer'
  }}
  width="24"
  height="24"
  viewBox="0 0 21 21"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  {...props}
>
  <path
    d="M10.5002 12.5417L3.35433 19.6876C3.08697 19.9549 2.74669 20.0886 2.3335 20.0886C1.9203 20.0886 1.58002 19.9549 1.31266 19.6876C1.0453 19.4202 0.911621 19.0799 0.911621 18.6667C0.911621 18.2535 1.0453 17.9133 1.31266 17.6459L8.4585 10.5001L1.31266 3.35424C1.0453 3.08688 0.911621 2.7466 0.911621 2.3334C0.911621 1.92021 1.0453 1.57993 1.31266 1.31257C1.58002 1.04521 1.9203 0.91153 2.3335 0.91153C2.74669 0.91153 3.08697 1.04521 3.35433 1.31257L10.5002 8.45841L17.646 1.31257C17.9134 1.04521 18.2536 0.91153 18.6668 0.91153C19.08 0.91153 19.4203 1.04521 19.6877 1.31257C19.955 1.57993 20.0887 1.92021 20.0887 2.3334C20.0887 2.7466 19.955 3.08688 19.6877 3.35424L12.5418 10.5001L19.6877 17.6459C19.955 17.9133 20.0887 18.2535 20.0887 18.6667C20.0887 19.0799 19.955 19.4202 19.6877 19.6876C19.4203 19.9549 19.08 20.0886 18.6668 20.0886C18.2536 20.0886 17.9134 19.9549 17.646 19.6876L10.5002 12.5417Z"
    fill="#373737"
  />
</svg>

export default memo(CloseSvg)