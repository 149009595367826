import {api} from "../index";
import {
  ICreateUserForm,
  IEditTray,
  IEditUser,
  IEditUserForm,
  IFilter,
  ILogin,
  IRegister
} from "../../interfaces/general";

interface ICreateTrayApi {
}

export const trays_api = {
  trays: async (params: IFilter) => {
    return await api.get(`trays`,{
          params: {
            ...params,
            filters: JSON.stringify(params.filters)
          }
      })
  },
  getTray: async (id: number) => {
    return await api.get(`trays/${id}`)
  },
  editTray: async (id: number, payload: IEditTray) => {
    return await api.patch(`trays/${id}`, payload)
  },
  createTray: async (data: ICreateTrayApi) => {
    return await api.post(`trays`, data)
  },
  deleteTray: async (id: number) => {
    return await api.delete(`trays/${id}`)
  }

};

