import {TFunction} from "i18next";
import * as Yup from "yup";
import {installations, products} from "../../constants";


export const getRecommendationEditValidationSchema = (t: TFunction<"translation", undefined>) =>
  Yup.object().shape({
        place_installation_uk: Yup.string().required(t("error.required")),
        place_installation_en: Yup.string().required(t("error.required")),
        coating_ids: Yup.array().required(t("error.required")),
        product: Yup.string().oneOf(products).required(t("error.required")),
        installation: Yup.string().oneOf(installations).required(t("error.required")),
  });