import {api} from "../index";
import {
  ICreateImageForm,
  ICreateUserForm, IEditImage,
  IFilter,
} from "../../interfaces/general";

interface ICreateUserApi extends ICreateUserForm {

}

export const images_api = {
  images: async (params: IFilter) => {
    return await api.get(`images`,{
          params: {
            ...params,
            filters: JSON.stringify(params.filters)
          }
      })
  },
  getImage: async (id: number) => {
    return await api.get(`images/${id}`)
  },
  editImage: async (id: number, payload: IEditImage) => {
    return await api.patch(`images/${id}`, payload)
  },
  createImage: async (data: ICreateImageForm) => {
    return await api.post(`images`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  deleteImage: async (id: number) => {
    return await api.delete(`images/${id}`)
  }

};

