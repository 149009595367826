import {Box} from "@mui/material";

import FileUploadRoot from "react-material-file-upload";

interface IProps {
  file: any[];
  handleChange: (files: any[]) => void;
  formats?: string[];
}

const FileUpload = ({ file, handleChange, formats = ['.pdf'] } : IProps ) => {

  return (
    <Box sx={{
      '& .MuiButtonBase-root' : {
        backgroundColor: '#DA281A',
      },
      '& .MuiSvgIcon-root' : {
        color: '#DA281A',
      },
      '& > .MuiBox-root' : {
        borderWidth: '2px',
        borderColor: '#242531',
        borderRadius: '8px'
      },
    }}>
      <FileUploadRoot
        value={file}
        accept={formats}
        onChange={handleChange}
      />
    </Box>
  )
}
export default FileUpload