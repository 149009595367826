import {Box, Button, Dialog, DialogContent, DialogTitle, Typography} from "@mui/material";
import CloseSvg from "../../../../assets/svgs/common/CloseSvg";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import FileUpload from "../../FileUpload";
import useToast from "../../../../hooks/useToast";
import ButtonLoadingWrapper from "../../../Wrappers/ButtonLoading";

interface ISettingsProps {
  open: boolean;
  handleClose: () => void;
  handleSubmit: (file: any) => void;
}
const UploadFile = ({ handleClose, open, handleSubmit,} : ISettingsProps ) => {
  const { t } = useTranslation()

  const { errorToast } = useToast();
  const [isFileLoading, setIsFileLoading] = useState<boolean>(false);
  const [files, setFiles] = useState<any[]>([]);

  const handleSetFiles = (files: any) => {
    if(files.length > 1) {
      errorToast(t('handBook.error.filesLength'));
      return
    }
    setFiles(files)
  }

  const handleUpload = async () => {
    setIsFileLoading(true)
    await handleSubmit(files[0])
    setIsFileLoading(false)
  }


  useEffect(() => {
    if(!open) {
      setFiles([])
    }
  },[open])

  return <Dialog
    open={open}
    onClose={handleClose}
    sx={{
      '& .MuiBackdrop-root': {
        backgroundColor: 'rgba(0,0,0,0.1)' // Try to remove this to see the result
      },
      '& .MuiPaper-root': {
        width: '100%'
      }

    }}
  >
    <DialogTitle sx={{
      display: 'flex',
      justifyContent: 'space-between',
      fontWeight: 600,
      color:'#373737',
      borderBottom: '2px solid #373737',
      marginBottom: '20px',
    }}>
      {t('common.import')}
      <Box onClick={handleClose}>
        <CloseSvg />
      </Box>
    </DialogTitle>


    <DialogContent sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: '25px',
    }}>
      <Box>
        <FileUpload file={files} handleChange={handleSetFiles} formats={['.xls', '.xlsx']}/>
      </Box>
      <ButtonLoadingWrapper isLoading={isFileLoading}>
        <Button
          variant={'contained'}
          color={'error'}
          onClick={handleUpload}
          disabled={!files.length}
        >
          {t('common.import')}
        </Button>
      </ButtonLoadingWrapper>
    </DialogContent>
  </Dialog>

}

export default UploadFile