import {api} from "../index";
import {
  ICreateUserForm,
  IEditTray,
  IEditUser,
  IEditUserForm,
  IFilter, IHandbookCreate,
  ILogin,
  IRegister
} from "../../interfaces/general";

interface ICreateTrayApi {
}

export const files_api = {
  import: async (data: any) => {
    return await api.post(`files/import`, data, {headers: {
        'Content-Type': 'multipart/form-data'
      }})
  },
  export: async (payload: any) => {
    return await api.post(`files/export`, payload)
  },
  files: async (params: IFilter) => {
    return await api.get(`files/history`,{
      params: {
        ...params,
        filters: JSON.stringify(params.filters)
      }
    })
  }
};

