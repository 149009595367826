import { images_api } from "api/apis/images.api";
import { create } from "zustand";
import {
  IFilter, IImageCreate,
  IResponseTable,

} from "../interfaces/general";


interface IImagesState {
  isLoading: boolean,
  isTableLoading: boolean,
  tableData: IResponseTable
  filter: IFilter,
  page: number,
  modal: {
    isOpen: boolean,
    choosedImages: number[],
    filterModal: IFilter,
    pageModal: number,
    tableDataModal: IResponseTable
  }

}
interface IImagesStore extends IImagesState{
  setPage: (page: number) => void
  setFilter: (filter: IFilter) => void
  getImages: (params: IFilter, page: number, modal?: boolean) => Promise<any>,
  createImage: (payload: any) => Promise<any>,
  deleteImage: (id: number) => Promise<any>,
  getImage: (id: number) => Promise<any>,
  resetFilter: () => void,
  editImage: (id: number, payload: IImageCreate) => Promise<any>,
  setChoosedImages: (choosedImages: number[]) => void
  toggleModal: (isOpen: boolean) => void
}

const initialState = {
  isLoading: false,
  tableData: {
    data: [],
    meta: {
      total: 0,
      current_page: 0,
      last_page: 0,
      perpage: 0,
    }
  },
  filter: {
    filters: [],
    perpage: 15,
    page: -1,
  },
  page: 0,
  isTableLoading: true,

  modal: {
    isOpen: false,
    choosedImages: [],
    filterModal: {
      filters: [],
      perpage: 100,
      page: -1,
    },
    tableDataModal: {
      data: [],
      meta: {
        total: 0,
        current_page: 0,
        last_page: 0,
        perpage: 0,
      }
    },
    pageModal: 0,
  }
}

export const useImagesStore = create<IImagesStore>((set) => ({
  ...initialState,
  setPage: (page) => {
    set(state => ({...state, page}))
  },
  setFilter: (filter) => {
    set(state => ({...state, filter, page: 0 }))
  },
  setChoosedImages: (choosedImages) => {
    set(state => ({...state, modal: {...state.modal, choosedImages}}))
  },
  resetFilter: () => {
    set(state => ({...state, filter: initialState.filter, page: 0 }))
  },
  toggleModal: (isOpen) => {
    set(state => ({...state, modal: {...state.modal, isOpen}}))
  },
  getImages: async (params, page, modal= false) => {
    set(state => ({...state, isTableLoading: true}))
    const response = await images_api.images({...params, page: page + 1})
      .finally(() => set(state => ({...state, isTableLoading: false})));

    const { status, data } = response;

    if (!status || !data) {
      throw new Error("No status code or data returned from server.");
    }

    if (status !== 200) {
      throw new Error(data);
    }

    set((state) => ({
      ...state,
      tableData: modal ? initialState.tableData : data ,
      modal: {
        ...state.modal,
        tableDataModal: modal ? data : initialState.tableData ,
      }
    }));
  },
  createImage: async (payload) => {
    const response = await images_api.createImage(payload);

    const { status, data } = response;

    if (!status || !data) {
      throw new Error("No status code or data returned from server.");
    }

    if (status !== 201) {
      throw new Error(data);
    }

    set((state) => ({
      ...state,
      tableData: {
        ...state.tableData,
        data: [...state.tableData.data, data.data]
      }
    }));

    return data;

  },
  deleteImage: async (id) => {
    const response = await images_api.deleteImage(id);

    const { status, data } = response;

    if (status !== 204) {
      throw new Error(data);
    }
    set((state) => ({
      ...state,
      tableData: {
        ...state.tableData,
        data: state.tableData.data.filter((item) => item.id !== id),
      },
    }));
  },
  getImage: async (id) => {
    const response = await images_api.getImage(id);

    const { status, data } = response;

    if (status !== 200) {
      throw new Error(data);
    }
    set((state) => ({
      ...state,

    }));
    return data;
  },
  editImage: async (id, payload) => {
    const response = await images_api.editImage(id, payload)

    const { status, data } = response;


    set((state) => ({
      ...state,
      tableData: {
        ...state.tableData,
        data: state.tableData.data.map((item) => item.id === id ? data.data : item),
      },
    }));
    return data;
  }
}));

export const useImagesStoreOut = useImagesStore.getState();