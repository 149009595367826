import {Dispatch, SetStateAction} from "react";

export const socketEvents = ({
  setValue,
  channel,
}: {
  setValue: (
    state: any
  ) => Dispatch<
    SetStateAction<any>
  >,
  channel: any;
}) => {
  channel.bind('App\\Event\\Notifications', function(data: any) {
    console.log(data,'1')

  })

  channel.bind('App\\Events\\NotificationEvent', function(res: any) {
    const data = res.data
    if(data?.file && res?.event && res?.status) {
      const payload = {
        action: res?.event?.includes('export') ? 'export' : 'import',
        type: data?.file?.model,
        item: data?.file
      }

      setValue((state: any) => {
        return {
          ...state,
          messageSuccess: payload
        };
      });
    }
    if(data?.file && res?.event && !res?.status) {
      const payload = {
        action: res?.event?.includes('export') ? 'export' : 'import',
        type: data?.file?.model,
        item: data?.file
      }

      setValue((state: any) => {
        return {
          ...state,
          messageError: payload
        };
      });
    }

  })

  // channel.bind("App\\Events\\Notifications", (data) => {
  //   console.log(data)
  // })
  // channel.bind("App\\Events\\SendOneLead", (data) => {
  //   console.log(data.lead)
  // })
};
