export const translationEn = {
  header: {
    account: "Account",
    users: "Users",
    projects: "Projects",
    admins: "Admins",
    logout: "Logout",
    settings: "Settings",
    resetPassword: "Reset Password",
    handBooks: 'Handbooks',
    lids: "Lids/Partitions",
    trays: "Trays/Accessories",
    metalProducts: "Metal Products",
    coatings: "Coatings",
    recommendations: "Recommendations",
    files: "Files history",
    images: "Images",
    emailSettings: "System emails",
  },
  error: {
    error: 'Error',
    required: "* Required field",
    451: 'Account deactivated',
    452: 'Account is temporarily blocked',
    901: 'Minimum symbols length',
    902: 'Maximum symbols length',
    invalidEmail: 'Invalid email',
    somethingWentWrong: 'Something went wrong',
  },
  signUp: {
    title: "Sign Up",
    first_name: "First Name",
    password_old: "Old Password",
    last_name: "Last Name",
    email: "Email",
    password: "Password",
    passwordConfirm: "Confirm Password",
    phone_number: "Phone Number",
    submit: "Sign Up",
    patronymic: "Patronymic",
    company: "Company",
    haveAccount : "Already have an account?",
    login: "Sign In",
    success: 'You have successfully registered',
    error: {
      userExists: 'User with this email already exists',
      passwordConfirm: 'Passwords do not match',
      phone_number: 'Invalid phone number',
      email: 'Invalid email',
      password: 'Password must be at least 6 characters long',
      first_name: 'First name must be at least 2 characters long',
      last_name: 'Last name must be at least 2 characters long',
      patronymic: 'Patronymic must be at least 2 characters long',
      company: 'Company must be at least 2 characters long',
      minLength: 'Field must be more than 6 characters',
      maxLength: 'Field must be no more than 20 characters',
      emailPattern: 'Invalid email',
      400: {
        email: 'Email is already in use',
        phone_number: 'Phone number is already in use',
      }
    }
  },
  signIn: {
    title: "Sign In",
    login: "Login / Phone Number",
    password: "Password",
    submit: "Sign In",
    dontHaveAccount: "Don't have an account?",
    signUp: "Sign Up",
    forgotPassword: "Forgot Password?",
    error: {
      login: "Invalid login or password",
      234: 'Too many login attempts, try again later',
      googleFail: 'Error logging in with Google',
      accountNotRegistered: 'No account registered with this email',
    },
    success: 'You have successfully logged in',
  },
  forgotPassword: {
    title: "Forgot Password",
    email: "Email",
    submit: "Send",
    code: "Code",
    new_password: "New Password",
    success: {
      email: 'A letter with a code has been sent to your email',
      code: 'The code is correct, enter the password',
      password: 'Password successfully changed',
    },
    error: {
      email: 'User with this email does not exist',
      code: 'Invalid code',
      new_password: 'Try another password',
    }
  },
  editUser: {
    title: "User settings",
    first_name: "First Name",
    last_name: "Last Name",
    email: "Email",
    phone_number: "Phone Number",
    submit: "Save",
    patronymic: "Patronymic",
    company: "Company",
    password: "Password",
    role: "Role",
    permissions: "Permissions",
    blocked: "Blocked",
    active: "Active",
    success: 'User successfully edited',
    error: {
      default: 'Error editing user',
    }
  },
  resetPassword: {
    title: "Reset Password",
    password_old: "Old Password",
    password: "New Password",
    passwordConfirm: "New Confirm Password",
    success: 'Password successfully changed',
    submit: "Save",
    error: {
      default: 'Password isn\'t correct',
    }
  },
  usersTable: {
    title: "Users",
    id: "ID",
    email: "Email",
    first_name: "First Name",
    last_name: "Last Name",
    phone_number: "Phone Number",
    company: "Company",
    patronymic: "Patronymic",
    role: "Role",
    last_login: "Last Login",
    status: "Status",
    active: "Active",
    blocked: "Blocked",
    nonActive: "Non Active",
    nonBlocked: "Non Blocked",
  },
  createUser: {
    title: "Create User",
    createAdmin: "Create Admin",
    first_name: "First Name",
    last_name: "Last Name",
    email: "Email",
    password: "Password",
    company: "Company",
    patronymic: "Patronymic",
    phone_number: "Phone Number",
    permissions: "Permissions",
    submit: "Create",
    role: "Role",
    success: 'User successfully created',
    error: {
      default: 'Error creating user',
      400: {
        email: 'User with this email already exists',
        phone_number: 'User with this phone number already exists',
      }
    },
  },
  deleteHandbook: {
    title: 'Delete handbook',
    submissionText: 'Are you sure you want to delete this handbook?',
    success: 'Handbook successfully deleted',
    error: 'Error deleting handbook',
  },
  editHandbook: {
    success: 'Handbook successfully edited',
    error: {
      default: 'Error editing handbook',
    },
  },
  handBook: {
    title: "Handbooks",
    create: "Create Handbook",
    edit: "Edit Handbook",
    name: "Name",
    description: "Description",
    submitCreate: "Create",
    locale: "Locale",
    submitEdit: "Save",
    hide: "Hide",
    success: 'Handbook successfully created',
    createHandbook: 'Create Handbook',
    hidden: 'Disabled',
    nonHidden: 'Enabled',
    type: {
      title: "Type",
      album: "Album",
      certificate: "Certificate",
      catalog: "Catalog",
    },
    error: {
      default: 'Error creating handbook',
      filesLength: 'You can upload only 1 file',
      fileRequired: 'File is required',
      400: {
        file: 'File does not match the criteria',

      }
    },

  },
  deleteUser: {
    title: 'Delete user',
    submissionText: 'Are you sure you want to delete this user?',
    cancel: 'Cancel',
    submit: 'Delete',
    error: 'Error deleting user',
    success: 'User successfully deleted',
  },
  projects: {
    create: 'Create project',
  },
  noTableData: {
    title: 'No data available',
  },
  lids: {
    title: 'Lids',
    create: 'Create lid',
    edit: 'Edit lid',
    name_en: 'English name',
    name_uk: 'Ukraine name',
    id: 'ID',
    coating: 'Coating',
    length: 'Length',
    width: 'Width',
    height: 'Height',
    thickness: 'Thickness',
    weight: 'Weight',
    page: 'Page',
    unit_of_measurement: 'Unit of measurement',
    article: 'Article',
    article_prefix_1: 'Article prefix 1',
    article_prefix_2: 'Article prefix 2',
    type: 'Lid or Partition',
    lid: 'Lid',
    partition: 'Partition',
  },
  createLid: {
    title: 'Create lid',
    submit: 'Create',
    success: 'Lid successfully created',
  },
  deleteLid: {
    title: 'Delete lid',
    submissionText: 'Are you sure you want to delete this lid?',
    cancel: 'Cancel',
    submit: 'Delete',
    error: 'Error deleting lid',
    success: 'Lid successfully deleted',
  },
  coatings: {
    title: 'Coatings',
    create: 'Create coating',
    edit: 'Edit coating',
    description: 'Description',
    id: 'ID',
    code: 'Code',
    descriptionEn: 'English description',
    descriptionUa: 'Ukraine description',
  },
  createCoating: {
    title: 'Create coating',
    submit: 'Create',
    success: 'Coating successfully created',
  },
  deleteCoating: {
    title: 'Delete coating',
    submissionText: 'Are you sure you want to delete this coating?',
    cancel: 'Cancel',
    submit: 'Delete',
    error: 'Error deleting coating',
    error430: 'Coating is used',
    error430add: 'Delete the coating from the product first',
    errorCoatingUsing: 'The coating is used in {{value}} products, are you sure you want to delete it?',
    success: 'Coating successfully deleted',
  },
  editRecommendation: {
    success: 'Recommendation successfully edited',
  },
  recommendation: {
    title: 'Recommendations',
    create: 'Create recommendation',
    edit: 'Edit recommendation',
    description: 'Description',
    id: 'ID',
    product: 'Product',
    installation: 'Installation',
    coating: 'Coating',
    place_installation_uk: 'Place installation ukrainian',
    place_installation_en: 'Place installation english',

  },
  createRecommendation: {
    title: 'Create recommendation',
    submit: 'Create',
    success: 'Recommendation successfully created',
  },
  deleteRecommendation: {
    title: 'Delete recommendation',
    submissionText: 'Are you sure you want to delete this coating?',
    cancel: 'Cancel',
    submit: 'Delete',
    error: 'Error deleting recommendation',
    success: 'Recommendation successfully deleted',
  },
  metalProducts: {
    title: 'Metal Products',
    name_en: 'English name',
    name_uk: 'Ukraine name',
    create: 'Create Metal Product',
    edit: 'Edit Metal Product',
    id: 'ID',
    coating: 'Coating',
    length: 'Length',
    width: 'Width',
    height: 'Height',
    thickness: 'Thickness',
    weight: 'Weight',
    page: 'Page',
    unit_of_measurement: 'Unit of measurement',
    article: 'Article',
    article_prefix_1: 'Article prefix 1',
    article_prefix_2: 'Article prefix 2',
  },
  createMetalProduct: {
    title: 'Create Metal Product',
    submit: 'Create',
    success: 'Metal product successfully created',
  },
  deleteMetalProduct: {
    title: 'Delete metal product',
    submissionText: 'Are you sure you want to delete this metal product?',
    cancel: 'Cancel',
    submit: 'Delete',
    error: 'Error deleting metal product',
    success: 'Metal product successfully deleted',
  },
  trays: {
    title: 'Trays',
    create: 'Create tray',
    edit: 'Edit tray',
    id: 'ID',
    type: 'Tray or Accessory',
    coating: 'Coating',
    length: 'Length',
    width: 'Width',
    height: 'Height',
    thickness: 'Thickness',
    tray: 'Tray',
    accessory: 'Accessory',
    weight: 'Weight',
    page: 'Page',
    unit_of_measurement: 'Unit of measurement',
    article: 'Article',
    angle: 'Angle',
    name_en: 'English name',
    name_uk: 'Ukraine name',
    article_prefix_1: 'Article prefix 1',
    article_prefix_2: 'Article prefix 2',
    lids: 'Lids',
    partitions: 'Partitions',
  },
  createTray: {
    title: 'Create Tray',
    submit: 'Create',
    success: 'Tray successfully created',
  },
  deleteTray: {
    title: 'Delete tray',
    submissionText: 'Are you sure you want to delete this tray?',
    cancel: 'Cancel',
    submit: 'Delete',
    error: 'Error deleting tray',
    success: 'Tray successfully deleted',
  },
  editTray: {
    success: 'Tray successfully edited',
    error: {
      default: 'Error editing tray'
    }
  },
  files: {
    title: 'Files',
    id: 'ID',
    file_name: 'File name',
    file_size: 'File size',
    file_status: 'File status',
    model: 'Model',
    action: 'Action',
    error: 'Error',
    created_at: 'Created at',
    status: {
      1: 'Not processed',
      2: 'Processed',
      3: 'Ready',
      4: 'Error',
    },
    export: {
      successProcess: 'Export data is being processed',
      error: 'Error exporting file',
    },
    import: {
      successProcess: 'Import data is being processed',
      error: 'Error importing file',
    }
  },
  images: {
    title: 'Images',
    id: 'ID',
    file_name: 'File name',
    create: 'Create image',
    file_size: 'File size',
    createImage: 'Create image',
    editImage: 'Edit image',
    image: 'Image',
    submitCreate: 'Create',
    submitEdit: 'Save',
    success: 'Image successfully created',
    addImages: 'Add images',
    error: {
      default: 'Error creating image',
      filesLength: 'You can upload only 1 file',
      fileRequired: 'File is required',
      400: {
        file: 'File does not match the criteria',
      }
    }
  },
  deleteImage: {
    title: 'Delete image',
    submissionText: 'Are you sure you want to delete this image?',
    cancel: 'Cancel',
    submit: 'Delete',
    error: 'Error deleting image',
    success: 'Image successfully deleted',
  },
  export: {
    success: {
      lid: 'File with lids is ready for download',
      tray: 'File with trays is ready for download',
      metalProduct: 'File with metal products is ready for download',
    },
    error: {
      lid: 'Error exporting lids',
      tray: 'Error exporting trays',
      metalProduct: 'Error exporting metal products',
    }
  },
  import: {
    success: {
      lid: 'Lids successfully imported',
      tray: 'Trays successfully imported',
      metalProduct: 'Metal products successfully imported',
    },
    error: {
      lid: 'Error importing lids',
      tray: 'Error importing trays',
      metalProduct: 'Error importing metal products',
    }
  },
  unit_of_measurement: {
    meter: 'm',
    millimeters: 'mm',
    units: 'units',
  },
  project: {
    withLid: 'With lid',
    noLid: 'No lid',
    withPartition: 'With partition',
    noPartition: 'No partition',
    isLid: 'Lid availability',
    complete: 'Complete',
    addToCart: 'Add to computation',
    projectName: 'Project name',
    projectHelper: 'Need help?',
    newProject: "New project",
    createError: 'Error creating project',
    createSuccess: 'Project successfully created',
    projectUpdated: 'Project successfully updated',
    projectUpdateFailed: 'Error updating project',
    trayQuantity: 'Quantity',
    projectLength: 'Order length (m)',
    formulaRequired: 'The items are required for the tray',
    successDelete: 'Item successfully deleted',
    errorDelete: 'Error deleting item',
    positionAddFailed: 'Error adding item',
    positionAddSuccess: 'Item successfully added',
    editCart: 'Edit item',
    positionEditSuccess: 'Item successfully edited',
    positionEditFailed: 'Error editing item',
    createErrorDeactivated: 'Error creating project, your account is deactivated',
    accessoryView: 'Accessory',
    disabledAccessories: 'You can\'t add accessories with empty computation',
    trayView: 'Trays',
    noLidFound: 'No lid found for this accessory',
    noLidFoundForTray: 'No lid found for this tray in database',
    chooseTypeLid: 'Choose type of lid',
    chooseTypePartition: 'Choose type of partition',
    noPartitionFoundForTray: 'No partition found for this tray in database',
    recommendation: {
      placementTitle: 'Choose placement of tray',
      titleChooseRec: "Choose industry",
      successChoose: 'Good choice, now you can find the right product',
    }
  },
  common: {
    search: "Search",
    reset: "Reset",
    cancel: "Cancel",
    edit: "Edit",
    delete: "Delete",
    fieldForSearch: "Field for search",
    showMore: "Show more",
    showLess: "Show less",
    import: "Import",
    article: "Article",
    export: "Export",
    external: "External",
    internal: "Internal",
    metalProduct: "Metal Product",
    lid: "Lid",
    tray: "Tray",
    notification: "Notification",
    editNumbers: "Edit numbers",
    addNumber: "Add number",
    save: "Save",
    meters: "Meters",
    meter: "Meter",
    millimeters: "Millimeters",
    millimeter: "Millimeter",
    unit: "Unit",
    units: "Units",
    use: "Use",
    allPermissions: "You have all permissions",
    userPermissions: "User can't have any permissions",
    width: "Width",
    length: "Length",
    height: "Height",
    coating: "Coating",
    type: "Type",
    description: "Description",
    weight: "Weight",
    thickness: "Thickness",
    angle: "Angle",
    chooseElement: "Choose element",
    color: "Color",
    newProject: "New project",
    unit_of_measurement: "UoM",
    count: "Count",
    emails_saved: "Emails saved",
    emailsSystem: "Emails system",
    email: "Email",
    send_to_emails: "Send to email",
    download: "Download",
    emailSent: "Email sent",
    exportWithEmail: "Send result via email",
    computation: "Computation",
    item: "Item",
    chooseColor: "Choose color",
    send: "Send",
    users: "Users",
    searchBy: {
      title: "Search by",
      id: "ID",
      active: "Active",
      name: "Name",
      blocked: "Blocked",
      email: "Email",
      first_name: "First Name",
      last_name: "Last Name",
      phone_number: "Phone Number",
      company: "Company",
      patronymic: "Patronymic",
      status: "Status",
      locale: "Locale",
      type: "Type",
      description: "Description",
      role: "Role",
      last_login: "Last Login",
      length: "Length",
      width: "Width",
      height: "Height",
      thickness: "Thickness",
      weight: "Weight",
      coating: "Coating",
      unit_of_measurement: "Unit of measurement",
      article: "Article",
      angle: "Angle",
      code: "Code",
      product: "Product",
      installation: "Installation",
      description_en: "En description",
      description_uk: "Uk description",
    }
  },
  permissions: {
    "imageLoad": "Load Image",
    "imageDestroy": "Destroy Image",
    "imageUpdate": "Update Image",
    "imageCreate": "Create Image",
    "imageList": "List Images",
    "metalProductDestroy": "Destroy Metal Product",
    "metalProductUpdate": "Update Metal Product",
    "metalProductCreate": "Create Metal Product",
    "metalProductList": "List Metal Products",
    "metalProductImport": "Import Metal Product",
    "metalProductExport": "Export Metal Product",
    "coatingDestroy": "Destroy Coating",
    "coatingUpdate": "Update Coating",
    "coatingCreate": "Create Coating",
    "coatingList": "List Coatings",
    "recommendationDestroy": "Destroy Recommendation",
    "recommendationUpdate": "Update Recommendation",
    "recommendationCreate": "Create Recommendation",
    "recommendationList": "List Recommendations",
    "lidDestroy": "Destroy Lid",
    "lidUpdate": "Update Lid",
    "lidCreate": "Create Lid",
    "lidList": "List Lids",
    "lidImport": "Import Lid",
    "lidExport": "Export Lid",
    "trayDestroy": "Destroy Tray",
    "trayUpdate": "Update Tray",
    "trayCreate": "Create Tray",
    "trayList": "List Trays",
    "trayImport": "Import Tray",
    "trayExport": "Export Tray",
    "handBookDestroy": "Destroy Handbook",
    "handBookUpdate": "Update Handbook",
    "handBookCreate": "Create Handbook",
    "handBookList": "List Handbooks",
    "userDestroy": "Destroy User",
    "userUpdate": "Update User",
    "userCreate": "Create User",
    "userList": "List Users",
    "systemLogList": "List System Logs",
    "sendMessageAboutNewRegistration": "Send Message About New Registration",
    "fileExport": "Export File",
    "fileImport": "Import File",
    "fileHistory": "File History",
    "systemConfiguration": "System Configuration",
  }


};
