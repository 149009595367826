import {Box, Typography} from "@mui/material";
import ListItem from "./ListItem";
import AddIcon from '@mui/icons-material/Add';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {RouteUrls} from "../../../../../interfaces/enum";
import {useProjectsStore} from "../../../../../store/projectsStore";
import useAsyncWrapper from "../../../../../hooks/useAsyncWrapper";
import useToast from "../../../../../hooks/useToast";

const ProjectList = () => {
  const { t } = useTranslation()
  const nav = useNavigate()
  const { errorToast, successToast } = useToast()


  const projects = useProjectsStore(state => state.tableData).data;
  const createProject = useProjectsStore(state => state.createProject);
  const isLoading = useProjectsStore(state => state.isCreateLoading);

  const createProjectHandler = useAsyncWrapper(createProject)

  const handleCreateProject = async () => {
    if(isLoading) return

    const payload = {
      name: t('project.newProject'),
    }

    const { data, error } = await createProjectHandler([payload])

    if(!error) {
      successToast(t('project.createSuccess'))
      nav(RouteUrls.PROJECTS + `/${data?.data?.id}`)
      return
    }

    if(error?.response?.data?.message?.includes('deactivated')) {
      errorToast(t('project.createErrorDeactivated'))
      return
    }
    errorToast(t('project.createError'))
  }

  return (<Box sx={{
    display: 'flex',
    overflowY: 'auto',
    gap: '25px',
    flexWrap: 'wrap',
    padding: '10px 15px',
  }}>
    <Box sx={{
      opacity: isLoading  ? 0.5 : 1,
    }}>
      <Box
        onClick={handleCreateProject}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '180px',
          width: '180px',
          borderRadius: '16px',
          border: '4px dashed #666',
          cursor: 'pointer',
          opacity: isLoading  ? 0.5 : 1,
          '&:hover': {
            opacity: 0.8
          },
          "@media (max-width: 600px)": {
            height: '130px',
            width: '130px',
          }
        }}
      >
        <Typography fontSize={{
          fontWeight: 700,
          color: '#666'
        }}>
          <AddIcon sx={{
            fontSize: '120px',
            "@media(max-width: 600px)": {
              fontSize: '80px',
            },
          }}/>
        </Typography>
      </Box>
      <Typography
        fontSize={{
          xs: 14,
          sm: 18,
        }}
        textAlign={'center'}>
        {t('projects.create')}
      </Typography>
    </Box>
    {projects.map((item, index) => <ListItem item={item} index={index} key={item.id} />)}

  </Box>)
}

export default ProjectList