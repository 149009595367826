import React, {useContext, useEffect, useState} from "react";

import { Outlet } from "react-router-dom";
import Header from "../Header";
import {StyledWrapper} from "./style";
import Menu from "../Menu";
import {Box} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import SuccessSnackbar from "../SuccessSnackbar";
import SocketContext from "../../../context/socket";
import ErrorSnackbar from "../ErrorSnackbar";


const Account = () => {
  const media = useMediaQuery('(max-width: 600px)')

  return (
    <Box sx={{
      display: 'flex',
      flexGrow: 1,
      padding: '10px',
      gap: '20px',
    }}>
      {!media && <Menu/>}
      <StyledWrapper>
        <Header />
        <Outlet />
        <SuccessSnackbar />
        <ErrorSnackbar />
      </StyledWrapper>
    </Box>

  );
};

export default Account;