import {Box, IconButton, Snackbar, Typography} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import SocketContext from "../../../context/socket";

interface IProps {

}
const ErrorSnackbar = ({ }: IProps ) => {
  const { t } = useTranslation()
  const context: any = useContext(SocketContext)

  const [open, setOpen] = useState(false)

  const { action, item, type } = context?.messageError!;

  const handleClose = () => {
    setOpen(false)
  }


  useEffect(() => {
    if(action && item && type && !open) {
      setOpen(true)
    }
  }, [action, item?.id, type])


  return <Snackbar
    open={open}
    anchorOrigin={{
      vertical: 'top', horizontal: 'left'
    }}
    // onClose={handleClose}
    sx={{
      '& .MuiSnackbarContent-action': {
        marginLeft: '0',
        paddingLeft: '0',
        width: '100%'
      },
    }}
    action={<Box sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      maxWidth: '300px',
      width: '100%'
    }}>


      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
        <Typography variant={'h6'}>
          {t('common.notification')}
        </Typography>
        <IconButton
          aria-label="close"
          color="inherit"
          sx={{ p: 0.5 }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </Box>


      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <Typography variant={'body2'}>
          {t(action === 'import' ? `import.error.${type}` : `export.error.${type}`)}
        </Typography>
      </Box>


    </Box>}
  />
}
export default ErrorSnackbar