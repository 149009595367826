import {Box, TablePagination} from "@mui/material";
import ProjectList from "./components/ProjectList";
import {useProjectsStore} from "../../../store/projectsStore";
import TableLoading from "../TableLoading";
import React, {useEffect} from "react";
import Filter from "./components/Filter";

const MyProjects = () => {
  const isTableLoading = useProjectsStore(state => state.isTableLoading);
  const projects = useProjectsStore(state => state.tableData).data;

  const filter = useProjectsStore(state => state.filter)
  const setFilter = useProjectsStore(state => state.setFilter);
  const meta = useProjectsStore(state => state.tableData.meta);
  const page = useProjectsStore(state => state.page);
  const resetFilter = useProjectsStore(state => state.resetFilter);
  const setPage = useProjectsStore(state => state.setPage);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({ ...filter, perpage: +event.target.value });
  };

  useEffect(() => {

    return () => {
      resetFilter()
    }
  },[])

  return (<Box sx={{
    width: '100%',
    // overflow: 'hidden',
    // flexGrow: 1,
    // display: 'flex',
    // flexDirection: 'column',
    marginTop: '15px',
    maxHeight: 'calc(100vh - 136px)',
    height: '100%',
    // justifyContent: 'space-between'
  }}>
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    }}>
      <Filter />
      {isTableLoading ? <TableLoading />
        :  <ProjectList/>}
    </Box>
    <TablePagination
        rowsPerPageOptions={[10, 15, 25, 100]}
        component="div"
        count={meta.total}
        rowsPerPage={filter.perpage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          overflow: 'hidden',
          // flexGrow: 1,
          // display: 'flex',
          // alignItems: 'flex-end',
          // justifyContent: 'flex-end'
        }}
    />
  </Box>)
}

export default MyProjects