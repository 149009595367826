import {TFunction} from "i18next";
import * as Yup from "yup";


export const getLidCreateValidationSchema = (t: TFunction<"translation", undefined>) =>
  Yup.object().shape({
        name_uk: Yup.string().min(2, t("error.required")).required(t("error.required")),
        name_en: Yup.string(),
        article_prefix_2: Yup.string(),
        article_prefix_1: Yup.string(),
        type: Yup.string().required(t("error.required")),
  });