import React, {useEffect} from "react";
import useAsyncWrapper from "../../hooks/useAsyncWrapper";
import FilesTable from "../../components/UI/Tables/FilesTable";
import {useFilesStore} from "../../store/filesStore";

let prevFilter = {}
const Files = () => {

  const getFiles = useFilesStore(state => state.getFiles)
  const filter = useFilesStore(state => state.filter);
  const getFilesHandler = useAsyncWrapper(getFiles);

  const page = useFilesStore(state => state.page);


  useEffect(() => {
    (async () => {
      if(JSON.stringify(prevFilter) !== JSON.stringify(filter) || filter.page !== page) {
        prevFilter = filter
        await getFilesHandler([filter, page])
      }
    })();
  },[filter, page])


  return (
      <>
        <FilesTable />
      </>
  );
};

export default Files;
