import {Box, IconButton, TextField, Typography, useMediaQuery} from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import {useTranslation} from "react-i18next";
import {useProjectStore} from "../../../../../store/projectStore";
import {useProjectsStore} from "../../../../../store/projectsStore";
import React, {memo, useCallback, useEffect, useState} from "react";
import useAsyncWrapper from "../../../../../hooks/useAsyncWrapper";
import useToast from "../../../../../hooks/useToast";
import RecommendationModal from "../../../Modals/RecomendationModal";
import ClearIcon from "@mui/icons-material/Clear";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ComputationModal from "../../../Modals/ComputationModal";
import ChoosenItemModal from "../../../Modals/ChoosenItemModal";
import ColorPicker from "../Trays/ChoosenItem/components/ColorPicker";

const ProjectHelper = ({}) => {
  const { t } = useTranslation();
  const { errorToast, successToast } = useToast();

  const media = useMediaQuery('(min-width: 900px)');

  const [open, setOpen] = useState({
    recommendation: false,
    computation: false,
    choosenItem: false,
  });

  const currentProject = useProjectsStore(state => state.currentProject);

  const initialProjectName = currentProject?.name ?? '';
  const initialColorName = currentProject?.color?.hex ?? '';

  const projectName = useProjectStore(state => state.projectName);
  const recommendation = useProjectStore(state => state.recommendation);
  const choosenItem = useProjectStore(state => state.choosenItem);
  const choosenAccessory = useProjectStore(state => state.choosenAccessory);
  const setRecommendation = useProjectStore(state => state.setRecommendation);
  const setCurrentCoating = useProjectStore(state => state.setCurrentCoating);
  const accessoryView = useProjectStore(state => state.accessoryView);
  const currentColor = useProjectStore(state => state.currentColor)

  const setProjectName = useProjectStore(state => state.setProjectName);

  const editProject = useProjectsStore(state => state.editProject);
  const editProjectHandler = useAsyncWrapper(editProject)



  const handleClose = useCallback(() => {
    setOpen({
      recommendation: false,
      computation: false,
      choosenItem: false,
    })
  },[])

  const handleOpen = () => {
    setOpen({
      ...open,
      recommendation: true,
    })
  }
  const handleOpenComputation = () => {
    setOpen({
      ...open,
      computation: true,
    })
  }
  const handleOpenChoosenItem = () => {
    setOpen({
      ...open,
      choosenItem: true,
    })
  }

  const handleSubmit = async () => {
    const payload = {
      name: projectName,
      color: currentColor || null,
    }
    const { error, data } = await editProjectHandler([currentProject?.id, payload])

    if(!error) {
      successToast(t('project.projectUpdated'))
      return
    }
    errorToast(t('project.projectUpdateFailed'))
  }

  const handleClear = () => {
    setRecommendation({
      ...recommendation,
      choosenRec: null,
    })
    setCurrentCoating(null)
  }


  useEffect(() => {
    if((choosenItem || choosenAccessory)&& !media) {
      handleOpenChoosenItem()
    }
  },[choosenItem, choosenAccessory])

  useEffect(() => {
    setProjectName(initialProjectName)
  },[initialProjectName])

  return <Box sx={{
    paddingLeft: '15px',
    borderLeft: '1px solid #ccc',
    '@media (max-width: 1100px)': {
      borderLeft: 'none',
      paddingLeft: '0px',
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
    }
  }}>
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '10px', paddingBottom: '5px' }}>
        <TextField
          value={projectName}
          onChange={(e) => setProjectName(e.target.value)}
          label={t('project.projectName')}
          variant="standard"
          sx={{
           minWidth: '170px'
          }}
        />
        <IconButton
          onClick={handleSubmit}
          disabled={projectName === initialProjectName && (currentColor?.hex ?? '') === initialColorName}
          sx={{
            padding: 0,
            color: 'red',
            "&:disabled": {
              color: '#ccc',
            }
          }}
        >
          <SaveIcon />
        </IconButton>
      </Box>
      {!accessoryView && <Typography
        sx={{
          marginTop: '10px',
          color: '#3366CC',
          fontWeight: '600',
          cursor: 'pointer',
          width: 'fit-content',
          display: 'flex',
          alignItems: 'center',
        }}>
        <span onClick={handleOpen}>{t('project.projectHelper')}</span>
        {recommendation?.choosenRec && <IconButton
            onClick={handleClear}
            sx={{
              padding: 0,
            }}>
            <ClearIcon color={'error'}/>
        </IconButton>}
      </Typography>}
      <ColorPicker />

    </Box>
    {!media && <IconButton
      onClick={handleOpenComputation}
    >
      <ShoppingCartIcon/>
    </IconButton>}
    <RecommendationModal isOpen={open.recommendation} handleClose={handleClose} />
    <ComputationModal open={open.computation} handleClose={handleClose} />
    <ChoosenItemModal open={open.choosenItem} handleClose={handleClose} />
  </Box>
}

export default memo(ProjectHelper);