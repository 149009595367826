import {Box, Button, Dialog, DialogContent, DialogTitle, Typography} from "@mui/material";
import CloseSvg from "../../../../assets/svgs/common/CloseSvg";
import React, {memo, useState} from "react";
import {useTranslation} from "react-i18next";

interface ISettingsProps {
  open: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
  title: string;
  text: string;
}
const ConfirmDelete = ({ handleClose, open, handleSubmit, title, text } : ISettingsProps ) => {
  const { t } = useTranslation()

  const submit = () => {
    handleSubmit()
    handleClose()
  }

  return <Dialog
    open={open}
    onClose={handleClose}
    sx={{
      '& .MuiBackdrop-root': {
        backgroundColor: 'rgba(0,0,0,0.1)' // Try to remove this to see the result
      },
      '& .MuiDialog-paper': {
        boxShadow: 'none'
      }
    }}
  >
    <DialogTitle sx={{
      display: 'flex',
      justifyContent: 'space-between',
      fontWeight: 600,
      color:'#373737'
    }}>
      {title}
      <Box onClick={handleClose}>
        <CloseSvg />
      </Box>
    </DialogTitle>


    <DialogContent sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: '25px',
    }}>
      <Typography variant={'body1'}>{text}</Typography>
      <Box sx={{
        display: 'flex',
        gap: '10px',
      }}>
        <Button
          fullWidth
          onClick={handleClose}
          color={'warning'}
          variant={'contained'}
        >
          {t('common.cancel')}
        </Button>
        <Button
          fullWidth
          onClick={submit}
          color={'error'}
          variant={'contained'}
        >
          {t('common.delete')}
        </Button>
      </Box>
    </DialogContent>
  </Dialog>

}

export default memo(ConfirmDelete)