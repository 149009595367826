import {useEffect, useState} from 'react';
import {Box} from "@mui/material";
import Router from "./components/Wrappers/RouterWrapper";
import {useUserStore} from "./store/userStore";
import useAsyncWrapper from "./hooks/useAsyncWrapper";
import Preloader from "./components/UI/PreLoader";
import {useGeneralStore} from "./store/generalStore";
import {useSearchParams} from "react-router-dom";
import {adminRoles} from "./constants";
import useToast from "./hooks/useToast";
import {useTranslation} from "react-i18next";
//wss://test2.perun.fun:443

function App() {
  const { t } = useTranslation()

  const { errorToast, successToast } = useToast()

  let [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);

  const user = useUserStore(state => state.user);
  const getProfile = useUserStore(state => state.getProfile);
  const getRoles = useGeneralStore(state => state.getRoles);
  const getPermissions = useGeneralStore(state => state.getPermissions);
  const loginTk = useUserStore(state => state.loginTk);

  const getPermissionsHandler = useAsyncWrapper(getPermissions);
  const getRolesHandler = useAsyncWrapper(getRoles);
  const getProfileHandler = useAsyncWrapper(getProfile);
  const loginTkeHandler = useAsyncWrapper(loginTk);


  useEffect(() => {
    (async () => {
      let user = null;

      const tk = searchParams.get('auth_token')
      if(tk && searchParams.get('status') === 'true') {
        const payload = {
          auth_token: tk,
        }
        const {data, error} = await loginTkeHandler([payload]);
        if(!error) {
          user = data
          successToast(t('signIn.success'))
        } else {
          errorToast(t('signIn.error.googleFail'))
        }


      } else if(searchParams.get('status') === 'false') {
        const isRegistered = searchParams?.get('error')?.includes('registered')
        if(isRegistered) {
          errorToast(t('signIn.error.accountNotRegistered'))
        } else
          errorToast(t('signIn.error.googleFail'))
      } else {
        const {data} = await getProfileHandler();
        user = data
      }

      if (adminRoles.includes(user?.data?.data?.role?.name)) {
        await getPermissionsHandler()
        await getRolesHandler()
      }


      setLoading(false)
    })()


  },[])



  return (
    <Box component={'main'}
      sx={{
        minHeight: '100vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {
        loading ? <Preloader /> : <Router />
      }
    </Box>
  );
}

export default App;
