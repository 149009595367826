import {Box, Dialog, DialogContent, Typography} from "@mui/material";
import {StyledDialogTitle} from "../../Auth/ForgotPassword/style";
import CloseSvg from "../../../../assets/svgs/common/CloseSvg";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import useAsyncWrapper from "../../../../hooks/useAsyncWrapper";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import EditMetalProductForm from "./EditMetalProductForm";
import {useHandbooksStore} from "../../../../store/handbooksStore";
import {useMetalProductStore} from "../../../../store/metalProductsStore";
import {useCoatingsStore} from "../../../../store/coatingsStore";
import {useImagesStore} from "../../../../store/imagesStore";

interface ISettingsProps {
  open: boolean;
  handleClose: () => void;
  id: number;
}
const EditMetalProduct = ({ handleClose, open, id } : ISettingsProps ) => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = React.useState(true);
  const [data, setData] = React.useState(null);

  const getMetalProduct = useMetalProductStore(state => state.getMetalProduct);
  const coatings = useCoatingsStore(state => state.tableData.data)
  const getMetalProductHandler = useAsyncWrapper(getMetalProduct)
  const setChoosedImages = useImagesStore(state => state.setChoosedImages)


  useEffect(() => {
    if(open) {
      (async () => {
        const { data } = await getMetalProductHandler([id]);
        setIsLoading(false)
        setData(data.data)
        setChoosedImages(data.data.images.map((image: any) => image.id))

      })()
    } else {
      setChoosedImages([])
      setIsLoading(true)
    }
  },[open])


  return <Dialog
    open={open}
    onClose={handleClose}
  >
    <StyledDialogTitle>
      <Typography
        fontWeight={600}
        variant={'h6'}
        color={'#373737'}
      >
        {t('metalProducts.edit')}
      </Typography>
      <Box onClick={handleClose}>
        <CloseSvg />
      </Box>
    </StyledDialogTitle>


    <DialogContent>
      {isLoading ? <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={0}
          sx={{
            width: '100%'
          }}
        >
          <CircularProgress size={30} />
        </Stack> :
        <EditMetalProductForm item={data!} handleClose={handleClose}/>}
    </DialogContent>
  </Dialog>

}

export default EditMetalProduct