import {useUserStore} from "../../store/userStore";
import {Navigate, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {PermissionsUser, RouteUrls} from "../../interfaces/enum";
import RouterWrapper from "./Router";
import SignUpPage from "../../pages/SignUpPage";
import SignInPage from "../../pages/SignInPage";
import Account from "../UI/Account";
import Users from "../../pages/Users";
import Admins from "../../pages/Admins";
import Projects from "../../pages/Projects";
import {useEffect} from "react";
import HandBooks from "../../pages/HandBooks";
import usePermission from "../../hooks/usePermission";
import Lids from "../../pages/Lids";
import MetalProducts from "../../pages/MetalProducts";
import Trays from "../../pages/Trays";
import Coatings from "../../pages/Coatings";
import Recommendations from "../../pages/Recommendations";
import Files from "../../pages/Files";
import Images from "../../pages/Images";
import Project from "../../pages/Project";
import PrivacyPolicy from "../../pages/PrivacyPolicy";

const Router = () => {
    const location = useLocation();
    const nav = useNavigate();
    const { hasPermission, isUser } = usePermission()
    const user = useUserStore(state => state.user)

    useEffect(() => {
      if(location.pathname === '/' && user) {
        nav(RouteUrls.PROJECTS)
      }
    },[location.pathname, user])

    return (<Routes>
        <Route
          path={'/'}
          element={
              <RouterWrapper user={user}>
                  <Account />
              </RouterWrapper>
          }
        >
          <Route
            index
            path={RouteUrls.PROJECTS}
            element={
              <RouterWrapper user={user}>{<Projects />}</RouterWrapper>
            }
          />
          <Route
            index
            path={RouteUrls.PROJECT_VIEW}
            element={
              <RouterWrapper user={user}>{<Project />}</RouterWrapper>
            }
          />
          <Route
            index
            path={RouteUrls.USERS}
            element={
              <RouterWrapper user={hasPermission(PermissionsUser.userList)}>{<Users />}</RouterWrapper>
            }
          />
          <Route
            index
            path={RouteUrls.ADMINS}
            element={
              <RouterWrapper user={hasPermission(PermissionsUser.userList)}>{<Admins />}</RouterWrapper>
            }
          />
          <Route
            index
            path={RouteUrls.HAND_BOOKS}
            element={
              <RouterWrapper user={true}>{<HandBooks />}</RouterWrapper> // hasPermission(PermissionsUser.handBookList) усі мають доступ
            }
          />
          <Route
            index
            path={RouteUrls.COATINGS}
            element={
              <RouterWrapper user={!isUser}>{<Coatings />}</RouterWrapper>
            }
          />
          <Route
            index
            path={RouteUrls.RECOMMENDATIONS}
            element={
              <RouterWrapper user={!isUser}>{<Recommendations />}</RouterWrapper>
            }
          />
          <Route
            index
            path={RouteUrls.LID}
            element={
              <RouterWrapper user={!isUser}>{<Lids />}</RouterWrapper>
            }
          />
          <Route
            index
            path={RouteUrls.METAL_PRODUCT}
            element={
              <RouterWrapper user={!isUser}>{<MetalProducts />}</RouterWrapper>
            }
          />
          <Route
            index
            path={RouteUrls.TRAY}
            element={
              <RouterWrapper user={!isUser}>{<Trays />}</RouterWrapper>
            }
          />
          <Route
            index
            path={RouteUrls.FILES}
            element={
              <RouterWrapper user={hasPermission(PermissionsUser.fileHistory)}>{<Files />}</RouterWrapper>
            }
          />
          <Route
            index
            path={RouteUrls.IMAGES}
            element={
              <RouterWrapper user={hasPermission(PermissionsUser.imageList)}>{<Images />}</RouterWrapper>
            }
          />
        </Route>
        <Route
          index
          path={RouteUrls.PRIVACY_POLICY}
          element={
            <PrivacyPolicy />
          }
        />
        <Route
            index
            path={RouteUrls.SIGN_IN}
            element={<SignInPage />}
        />
        <Route
            index
            path={RouteUrls.SIGN_UP}
            element={<SignUpPage />}
        />

        <Route
            path="*"
            element={<Navigate to={RouteUrls.PROJECTS} />}
        />
    </Routes>)
}

export default Router