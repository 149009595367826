import {Controller, useForm} from "react-hook-form";
import {ICreateRecommendationForm,
} from "../../../../../interfaces/general";
import {yupResolver} from "@hookform/resolvers/yup";
import {StyledFormControlLabel} from "./style";
import {Box, Button, Checkbox, Grid, ListItemText, MenuItem, Select, TextField} from "@mui/material";
import ButtonLoadingWrapper from "../../../../Wrappers/ButtonLoading";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import useAsyncWrapper from "../../../../../hooks/useAsyncWrapper";
import useToast from "hooks/useToast";

import {useRecommendationsStore} from "../../../../../store/recommendationsStore";
import {useCoatingsStore} from "../../../../../store/coatingsStore";
import {installations, products} from "../../../../../constants";
import {
  getRecommendationCreateValidationSchema
} from "../../../../../validations/recommendations/createRecommendations";


interface IProps {
  handleClose: () => void;
}
const initialFormState = {
  place_installation_uk: '',
  place_installation_en: '',
  coating_ids: [],
  product: products[0],
  installation: installations[0],
}

const CreateRecommendationForm = ({ handleClose }: IProps) => {
  const { t } = useTranslation()
  const { errorToast, successToast } = useToast();

  const [isLoading, setIsLoading] = useState(false);

  const createRecommendation = useRecommendationsStore(state => state.createRecommendation);
  const coatings = useCoatingsStore(state => state.tableData.data);

  const createRecommendationHandler = useAsyncWrapper(createRecommendation)

  const schema = getRecommendationCreateValidationSchema(t);

  const {handleSubmit, control, watch } = useForm<ICreateRecommendationForm>({
    defaultValues: initialFormState,
    resolver: yupResolver(schema),
  });

  const handleCreateSubmit = async (body: ICreateRecommendationForm) => {

    setIsLoading(true)
    const { error, status } = await createRecommendationHandler( [body] )
    setIsLoading(false)

    if(!error) {
      successToast(t('createRecommendation.success'))
      handleClose()
      return;
    }
    if (status === 400) {
      const errors = error?.response?.data?.message ?? [];
      if(error?.response?.data?.message.length > 0) {
        errors.forEach((err: any) => errorToast(t('error.error'), t('createRecommendation.error.400.' + err?.field)))
      }

      return
    }

    errorToast(t('createRecommendation.error.default'))
  }


  return (
    <Box
      component={'form'}
      onSubmit={handleSubmit(handleCreateSubmit)}
      sx={{
        '@media (min-width: 600px)': {
          minWidth: '350px',
        }
      }}
    >
      <Grid
        container
        spacing={1}
        mb={2}
      >
        <Grid item xs={12} md={12}>
          <Controller
            name={"coating_ids"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                label={t("recommendation.coating")}
                control={(
                  <Select
                    fullWidth
                    sx={{
                      padding: '0px',
                      '& .MuiSelect-select': {
                        padding: '10px 0 10px 10px',
                      }
                    }}
                    multiple
                    value={value}
                    renderValue={(selected) => selected.map(id => coatings.find(el => el.id === id)?.code).join(', ')}
                    onChange={onChange}
                    error={error?.ref?.name === name}
                  >
                    {coatings.map((coating) => {
                        return (
                          <MenuItem key={coating.id} value={coating.id}>
                            <Checkbox checked={value?.includes(coating.id)}/>
                            <ListItemText primary={coating.code}/>
                          </MenuItem>
                        );
                    })}
                  </Select>)
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name={"installation"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
                      <StyledFormControlLabel
                        labelPlacement="top"
                        sx={{
                          alignItems: 'flex-start'
                        }}
                        label={t("recommendation.installation")}
                        control={(
                          <Select
                            fullWidth
                            sx={{
                              padding: '0px',
                              '& .MuiSelect-select': {
                                padding: '10px 0 10px 10px',
                              }
                            }}
                            value={value}
                            onChange={onChange}
                            error={error?.ref?.name === name}
                          >
                            {installations.map((installation) => {
                              return (
                                <MenuItem key={installation} value={installation}>
                                  {t('common.'+installation)}
                                </MenuItem>
                              );
                            })}
                          </Select>)
                        }
                      />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name={"product"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                label={t("recommendation.product")}
                control={(
                  <Select
                    fullWidth
                    sx={{
                      padding: '0px',
                      '& .MuiSelect-select': {
                        padding: '10px 0 10px 10px',
                      }
                    }}
                    value={value}
                    onChange={onChange}
                    error={error?.ref?.name === name}
                  >
                    {products.map((product) => {
                      return (
                        <MenuItem key={product} value={product}>
                          {t('common.'+product)}
                        </MenuItem>
                      );
                    })}
                  </Select>)
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name={"place_installation_uk"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                label={t("recommendation.place_installation_uk")}
                control={
                  <TextField
                    fullWidth
                    onChange={onChange}
                    value={value}
                    error={error?.ref?.name === name}
                    helperText={error ? error.message : ' '}
                  />
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name={"place_installation_en"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                label={t("recommendation.place_installation_en")}
                control={
                  <TextField
                    fullWidth
                    onChange={onChange}
                    value={value}
                    error={error?.ref?.name === name}
                    helperText={error ? error.message : ' '}
                  />
                }
              />
            )}
          />
        </Grid>

      </Grid>
      <ButtonLoadingWrapper isLoading={isLoading}>
        <Button
          variant="contained"
          type="submit"
          fullWidth
          size="large"
          sx={{
            background: '#373737',
            '&:hover': {
              background: '#666',
            }
          }}
        >

          {t("createRecommendation.submit")}
        </Button>
      </ButtonLoadingWrapper>
    </Box>
  )
}
export default CreateRecommendationForm