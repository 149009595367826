import { create } from "zustand";

interface State {
  modalNumbers: {
    isOpen: boolean,
    data: any[],
    name: string,
    // tableDataModal: IResponseTable
  },
  menuOpen: boolean
}

interface Actions {
  toggleModalNumbers: (isOpen: boolean) => void;
  changeModalData: (data: any[]) => void;
  addModalData: (number: any) => void;
  changeModalName: (name: string) => void;
  setMenuOpen: (open: boolean) => void
}

const initialState: State = {
  modalNumbers: {
    isOpen: false,
    data: [],
    name: '',
  },
  menuOpen: true
};

export const useHelperStore = create<State & Actions>((set) => ({
  ...initialState,
  toggleModalNumbers: (isOpen) => {
    set((state) => ({
      ...state,
      modalNumbers: {
        ...state.modalNumbers,
        isOpen,
      }
    }))
  },
  setMenuOpen: (isOpen) => {
    set((state) => ({
      ...state,
      menuOpen: isOpen
    }))
  },
  changeModalData: (data) => {
    set((state) => ({
      ...state,
      modalNumbers: {
        ...state.modalNumbers,
        data
      } }));
  },
  addModalData: (number) => {
    set((state) => ({
      ...state,
      modalNumbers: {
        ...state.modalNumbers,
        data: state.modalNumbers.data.includes(number) ?  state.modalNumbers.data : [...state?.modalNumbers?.data, number]
      } }));
  },
  changeModalName: (name) => {
    set((state) => ({
      ...state,
      modalNumbers: {
        ...state.modalNumbers,
        name
      } }));
  }
}));
