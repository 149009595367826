import {api} from "../index";
import {IEditMetalProduct, IEditUser, IFilter,} from "../../interfaces/general";

interface IMetalProductApi {

}

export const metalProducts_api = {
  metalProducts: async (params: IFilter) => {
    return await api.get(`metal-products`,{
          params: {
            ...params,
            filters: JSON.stringify(params.filters)
          }
      })
  },
  importMetalProducts: async (params: IFilter) => {
    return await api.get(`metal-products/import`,{
          params: {
            ...params,
            filters: JSON.stringify(params.filters)
          }
      })
  },
  getMetalProduct: async (id: number) => {
    return await api.get(`metal-products/${id}`)
  },
  editMetalProduct: async (id: number, payload: IEditMetalProduct) => {
    return await api.patch(`metal-products/${id}`, payload)
  },
  createMetalProduct: async (data: IMetalProductApi) => {
    return await api.post(`metal-products`, data)
  },
  deleteMetalProduct: async (id: number) => {
    return await api.delete(`metal-products/${id}`)
  }

};

