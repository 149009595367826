import { createContext } from "react";

const SocketContext = createContext({
  messageError: {
    action: '',
    item: null,
    type: ''
  },
  messageSuccess: {
    action: '',
    item: null,
    type: ''
  },
});

export default SocketContext;
