import React, { useEffect, useRef, useCallback, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
// @ts-ignore
import {
    TextField as TextFieldMui,
    Paper as PaperMui,
    Popper, Divider,
} from "@mui/material";
import { styled } from "@mui/system";

let newData = 0;
let reload = false;
const Paper = ({ loadMore, maxPage, currentPage, ...props }) => {
    const scrollHandler = (e) => {
        if (reload) {
            e.currentTarget.scrollTop = newData;
            reload = false;
        }
        newData = e.currentTarget.scrollTop;
        if (
            e.currentTarget.scrollHeight <=
            e.currentTarget.scrollTop + e.currentTarget.offsetHeight + 20
        ) {
            loadMore();
        }
    };
    let scroll = useRef(null);
    useEffect(() => {
        if (scroll && scroll.current) {
            scroll.current.childNodes[0].addEventListener("scroll", scrollHandler);
            reload = true;
        }
        return () => {
            if (scroll && scroll.current) {
                scroll.current.childNodes[0].removeEventListener(
                    "scroll",
                    scrollHandler,
                    false
                );
            }
        };
    });
    return <PaperMui {...props} open={true} ref={scroll} />;
};

const TextField = styled(TextFieldMui)(({inputHeight}) => ({
    minWidth: '250px',
    label: {
        color: "#A9A9B7",
    },
    ".MuiChip-root": {
        backgroundColor: "#52525e",
    },
    ".MuiInput-underline:before": {
        borderBottom: "1px solid #A9A9B7",
    },
    "& .MuiInputBase-root": {
        height: inputHeight,
    },
}));

const AsyncSelect = ({
                                   placeholder,
                                   limitTags = 2,
                                   selectHandler,
                                   DataSetter,
                                   defaultValues,
                                    variant = "standard",
                                   height = "auto",
                                   accent = false,
                                   secondField = '',
                                   ...props
                               }) => {
    const [open, setOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(open && options.length === 0);
    const [currentPage, setCurrentPage] = useState(1);
    const [maxPage, setMaxPage] = useState(1);
    const [searchValue, setsSearchValue] = useState("");

    const debounce = (func) => {
        let timer;
        return function (...args) {
            const context = this;
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                timer = null;
                func.apply(context, args);
            }, 500);
        };
    };

    const inputChangeHandler = async (newValue) => {
        if (!newValue) {
            return;
        }
        newData = 0;
        setLoading(true);
        setCurrentPage(1);
        setsSearchValue(newValue);
        let { data } = await DataSetter(1, newValue);
        setOptions([...data]);
        setLoading(false);
    };
    const optimizedFn = useCallback(debounce(inputChangeHandler), []);
    const loadMore = async () => {
        if (currentPage <= maxPage - 1) {
            let { data } = await DataSetter(currentPage + 1, searchValue);
            setOptions([...options, ...data]);
            setCurrentPage(currentPage + 1);
        }
    };


    const userSelectHandler = (e, options) => {
        if (!options) {
            selectHandler("");
            return;
        }
        setOpen(false);
        selectHandler(options, props.name);
    };

    useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            setIsOpen(true);
            if (active && options.length === 0) {
                let { data, pages } = await DataSetter(1, "");
                setOptions([...data]);
                setMaxPage(pages);
            }
            setLoading(false);
        })();

        return () => {
            active = false;
        };
    }, [loading]);
    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open, defaultValues]);

    return (
        <Autocomplete
            limitTags={limitTags}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => {
                return option?.email
            }}
            open={open}
            renderOption={(props, option) => {
                return (
                    <li
                        style={{
                            height: '40px'
                        }}
                        key={option.id || 'default'}
                        {...props}
                    >
                        <p>{option?.email}</p>
                    </li>
                );
            }}
            onOpen={() => {
                setOpen(true);
                setLoading(true);
            }}
            onClose={() => {
                setOpen(false);
                setLoading(false);
            }}
            clearOnBlur={false}
            clearIcon={true}
            filterOptions={(x) => x}
            onInputChange={(e, newInputValue, reason) => {
                optimizedFn(e?.target?.value)
            }}
            onChange={userSelectHandler}
            options={options}
            loading={loading}
            PaperComponent={Paper}
            size="small"
            PopperComponent={Popper}
            defaultValue={defaultValues}
            componentsProps={{ paper: { loadMore: loadMore, maxPage, currentPage } }}
            loadingText={<p style={{ color: "white" }}>Loading</p>}
            noOptionsText={<p style={{ color: "white" }}>No options</p>}
            disableCloseOnSelect={true}
            disabledItemsFocusable={true}
            renderInput={(params) => {
                return (
                    <TextField
                        {...props}
                        {...params}
                        inputHeight={height}
                        placeholder={placeholder}
                        label={placeholder}
                        variant={variant}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {loading ? (
                                        <CircularProgress color="inherit" size={20} />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                    />
                );
            }}
        />
    );
};
export default AsyncSelect;
