import {TFunction} from "i18next";
import * as Yup from "yup";
import isEmail from "../../utils/isEmail";
import {phoneRegExp} from "../general";

export const getCreateUserValidationSchema = (t: TFunction<"translation", undefined>) =>
  Yup.object().shape({
    first_name: Yup.string(),//.required(t("signUp.error.first_name"))
    last_name: Yup.string(),//.required(t("signUp.error.last_name"))
    phone_number: Yup.string()
      .matches(phoneRegExp, t("signUp.error.phone_number"))
      .required(t("signUp.error.phone_number")),
    company: Yup.string(), //.required(t("signUp.error.company"))
    patronymic: Yup.string(), //.required(t("signUp.error.patronymic"))
    email: Yup.string()
      .required(t("signUp.error.email"))
      .test("pattern", t("signUp.error.emailPattern"), (email) => isEmail(email)),
    role_id: Yup.number().required(t("createUser.role")),
    password: Yup.string()
      .required(t("error.required"))
      .min(6, t("signUp.error.minLength"))
      .max(20, t("signUp.error.maxLength")),
    passwordConfirm: Yup.string().oneOf(
      [Yup.ref("password"), null as any],
      t("signUp.error.passwordConfirm")
    ),
    permission_ids: Yup.array(),
  });