import {TFunction} from "i18next";
import * as Yup from "yup";
import {installations, products} from "../../constants";


export const getCoatingCreateValidationSchema = (t: TFunction<"translation", undefined>) =>
  Yup.object().shape({
    name_en: Yup.string(),
    name_uk: Yup.string().required(t("error.required")),
    code: Yup.string().required(t("error.required")),

  });