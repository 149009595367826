import React, { useState } from "react";
import {
  Paper,
  Table,
  TableContainer, TablePagination,
} from "@mui/material";
import LidsTableHead from "./components/LidsTableHead";
import LidTableBody from "./components/LidTableBody";
import {useTranslation} from "react-i18next";
import LidsTableHeader from "./components/LidsTableHeader";

import TableLoading from "../../TableLoading";
import NoTableData from "../../NoTableData";
import {useLidsStore} from "../../../../store/lidsStore";
const LidsTable = () => {
  const { t } = useTranslation()

  const setFilter = useLidsStore(state => state.setFilter);
  const setPage = useLidsStore(state => state.setPage);
  const meta = useLidsStore(state => state.tableData.meta);
  const page = useLidsStore(state => state.page);
  const filter = useLidsStore(state => state.filter);
  const isTableLoading = useLidsStore(state => state.isTableLoading);

  const lids = useLidsStore(state => state.tableData)

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({ ...filter, perpage: +event.target.value });
  };


  return (
    <Paper sx={{
      width: '100%',
      overflow: 'hidden',
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      boxShadow: 'none',
      maxHeight: 'calc(100vh - 68px)',
    }}>
     <LidsTableHeader/>
      <TableContainer sx={{
        borderRadius: ' 0 0 12px 12px',
        flexGrow: 1,
        position: 'relative',
        height: '100%',
      }}>
        {isTableLoading ? <TableLoading /> : lids.data?.length ?
        <Table
          stickyHeader
          aria-label="sticky table"
          sx={{
            borderSpacing: '0 8px'
          }}
        >
          <LidsTableHead />
          <LidTableBody data={lids.data} />
        </Table> : <NoTableData />}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 15, 25, 100]}
        component="div"
        count={meta.total}
        rowsPerPage={filter.perpage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          overflow: 'hidden'
        }}
      />

    </Paper>
  )
}

export default LidsTable