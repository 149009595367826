import {Box, Button} from "@mui/material";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import React from "react";
import {Installation} from "../../../../../../interfaces/general";
import {useProjectStore} from "../../../../../../store/projectStore";
import useAsyncWrapper from "../../../../../../hooks/useAsyncWrapper";
import {useTranslation} from "react-i18next";


const InternalExternal = () => {
  const { t } = useTranslation();

  const setRecommendation = useProjectStore(state => state.setRecommendation);
  const recommendation = useProjectStore(state => state.recommendation);
  const {
    choosenRec,
    installation,
    recData,
    loadingRecommendation,
    filter
  } = recommendation;

  const getRecommendations = useProjectStore(state => state.getRecommendations)

  const getRecommendationsHandler = useAsyncWrapper(getRecommendations)




  const handleChoose = async (installation: Installation) => {
    setRecommendation({
      ...recommendation,
      installation
    })

    const customFilter = {
      ...filter,
      filters: [
        { "field_name": 'installation', "rule": 'contains', "value": installation }
      ]
    }

    const { data, error } = await getRecommendationsHandler([customFilter, 1])
  }

  return <Box sx={{
    display: 'flex',
    gap: '15px',
    width: '100%',
  }}>
    <Button
      fullWidth
      onClick={() => handleChoose('external')}
      color={'secondary'}
      variant={'outlined'}
      startIcon={<OpenInFullIcon />}
      sx={{
        height: '80px',
      }}
    >
      {t('common.external')}
    </Button>
    <Button
      fullWidth
      onClick={() => handleChoose('internal')}
      color={'error'}
      variant={'contained'}
      startIcon={<CloseFullscreenIcon />}
      sx={{
        height: '80px',
      }}
    >
      {t('common.internal')}
    </Button>

  </Box>
}

export default InternalExternal