import React, {useEffect} from "react";
import useAsyncWrapper from "../../hooks/useAsyncWrapper";
import {useLidsStore} from "../../store/lidsStore";
import {useTraysStore} from "../../store/traysStore";
import TraysTable from "../../components/UI/Tables/TraysTable";
import {useCoatingsStore} from "../../store/coatingsStore";
import {useImagesStore} from "../../store/imagesStore";
import ChooseImage from "../../components/UI/Modals/ChooseImages";

let prevFilter = {}
const Trays = () => {


  const getTrays = useTraysStore(state => state.getTrays)
  const filter = useTraysStore(state => state.filter);
  const filterImages = useImagesStore(state => state.modal.filterModal);

  const getCoatings = useCoatingsStore(state => state.getCoatings);
  const getLids = useLidsStore(state => state.getLids)

  const getImages = useImagesStore(state => state.getImages);
  const filterSelect = useCoatingsStore(state => state.filterSelect);

  const getCoatingsHandler = useAsyncWrapper(getCoatings);
  const getImagesHandler = useAsyncWrapper(getImages);
  const getTraysHandler = useAsyncWrapper(getTrays);
  const getLidsHandler = useAsyncWrapper(getLids);

  const page = useTraysStore(state => state.page);


  useEffect(() => {
    (async () => {
      if(JSON.stringify(prevFilter) !== JSON.stringify(filter) || filter.page !== page) {
        prevFilter = filter
        await getTraysHandler([filter, page])
      }
    })();
  },[filter, page])



  useEffect(() => {
    (async () => {
      await getCoatingsHandler([filterSelect, 0])
      await getImagesHandler([filterImages, 0, true])
      const filter = {
        filters: [],
        perpage: 100,
        page: 0,
      }
      await getLidsHandler([filterSelect, 0])
    })();
  },[])

  return (
    <>
      <TraysTable />
      <ChooseImage />
    </>
  );
};

export default Trays;
