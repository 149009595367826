import {Divider, Menu} from "@mui/material";
import {useUserStore} from "../../../../../store/userStore";
import {useTranslation} from "react-i18next";
import {StyledNavLink} from "../../style";
import {PermissionsUser, RouteUrls} from "../../../../../interfaces/enum";
import useMediaQuery from "@mui/material/useMediaQuery";
import useAsyncWrapper from "../../../../../hooks/useAsyncWrapper";
import SettingsIcon from '@mui/icons-material/Settings';
import KeyIcon from '@mui/icons-material/Key';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import GroupIcon from '@mui/icons-material/Group';
import FolderSharedIcon from '@mui/icons-material/FolderShared';

import {StyledMenuItem} from "./style";
import Settings from "../../../Modals/Settings";
import {useState} from "react";
import ResetPassword from "../../../Modals/ResetPassword";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark";
import usePermissions from "../../../../../hooks/usePermission";
import TakeoutDiningIcon from "@mui/icons-material/TakeoutDining";
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import ConstructionIcon from '@mui/icons-material/Construction';
import RecommendIcon from '@mui/icons-material/Recommend';
import RestoreIcon from '@mui/icons-material/Restore';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import SetUpEmails from "../../../Modals/SetUpEmails";
import {useProjectsStore} from "../../../../../store/projectsStore";

interface IOpenModal {
  password: boolean;
  settings: boolean;
  adminSettings: boolean;
}
interface IProps {
  anchorEl: null | HTMLElement;
  handleClose: () => void;
}
const DropdownList = ({ handleClose,  anchorEl }: IProps) => {
  const { t } = useTranslation();
  const media = useMediaQuery('(max-width: 600px)');
  const { hasPermission, isUser } = usePermissions();

  const [open, setOpen] = useState<IOpenModal>({
    password: false,
    settings: false,
    adminSettings: false,
  });

  const user = useUserStore(state => state.user);
  const logout = useUserStore(state => state.logout)
  const resetStore = useProjectsStore(state => state.resetStore)
  const logoutHandler = useAsyncWrapper(logout)

  const handleLogout = () => {
    logoutHandler()
    resetStore()
    handleClose()
  }

  const handleCloseModals = () => {
    setOpen({
      password: false,
      settings: false,
      adminSettings: false,
    })
  }
  const handleOpenSettings = () => {
    setOpen({
      ...open,
      settings: true,
    })
  }
  const handleOpenPassword = () => {
    setOpen({
      ...open,
      password: true,
    })
  }
  const handleOpenEmail = () => {
    setOpen({
      ...open,
      adminSettings: true,
    })
  }

  return <>
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={!!anchorEl}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          overflowY: 'auto',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <StyledMenuItem onClick={handleClose}>
        <AccountCircleIcon />
        {user.email}
      </StyledMenuItem>
      <Divider />


      {media && <StyledMenuItem onClick={handleClose}>
        <FolderSharedIcon />
        <StyledNavLink to={RouteUrls.PROJECTS}>{t('header.projects')}</StyledNavLink>
      </StyledMenuItem>}

      {hasPermission(PermissionsUser.systemConfiguration) && <StyledMenuItem onClick={handleOpenEmail}>
        <SettingsIcon/>
        {t('header.emailSettings')}
      </StyledMenuItem>}

      {media && hasPermission(PermissionsUser.userList) && <StyledMenuItem onClick={handleClose}>
        <AdminPanelSettingsIcon />
        <StyledNavLink to={RouteUrls.ADMINS}>{t('header.admins')}</StyledNavLink>
      </StyledMenuItem>}
      {media && hasPermission(PermissionsUser.userList) && <StyledMenuItem onClick={handleClose}>
        <GroupIcon />
        <StyledNavLink to={RouteUrls.USERS}>{t('header.users')}</StyledNavLink>
      </StyledMenuItem>}
      {media &&  <StyledMenuItem onClick={handleClose}>
        <CollectionsBookmarkIcon />
        <StyledNavLink to={RouteUrls.HAND_BOOKS}>{t('header.handBooks')}</StyledNavLink>
      </StyledMenuItem>} {/*hasPermission(PermissionsUser.handBookList)*/}
      {media && !isUser && <StyledMenuItem onClick={handleClose}>
          <ConstructionIcon />
          <StyledNavLink to={RouteUrls.COATINGS}>{t('header.coatings')}</StyledNavLink>
      </StyledMenuItem>}
      {media && !isUser && hasPermission(PermissionsUser.lidList) && <StyledMenuItem onClick={handleClose}>
        <AllInboxIcon />
        <StyledNavLink to={RouteUrls.LID}>{t('header.lids')}</StyledNavLink>
      </StyledMenuItem>}
      {media && !isUser && hasPermission(PermissionsUser.metalProductList) && <StyledMenuItem onClick={handleClose}>
        <PrecisionManufacturingIcon />
        <StyledNavLink to={RouteUrls.LID}>{t('header.metalProducts')}</StyledNavLink>
      </StyledMenuItem>}
      {media && !isUser && hasPermission(PermissionsUser.trayList) && <StyledMenuItem onClick={handleClose}>
        <TakeoutDiningIcon />
        <StyledNavLink to={RouteUrls.TRAY}>{t('header.trays')}</StyledNavLink>
      </StyledMenuItem>}
      {media && !isUser && hasPermission(PermissionsUser.recommendationList) && <StyledMenuItem onClick={handleClose}>
        <RecommendIcon />
        <StyledNavLink to={RouteUrls.RECOMMENDATIONS}>{t('header.recommendations')}</StyledNavLink>
      </StyledMenuItem>}
      {media && hasPermission(PermissionsUser.fileHistory) && <StyledMenuItem onClick={handleClose}>
        <RestoreIcon />
        <StyledNavLink to={RouteUrls.FILES}>{t('header.files')}</StyledNavLink>
      </StyledMenuItem>}
      {media && hasPermission(PermissionsUser.imageList) && <StyledMenuItem onClick={handleClose}>
        <CameraAltIcon />
        <StyledNavLink to={RouteUrls.IMAGES}>{t('header.images')}</StyledNavLink>
      </StyledMenuItem>}


      <StyledMenuItem onClick={handleOpenPassword}>
        <KeyIcon />
        {t('header.resetPassword')}
      </StyledMenuItem>
      <StyledMenuItem onClick={handleOpenSettings}>
        <SettingsIcon />
        {t('header.settings')}
      </StyledMenuItem>
      <StyledMenuItem onClick={handleLogout}>
        <LogoutIcon />
        {t('header.logout')}
      </StyledMenuItem>
    </Menu>
    <Settings open={open.settings} handleClose={handleCloseModals} />
    <ResetPassword open={open.password} handleClose={handleCloseModals} />
    <SetUpEmails open={open.adminSettings} handleClose={handleCloseModals} />
  </>
}

export default DropdownList