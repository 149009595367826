import {Controller, useForm} from "react-hook-form";
import {IEditUserForm} from "../../../../../interfaces/general";
import {yupResolver} from "@hookform/resolvers/yup";
import {getEditUserValidationSchema} from "../../../../../validations/editUser";
import {StyledFormControlLabel} from "./style";
import {Box, Button, Grid, TextField} from "@mui/material";
import ButtonLoadingWrapper from "../../../../Wrappers/ButtonLoading";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useUserStore} from "../../../../../store/userStore";
import useAsyncWrapper from "../../../../../hooks/useAsyncWrapper";
import useToast from "hooks/useToast";


const SettingsForm = () => {
  const { t } = useTranslation()
  const { errorToast, successToast } = useToast();

  const [isLoading, setIsLoading] = useState(false);

  const profileSettings = useUserStore(state => state.profileSettings);
  const user = useUserStore(state => state.user);

  const profileSettingsHandler = useAsyncWrapper(profileSettings)

  const initialFormState = {
    email: user?.email ?? '',
    first_name: user?.first_name ?? '',
    last_name: user?.last_name ?? '',
    phone_number: user?.phone_number ?? '',
    company: user?.company ?? '',
    patronymic: user?.patronymic ?? '',
  }

  const schema = getEditUserValidationSchema(t);
  const {handleSubmit, control } = useForm<IEditUserForm>({
    defaultValues: initialFormState,
    resolver: yupResolver(schema),
  });


  const handleEditSubmit = async (body: IEditUserForm) => {
    const payload = new Object(Object.entries(body))
      // @ts-ignore
      .reduce((obj, data) => initialFormState[data[0]] === data[1] ? obj : ({...obj, [data[0]]: data[1]}), {});


    setIsLoading(true)
    const { error } = await profileSettingsHandler( [payload] )
    setIsLoading(false)

    if(!error) {
      successToast(t('editUser.success'))
      return;
    }
    errorToast(t('editUser.error.default'))
  }


  return (
    <Box
      component={'form'}
      onSubmit={handleSubmit(handleEditSubmit)}
      sx={{
        '@media (min-width: 600px)': {
          minWidth: '350px',
        }
      }}
    >
      <Grid
        container
        spacing={1}
      >
        <Grid item xs={12} md={6}>
          <Controller
            name={"first_name"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                label={t("editUser.first_name")}
                control={
                  <TextField
                    fullWidth
                    onChange={onChange}
                    value={value}
                    error={error?.ref?.name === name}
                    helperText={error ? error.message : ' '}
                  />
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name={"last_name"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                label={t("editUser.last_name")}
                control={
                  <TextField
                    fullWidth
                    onChange={onChange}
                    value={value}
                    error={error?.ref?.name === name}
                    helperText={error ? error.message : ' '}
                  />
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name={"patronymic"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                label={t("editUser.patronymic")}
                control={
                  <TextField
                    fullWidth
                    onChange={onChange}
                    value={value}
                    error={error?.ref?.name === name}
                    helperText={error ? error.message : ' '}
                  />
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name={"email"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                label={t("editUser.email")}
                control={
                  <TextField
                    fullWidth
                    onChange={onChange}
                    value={value}
                    error={error?.ref?.name === name}
                    helperText={error ? error.message : ' '}
                  />
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name={"company"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                label={t("editUser.company")}
                control={
                  <TextField
                    fullWidth
                    onChange={onChange}
                    value={value}
                    error={error?.ref?.name === name}
                    helperText={error ? error.message : ' '}
                  />
                }
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            name={"phone_number"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                label={t("editUser.phone_number")}
                control={
                  <TextField
                    fullWidth
                    onChange={onChange}
                    value={value}
                    error={error?.ref?.name === name}
                    helperText={error ? error.message : ' '}
                  />
                }
              />
            )}
          />
        </Grid>
      </Grid>
      <ButtonLoadingWrapper isLoading={isLoading}>
        <Button
          variant="contained"
          type="submit"
          fullWidth
          size="large"
          sx={{
            background: '#373737',
            '&:hover': {
              background: '#666',
            }
          }}
        >

          {t("forgotPassword.submit")}
        </Button>
      </ButtonLoadingWrapper>
    </Box>
  )
}
export default SettingsForm