import {TFunction} from "i18next";
import * as Yup from "yup";


export const getHandbooksValidationSchema = (t: TFunction<"translation", undefined>) =>
  Yup.object().shape({
    name: Yup.string().required(t("error.required")),
    locale: Yup.string().required(t("error.required")),
    description: Yup.string().required(t("error.required")),
    type: Yup.string().required(t("error.required")),

  });