import {api} from "../index";
import {IEditUserForm, IFilter, ILogin, IRegister} from "../../interfaces/general";



export const roles_api = {
  roles: async (params: IFilter) => {
    return await api.get(`roles`,{
          params
      })
  },
};

