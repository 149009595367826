import React, {useEffect} from "react";
import useAsyncWrapper from "../../hooks/useAsyncWrapper";
import CoatingsTable from "../../components/UI/Tables/CoatingsTable";
import {useCoatingsStore} from "../../store/coatingsStore";

let prevFilter = {}
const Coatings = () => {

  const getCoatings = useCoatingsStore(state => state.getCoatings)
  const filter = useCoatingsStore(state => state.filter);
  const getCoatingsHandler = useAsyncWrapper(getCoatings);

  const page = useCoatingsStore(state => state.page);


  useEffect(() => {
    (async () => {
      if(JSON.stringify(prevFilter) !== JSON.stringify(filter) || filter.page !== page) {
        prevFilter = filter
        await getCoatingsHandler([filter, page])
      }
    })();
  },[filter, page])


  return (
      <>
        <CoatingsTable />
      </>
  );
};

export default Coatings;
