import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {StyledFormControlLabel} from "./style";
import {Box, Button, Grid, MenuItem, Select, TextField} from "@mui/material";
import ButtonLoadingWrapper from "../../../../Wrappers/ButtonLoading";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import useAsyncWrapper from "../../../../../hooks/useAsyncWrapper";
import useToast from "hooks/useToast";
import {useHandbooksStore} from "../../../../../store/handbooksStore";
import {getHandbooksValidationSchema} from "validations/handbooks/create";
import {IHandbookCreate} from "../../../../../interfaces/general";
import {handbookLangs, handbooksType} from "../../../../../constants";
import FileUpload from "../../../HandBooks/components/FileUpload";



const initialFormState = {
  name: '',
  description: '',
  locale: 'all',
  type: '1'
}

interface IProps {
  handleClose: () => void;
}

const CreateHandbookForm = ({ handleClose }: IProps) => {
  const { t } = useTranslation()
  const { errorToast, successToast } = useToast();


  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState<any[]>([]);

  const createHandbook = useHandbooksStore(state => state.createHandbook);
  const createHandbookHandler = useAsyncWrapper(createHandbook)

  const schema = getHandbooksValidationSchema(t);
  const {handleSubmit, control } = useForm<IHandbookCreate>({
    defaultValues: initialFormState,
    resolver: yupResolver(schema),
  });

  const handleSetFiles = (files: any) => {
    if(files.length > 1) {
      errorToast(t('handBook.error.filesLength'));
      return
    }
    setFiles(files)
  }


  const handleCreateSubmit = async (body: IHandbookCreate) => {
    if(!files.length) {
      errorToast(t('handBook.error.fileRequired'));
      return
    }

    const file = files[0];
    const formData = new FormData();

    Object.entries(body).forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.append('file', file)
    formData.append('hidden', '0')


    setIsLoading(true)
    const { error, status } = await createHandbookHandler( [formData] )
    setIsLoading(false)

    if(!error) {
      successToast(t('handBook.success'))
      handleClose()
      return;
    }
    if (status === 400) {
      const errors = error?.response?.data?.message ?? [];
      if(error?.response?.data?.message.length > 0) {
        errors.forEach((err: any) => errorToast(t('error.error'), t('handBook.error.400.' + err?.field)))
      }

      return
    }

    errorToast(t('handBook.error.default'))
  }


  return (
    <Box
      component={'form'}
      onSubmit={handleSubmit(handleCreateSubmit)}
      sx={{
        '@media (min-width: 600px)': {
          minWidth: '350px',
        }
      }}
    >
      <Grid
        container
        spacing={1}
        mb={2}
      >
        <Grid item xs={12} md={12}>
          <Controller
            name={"name"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                label={t("handBook.name")}
                control={
                  <TextField
                    fullWidth
                    onChange={onChange}
                    value={value}
                    error={error?.ref?.name === name}
                    helperText={error ? error.message : ' '}
                  />
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            name={"description"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start',
                  '& .MuiInputBase-root': {
                    height: '120px',
                    alignItems: 'flex-start',
                    '& textarea': {
                      overflow: 'auto!important',
                      height: '100%!important'
                    }
                  }
                }}
                label={t("handBook.description")}
                control={
                  <TextField
                    fullWidth
                    onChange={onChange}
                    value={value}
                    multiline
                    error={error?.ref?.name === name}
                    helperText={error ? error.message : ' '}
                  />
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name={"locale"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                label={t("handBook.locale")}
                control={(
                  <Select
                    fullWidth
                    sx={{
                      padding: '0px',
                      maxHeight: '40px',
                      marginBottom: '10px',
                      '& .MuiSelect-select': {
                        padding: '10px 0 10px 10px',
                      }
                    }}
                    value={value}
                    onChange={onChange}
                    error={error?.ref?.name === name}
                  >
                    {handbookLangs.map((lang) => {
                      return (
                        <MenuItem key={lang} value={lang}>
                          {lang}
                        </MenuItem>
                      );
                    })}
                  </Select>)
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name={"type"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                label={t("handBook.type.title")}
                control={(
                  <Select
                    fullWidth
                    sx={{
                      padding: '0px',
                      maxHeight: '40px',
                      marginBottom: '10px',
                      '& .MuiSelect-select': {
                        padding: '10px 0 10px 10px',
                      }
                    }}
                    value={value}
                    onChange={onChange}
                    error={error?.ref?.name === name}
                  >
                    {handbooksType.map((type) => {
                      return (
                        <MenuItem key={type.value + type.label} value={type.value}>
                          {t('handBook.type.' + type.label)}
                        </MenuItem>
                      );
                    })}
                  </Select>)
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <FileUpload handleChange={handleSetFiles} file={files}/>
        </Grid>
      </Grid>
      <ButtonLoadingWrapper isLoading={isLoading}>
        <Button
          variant="contained"
          type="submit"
          fullWidth
          size="large"
          sx={{
            background: '#373737',
            '&:hover': {
              background: '#666',
            }
          }}
        >

          {t("handBook.submitCreate")}
        </Button>
      </ButtonLoadingWrapper>
    </Box>
  )
}
export default CreateHandbookForm