import {api} from "../index";
import {
  ICreateRecommendationForm,
  ICreateUserForm, IEditRecommendation,
  IFilter,
} from "../../interfaces/general";


export const recommendations_api = {
  recommendations: async (params: IFilter) => {
    return await api.get(`recommendations`,{
          params: {
            ...params,
            filters: JSON.stringify(params.filters),
            coating_ids: JSON.stringify(params?.coating_ids || []),
          }
      })
  },
  getRecommendation: async (id: number) => {
    return await api.get(`recommendations/${id}`)
  },
  editRecommendation: async (id: number, payload: IEditRecommendation) => {
    return await api.patch(`recommendations/${id}`, payload)
  },
  createRecommendation: async (data: ICreateRecommendationForm) => {
    return await api.post(`recommendations`, data)
  },
  deleteRecommendation: async (id: number) => {
    return await api.delete(`recommendations/${id}`)
  }

};

