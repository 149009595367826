import {useTranslation} from "react-i18next";


const PrivacyPolicy = () => {
  const { i18n } = useTranslation()
  const lang = i18n.language

  const styleRed = {
    color: 'red'
  }

  return lang === 'en' ? (<div className="page" style={{
    maxWidth: '1400px',
    margin: '0 auto',
  }}>
    <div className="header">
      <div className="container">
        <p className="title">Privacy Policy for <span style={styleRed}>Каталог Хай Тек</span></p>
      </div>
    </div>
    <div className="translations-content-container">
      <div className="container">
        <div className="tab-content translations-content-item en visible" id="en">
          <h1>Privacy Policy</h1>
          <p>Last updated: January 11, 2024</p>
          <p>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</p>
          <p>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.
          </p>
          <h2>Interpretation and Definitions</h2>
          <h3>Interpretation</h3>
          <p>The words of which the initial letter is capitalized have meanings defined under the following conditions.
            The following definitions shall have the same meaning regardless of whether they appear in singular or in
            plural.</p>
          <h3>Definitions</h3>
          <p>For the purposes of this Privacy Policy:</p>
          <ul>
            <li>
              <p><strong>Account</strong> means a unique account created for You to access our Service or parts of our
                Service.</p>
            </li>
            <li>
              <p><strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control
                with a party, where "control" means ownership of 50% or more of the shares, equity interest or other
                securities entitled to vote for election of directors or other managing authority.</p>
            </li>
            <li>
              <p><strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement)
                refers to ТОВ <strong style={styleRed}>"ХАЙ-ТЕК", Україна, 03061, м. Київ, вул. Миколи Шепелєва, 2</strong></p>
            </li>
            <li>
              <p><strong>Cookies</strong> are small files that are placed on Your computer, mobile device or any other
                device by a website, containing the details of Your browsing history on that website among its many
                uses.</p>
            </li>
            <li>
              <p><strong>Country</strong> refers to: Ukraine</p>
            </li>
            <li>
              <p><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or
                a digital tablet.</p>
            </li>
            <li>
              <p><strong>Personal Data</strong> is any information that relates to an identified or identifiable
                individual.</p>
            </li>
            <li>
              <p><strong>Service</strong> refers to the Website.</p>
            </li>
            <li>
              <p><strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of
                the Company. It refers to third-party companies or individuals employed by the Company to facilitate the
                Service, to provide the Service on behalf of the Company, to perform services related to the Service or
                to assist the Company in analyzing how the Service is used.</p>
            </li>
            <li>
              <p><strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the
                Service or from the Service infrastructure itself (for example, the duration of a page visit).</p>
            </li>
            <li>
              <p><strong>Website</strong> refers to Каталог Хай Тек, accessible from <a
                href="http://hitek-platform.com" rel="external nofollow noopener"
                target="_blank">http://hitek-platform.com</a></p>
            </li>
            <li>
              <p><strong>You</strong> means the individual accessing or using the Service, or the company, or other
                legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
            </li>
          </ul>
          <h2>Collecting and Using Your Personal Data</h2>
          <h3>Types of Data Collected</h3>
          <h4>Personal Data</h4>
          <p>While using Our Service, We may ask You to provide Us with certain personally identifiable information that
            can be used to contact or identify You. Personally identifiable information may include, but is not limited
            to:</p>
          <ul>
            <li>
              <p>Email address</p>
            </li>
            <li>
              <p>First name and last name</p>
            </li>
            <li>
              <p>Phone number</p>
            </li>
            <li>
              <p>Usage Data</p>
            </li>
          </ul>
          <h4>Usage Data</h4>
          <p>Usage Data is collected automatically when using the Service.</p>
          <p>Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address),
            browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the
            time spent on those pages, unique device identifiers and other diagnostic data.</p>
          <p>When You access the Service by or through a mobile device, We may collect certain information
            automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique
            ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser
            You use, unique device identifiers and other diagnostic data.</p>
          <p>We may also collect information that Your browser sends whenever You visit our Service or when You access
            the Service by or through a mobile device.</p>
          <h4>Tracking Technologies and Cookies</h4>
          <p>We use Cookies and similar tracking technologies to track the activity on Our Service and store certain
            information. Tracking technologies used are beacons, tags, and scripts to collect and track information and
            to improve and analyze Our Service. The technologies We use may include:</p>
          <ul>
            <li><strong>Cookies or Browser Cookies.</strong> A cookie is a small file placed on Your Device. You can
              instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do
              not accept Cookies, You may not be able to use some parts of our Service. Unless you have adjusted Your
              browser setting so that it will refuse Cookies, our Service may use Cookies.
            </li>
            <li><strong>Web Beacons.</strong> Certain sections of our Service and our emails may contain small
              electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs)
              that permit the Company, for example, to count users who have visited those pages or opened an email and
              for other related website statistics (for example, recording the popularity of a certain section and
              verifying system and server integrity).
            </li>
          </ul>
          <p>Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on Your personal computer or
            mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser.
            Learn more about cookies on the <a
              href="https://www.freeprivacypolicy.com/blog/sample-privacy-policy-template/#Use_Of_Cookies_And_Tracking"
              target="_blank">Free Privacy Policy website</a> article.</p>
          <p>We use both Session and Persistent Cookies for the purposes set out below:</p>
          <ul>
            <li>
              <p><strong>Necessary / Essential Cookies</strong></p>
              <p>Type: Session Cookies</p>
              <p>Administered by: Us</p>
              <p>Purpose: These Cookies are essential to provide You with services available through the Website and to
                enable You to use some of its features. They help to authenticate users and prevent fraudulent use of
                user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We
                only use these Cookies to provide You with those services.</p>
            </li>
            <li>
              <p><strong>Cookies Policy / Notice Acceptance Cookies</strong></p>
              <p>Type: Persistent Cookies</p>
              <p>Administered by: Us</p>
              <p>Purpose: These Cookies identify if users have accepted the use of cookies on the Website.</p>
            </li>
            <li>
              <p><strong>Functionality Cookies</strong></p>
              <p>Type: Persistent Cookies</p>
              <p>Administered by: Us</p>
              <p>Purpose: These Cookies allow us to remember choices You make when You use the Website, such as
                remembering your login details or language preference. The purpose of these Cookies is to provide You
                with a more personal experience and to avoid You having to re-enter your preferences every time You use
                the Website.</p>
            </li>
          </ul>
          <p>For more information about the cookies we use and your choices regarding cookies, please visit our Cookies
            Policy or the Cookies section of our Privacy Policy.</p>
          <h3>Use of Your Personal Data</h3>
          <p>The Company may use Personal Data for the following purposes:</p>
          <ul>
            <li>
              <p><strong>To provide and maintain our Service</strong>, including to monitor the usage of our Service.
              </p>
            </li>
            <li>
              <p><strong>To manage Your Account:</strong> to manage Your registration as a user of the Service. The
                Personal Data You provide can give You access to different functionalities of the Service that are
                available to You as a registered user.</p>
            </li>
            <li>
              <p><strong>For the performance of a contract:</strong> the development, compliance and undertaking of the
                purchase contract for the products, items or services You have purchased or of any other contract with
                Us through the Service.</p>
            </li>
            <li>
              <p><strong>To contact You:</strong> To contact You by email, telephone calls, SMS, or other equivalent
                forms of electronic communication, such as a mobile application's push notifications regarding updates
                or informative communications related to the functionalities, products or contracted services, including
                the security updates, when necessary or reasonable for their implementation.</p>
            </li>
            <li>
              <p><strong>To provide You</strong> with news, special offers and general information about other goods,
                services and events which we offer that are similar to those that you have already purchased or enquired
                about unless You have opted not to receive such information.</p>
            </li>
            <li>
              <p><strong>To manage Your requests:</strong> To attend and manage Your requests to Us.</p>
            </li>
            <li>
              <p><strong>For business transfers:</strong> We may use Your information to evaluate or conduct a merger,
                divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our
                assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in
                which Personal Data held by Us about our Service users is among the assets transferred.</p>
            </li>
            <li>
              <p><strong>For other purposes</strong>: We may use Your information for other purposes, such as data
                analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to
                evaluate and improve our Service, products, services, marketing and your experience.</p>
            </li>
          </ul>
          <p>We may share Your personal information in the following situations:</p>
          <ul>
            <li><strong>With Service Providers:</strong> We may share Your personal information with Service Providers
              to monitor and analyze the use of our Service, to contact You.
            </li>
            <li><strong>For business transfers:</strong> We may share or transfer Your personal information in
              connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition
              of all or a portion of Our business to another company.
            </li>
            <li><strong>With Affiliates:</strong> We may share Your information with Our affiliates, in which case we
              will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any
              other subsidiaries, joint venture partners or other companies that We control or that are under common
              control with Us.
            </li>
            <li><strong>With business partners:</strong> We may share Your information with Our business partners to
              offer You certain products, services or promotions.
            </li>
            <li><strong>With other users:</strong> when You share personal information or otherwise interact in the
              public areas with other users, such information may be viewed by all users and may be publicly distributed
              outside.
            </li>
            <li><strong>With Your consent</strong>: We may disclose Your personal information for any other purpose with
              Your consent.
            </li>
          </ul>
          <h3>Retention of Your Personal Data</h3>
          <p>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in
            this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our
            legal obligations (for example, if we are required to retain your data to comply with applicable laws),
            resolve disputes, and enforce our legal agreements and policies.</p>
          <p>The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained
            for a shorter period of time, except when this data is used to strengthen the security or to improve the
            functionality of Our Service, or We are legally obligated to retain this data for longer time periods.</p>
          <h3>Transfer of Your Personal Data</h3>
          <p>Your information, including Personal Data, is processed at the Company's operating offices and in any other
            places where the parties involved in the processing are located. It means that this information may be
            transferred to — and maintained on — computers located outside of Your state, province, country or other
            governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.</p>
          <p>Your consent to this Privacy Policy followed by Your submission of such information represents Your
            agreement to that transfer.</p>
          <p>The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in
            accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization
            or a country unless there are adequate controls in place including the security of Your data and other
            personal information.</p>
          <h3>Delete Your Personal Data</h3>
          <p>You have the right to delete or request that We assist in deleting the Personal Data that We have collected
            about You.</p>
          <p>Our Service may give You the ability to delete certain information about You from within the Service.</p>
          <p>You may update, amend, or delete Your information at any time by signing in to Your Account, if you have
            one, and visiting the account settings section that allows you to manage Your personal information. You may
            also contact Us to request access to, correct, or delete any personal information that You have provided to
            Us.</p>
          <p>Please note, however, that We may need to retain certain information when we have a legal obligation or
            lawful basis to do so.</p>
          <h3>Disclosure of Your Personal Data</h3>
          <h4>Business Transactions</h4>
          <p>If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred.
            We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy
            Policy.</p>
          <h4>Law enforcement</h4>
          <p>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do
            so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>
          <h4>Other legal requirements</h4>
          <p>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</p>
          <ul>
            <li>Comply with a legal obligation</li>
            <li>Protect and defend the rights or property of the Company</li>
            <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
            <li>Protect the personal safety of Users of the Service or the public</li>
            <li>Protect against legal liability</li>
          </ul>
          <h3>Security of Your Personal Data</h3>
          <p>The security of Your Personal Data is important to Us, but remember that no method of transmission over the
            Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable
            means to protect Your Personal Data, We cannot guarantee its absolute security.</p>
          <h2>Children's Privacy</h2>
          <p>Our Service does not address anyone under the age of 13. We do not knowingly collect personally
            identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware
            that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have
            collected Personal Data from anyone under the age of 13 without verification of parental consent, We take
            steps to remove that information from Our servers.</p>
          <p>If We need to rely on consent as a legal basis for processing Your information and Your country requires
            consent from a parent, We may require Your parent's consent before We collect and use that information.</p>
          <h2>Links to Other Websites</h2>
          <p>Our Service may contain links to other websites that are not operated by Us. If You click on a third party
            link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy
            of every site You visit.</p>
          <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any
            third party sites or services.</p>
          <h2>Changes to this Privacy Policy</h2>
          <p>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new
            Privacy Policy on this page.</p>
          <p>We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming
            effective and update the "Last updated" date at the top of this Privacy Policy.</p>
          <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy
            are effective when they are posted on this page.</p>
          <h2>Contact Us</h2>
          <p>If you have any questions about this Privacy Policy, You can contact us:</p>
          <ul>
            <li>By email: <span style={styleRed}>office@hitek.kiev.ua</span></li>
          </ul>
        </div>
      </div>
    </div>
  </div> ) : <div className="page" style={{
    maxWidth: '1400px',
    margin: '0 auto',
  }}>
    <div className="header">
      <div className="container">
        <h1 className="title">Політика конфіденційності для <span style={styleRed}>Каталог Хай Тек</span></h1>
      </div>
    </div>
    <div className="translations-content-container">
      <div className="container">
        <div className="tab-content translations-content-item en visible" id="en">
          <h2>Політика конфіденційності</h2>
          <p>Останнє оновлення: 11 січня 2024 року</p>
          <p>Ця Політика конфіденційності описує наші положення та процедури щодо збору, використання та розкриття ваших даних під час використання вами Служби та розповідає вам про ваші права щодо конфіденційності та те, як закон захищає вас.</p>
          <p>Ми використовуємо ваші особисті дані для надання та покращення Служби. Використовуючи Службу, ви погоджуєтеся зі збором та використанням інформації відповідно до цієї Політики конфіденційності.</p>
          <h2>Тлумачення та визначення</h2>
          <h3>Тлумачення</h3>
          <p>Слова, перше літера яких велика, мають визначення, зазначене за наступних умов.</p>
          <p>Наступні визначення мають той самий зміст незалежно від того, чи зустрічаються вони в однині чи множині.</p>
          <h3>Визначення</h3>
          <p>З метою цієї Політики конфіденційності:</p>
          <ul>
            <li>
              <p><strong>Обліковий запис</strong> означає унікальний обліковий запис, створений для вас для доступу до нашої Служби або її частини.</p>
            </li>
            <li>
              <p><strong>Афіліат</strong> означає сутність, яка контролюється, контролює або перебуває під спільним контролем із стороною, де "контроль" означає володіння 50% чи більше акціями, еквіті часткою або іншими цінними паперами, які мають право голосу при виборах директорів чи інших керівних органів.</p>
            </li>
            <li>
              <p><strong>Компанія</strong> (також зазначається як "Компанія", "Ми", "Наша" чи "Наше" в цьому Угоді) вказує на ТОВ <strong style={styleRed}>"ХАЙ-ТЕК", Україна, 03061, м. Київ, вул. Миколи Шепелєва, 2</strong></p>
            </li>
            <li>
              <p><strong>Кукіс</strong> - це невеликі файли, які розміщуються на вашому комп'ютері, мобільному пристрої або іншому пристрої веб-сайтом та містять деталі вашої історії перегляду на цьому веб-сайті серед інших їхніх використань.</p>
            </li>
            <li>
              <p><strong>Країна</strong> вказує на: Україну</p>
            </li>
            <li>
              <p><strong>Пристрій</strong> означає будь-який пристрій, який може мати доступ до Служби, такий як комп'ютер, мобільний телефон чи цифровий планшет.</p>
            </li>
            <li>
              <p><strong>Особисті дані</strong> - це будь-яка інформація, яка стосується ідентифікованої чи ідентифікованої особи.</p>
            </li>
            <li>
              <p><strong>Служба</strong> вказує на Веб-сайт.</p>
            </li>
            <li>
              <p><strong>Надавач послуг</strong> означає будь-яку фізичну або юридичну особу, яка обробляє дані від імені Компанії. Це стосується сторонніх компаній чи осіб, які працюють на користь Компанії для полегшення Служби, надання Служби від імені Компанії, виконання послуг, пов'язаних із Службою, або допомоги Компанії у вивченні того, як використовується Служба.</p>
            </li>
            <li>
              <p><strong>Дані про використання</strong> вказують на дані, зібрані автоматично, згенеровані використанням Служби або з інфраструктури Служби самої (наприклад, тривалість візиту на сторінку).</p>
            </li>
            <li>
              <p><strong>Веб-сайт</strong> вказує на <span style={styleRed}>Каталог Хай Тек</span>, доступний за адресою <a href="http://hitek-platform.com" rel="external nofollow noopener" target="_blank">http://hitek-platform.com</a></p>
            </li>
            <li>
              <p><strong>Ви</strong> означає фізичну особу, яка має доступ або використовує Службу, або компанію чи інше юридичне утворення від імені якого така фізична особа має доступ або використовує Службу, застосовується.</p>
            </li>
          </ul>
          <h2>Збір та використання ваших особистих даних</h2>
          <h3>Типи зібраних даних</h3>
          <h4>Особисті дані</h4>
          <p>Під час використання нашої Служби ми можемо попросити вас надати нам певну особисту ідентифікаційну інформацію, яка може бути використана для зв'язку чи ідентифікації вас. Ця інформація може включати, але не обмежується:</p>
          <ul>
            <li>
              <p>Адреса електронної пошти</p>
            </li>
            <li>
              <p>Ім'я та прізвище</p>
            </li>
            <li>
              <p>Номер телефону</p>
            </li>
            <li>
              <p>Дані про використання</p>
            </li>
          </ul>
          <h4>Дані про використання</h4>
          <p>Дані про використання збираються автоматично під час використання Служби.</p>
          <p>Дані про використання можуть включати інформацію, таку як IP-адреса вашого пристрою (наприклад, IP-адреса), тип браузера, версія браузера, сторінки нашої Служби, які ви відвідуєте, час і дата вашого візиту, тривалість перебування на цих сторінках, унікальні ідентифікатори пристрою та інші діагностичні дані.</p>
          <p>Коли ви отримуєте доступ до Служби за допомогою мобільного пристрою, ми можемо автоматично збирати певну інформацію, зокрема, але не виключно, тип мобільного пристрою, унікальний ідентифікатор вашого мобільного пристрою, IP-адреса вашого мобільного пристрою, операційна система вашого мобільного пристрою, тип мобільного браузера, який ви використовуєте, унікальні ідентифікатори пристрою та інші діагностичні дані.</p>
          <p>Ми також можемо збирати інформацію, яку ваш браузер відправляє кожного разу, коли ви відвідуєте нашу Службу або коли ви отримуєте доступ до Служби за допомогою мобільного пристрою.</p>
          <h4>Технології відстеження та файли cookie</h4>
          <p>Ми використовуємо файли cookie та подібні технології відстеження для відстеження активності нашої Служби та зберігання певної інформації. Використовувані технології включають білизни, теги та скрипти для збору та відстеження інформації та для поліпшення та аналізу нашої Служби. Використовувані технології можуть включати:</p>
          <ul>
            <li><strong>Файли cookie або браузерні файли cookie.</strong> Cookie - це невеликий файл, розміщений на вашому пристрої. Ви можете навчити свій браузер відхиляти всі файли cookie або попереджати про їх відправлення. Проте, якщо ви не приймаєте файли cookie, ви можливо не зможете використовувати деякі частини нашої Служби. Якщо ви не налаштували свій браузер так, щоб він відхиляв файли cookie, наша Служба може використовувати файли cookie.</li>
            <li><strong>Веб бекони.</strong> Окремі розділи нашої Служби та наші електронні листи можуть містити невеликі електронні файли, відомі як білизни (також називаються чіткими гіфками, піксельними тегами та однопіксельними гіфками), які дозволяють Компанії, наприклад, підраховувати користувачів, які відвідали ці сторінки або відкрили електронний лист, та для інших пов'язаних статистичних даних веб-сайту (наприклад, фіксації популярності певного розділу та перевірки цілісності системи та сервера).</li>
          </ul>
          <p>Файли cookie можуть бути "Постійними" чи "Сеансовими". Постійні файли cookie залишаються на вашому персональному комп'ютері чи мобільному пристрої, коли ви виходите з мережі, тоді як сеансові файли cookie видаляються, як тільки ви закриваєте веб-браузер. Докладніше про файли cookie ви можете дізнатися з <a href="https://www.freeprivacypolicy.com/blog/sample-privacy-policy-template/#Use_Of_Cookies_And_Tracking" target="_blank">статті на веб-сайті Free Privacy Policy</a>.</p>
          <p>Ми використовуємо як сеансові, так і постійні файли cookie для визначених нижче цілей:</p>
          <ul>
            <li>
              <p><strong>Необхідні / Основні файли cookie</strong></p>
              <p>Тип: Сеансові файли cookie</p>
              <p>Адмініструються: Ми</p>
              <p>Мета: Ці файли cookie необхідні для надання вам послуг через Веб-сайт та для можливості використання деяких його функцій. Вони допомагають автентифікувати користувачів та запобігати шахрайському використанню облікових записів користувачів. Без цих файлів cookie не можна надати послуги, які ви запитали, і ми використовуємо ці файли cookie лише для надання вам цих послуг.</p>
            </li>
            <li>
              <p><strong>Політика / Повідомлення про прийняття файлів cookie</strong></p>
              <p>Тип: Постійні файли cookie</p>
              <p>Адмініструються: Ми</p>
              <p>Мета: Ці файли cookie визначають, чи користувачі прийняли використання файлів cookie на Веб-сайті.</p>
            </li>
            <li>
              <p><strong>Файли cookie для функціональності</strong></p>
              <p>Тип: Постійні файли cookie</p>
              <p>Адмініструються: Ми</p>
              <p>Мета: Ці файли cookie дозволяють нам запам'ятовувати ваші вибори, коли ви використовуєте Веб-сайт, такі як запам'ятовування ваших реквізитів для входу чи мовного вибору. Мета цих файлів cookie - забезпечити вам більш особистий досвід та уникнути необхідності знову вводити свої налаштування кожного разу, коли ви використовуєте Веб-сайт.</p>
            </li>
          </ul>
          <p>Для отримання додаткової інформації про файли cookie, які ми використовуємо, та ваші вибори щодо файлів cookie, відвідайте нашу Політику файлів cookie або розділ файлів cookie нашої Політики конфіденційності.</p>
          <h3>Використання ваших особистих даних</h3>
          <p>Компанія може використовувати особисті дані для наступних цілей:</p>
          <ul>
            <li>
              <p><strong>Надання та підтримка нашої Служби</strong>, включаючи моніторинг використання нашої Служби.</p>
            </li>
            <li>
              <p><strong>Управління вашим обліковим записом:</strong> управління вашою реєстрацією як користувача Служби. Надані вами особисті дані можуть надати вам доступ до різних функціональностей Служби, які доступні вам як зареєстрованому користувачу.</p>
            </li>
            <li>
              <p><strong>Виконання угоди:</strong> розробка, виконання та виконання угоди на закупівлю товарів, предметів чи послуг, які ви придбали чи будь-якої іншої угоди з нами через Службу.</p>
            </li>
            <li>
              <p><strong>Зв'язок з вами:</strong> Зв'язок з вами по електронній пошті, телефонних дзвінках, SMS чи іншими еквівалентними формами електронного зв'язку, такими як сповіщення за допомогою мобільного додатка щодо оновлень чи інформаційних повідомлень, пов'язаних з функціональностями, товарами чи послугами, які ви замовили чи договорилися, включаючи оновлення з питань безпеки, коли це необхідно чи розумно для їхньої реалізації.</p>
            </li>
            <li>
              <p><strong>Надання вам</strong> новин, спеціальних пропозицій та загальної інформації про інші товари, послуги та події, які ми пропонуємо і які схожі на ті, які ви вже придбали чи запитали, якщо ви не відмовилися отримувати таку інформацію.</p>
            </li>
            <li>
              <p><strong>Управління вашими запитаннями:</strong> обслуговування та управління вашими запитаннями до нас.</p>
            </li>
            <li>
              <p><strong>Для бізнес-передач:</strong> Ми можемо використовувати вашу інформацію для оцінки чи проведення злиття, відчуження, реструктуризації, реорганізації, розпуску чи іншого продажу чи передачі частини чи всіх наших активів, будь то в розгляді як існуючого підприємства, так і в рамках банкрутства, ліквідації чи подібної процедури, в якій особисті дані, які ми утримуємо про користувачів нашої Служби, є серед переданих активів.</p>
            </li>
            <li>
              <p><strong>З інших цілей</strong>: Ми можемо використовувати вашу інформацію для інших цілей, таких як аналіз даних, визначення тенденцій використання, визначення ефективності наших рекламних кампаній та оцінка та поліпшення нашої Служби, продуктів, послуг, маркетингу та вашого досвіду.</p>
            </li>
          </ul>
          <p>Ми можемо обмінювати вашою особистою інформацією в таких ситуаціях:</p>
          <ul>
            <li><strong>З постачальниками послуг:</strong> Ми можемо поділитися вашою особистою інформацією з постачальниками
              послуг для моніторингу та аналізу використання наших послуг, а також для зв'язку з вами.</li>
            <li><strong>Під час бізнес-передач:</strong> Ми можемо поділитися або передати вашу особисту інформацію у зв'язку
              з будь-яким злиттям, продажем активів компанії, фінансуванням або придбанням всього або частини нашого бізнесу
              іншій компанії.</li>
            <li><strong>З афіліатами:</strong> Ми можемо поділитися вашою інформацією з нашими афіліатами, при цьому ми
              будемо вимагати від цих афіліатів дотримання цієї політики конфіденційності. Афіліати включають нашу материнську
              компанію і будь-які інші підконтрольні нам дочірні компанії, партнерів по спільному підприємству або інші
              компанії, які перебувають під спільним контролем з нами.</li>
            <li><strong>З бізнес-партнерами:</strong> Ми можемо поділитися вашою інформацією з нашими бізнес-партнерами, щоб
              пропонувати вам певні продукти, послуги або акції.</li>
            <li><strong>З іншими користувачами:</strong> Коли ви ділитеся особистою інформацією або взаємодієте з іншими
              користувачами у відкритих зонах, така інформація може бути переглянута всіма користувачами і може бути
              публічно розповсюджена.</li>
            <li><strong>З вашою згодою:</strong> Ми можемо розкривати вашу особисту інформацію для інших цілей за вашою
              згодою.</li>
          </ul>

          <h3>Зберігання ваших особистих даних</h3>
          <p>Компанія буде зберігати ваші особисті дані лише стільки часу, наскільки це необхідно для визначених цілей, вказаних у
            цій політиці конфіденційності. Ми будемо зберігати та використовувати ваші особисті дані в міру необхідності для
            виконання наших правових обов'язків (наприклад, якщо ми повинні зберігати ваші дані для виконання вимог чинного
            законодавства), вирішення суперечок та виконання наших правових угод і політик.</p>
          <p>Компанія також буде зберігати дані про використання для внутрішньої аналітики. Дані про використання, як правило,
            зберігаються на коротший термін, за винятком випадків, коли ці дані використовуються для підвищення безпеки чи
            поліпшення функціональності нашого сервісу або якщо ми маємо юридичний обов'язок зберігати ці дані протягом довшого
            періоду.</p>

          <h3>Передача ваших особистих даних</h3>
          <p>Ваша інформація, включаючи особисті дані, обробляється в офісах Компанії та в інших місцях, де здійснюється
            обробка. Це означає, що ця інформація може бути передана — та зберігатися — на комп'ютерах, які знаходяться за
            межами вашої країни, провінції, країни чи іншої урядової юрисдикції, де закони про захист даних можуть відрізнятися
            від законів вашої юрисдикції.</p>
          <p>Ваша згода на цю політику конфіденційності, слідом за вашим поданням такої інформації, означає вашу згоду на цей
            передачу.</p>
          <p>Компанія здійснить всі розумні заходи для того, щоб забезпечити безпеку вашої інформації відповідно до цієї політики
            конфіденційності, і ніякого передавання ваших особистих даних не відбудеться до організації чи країни, якщо не буде
            забезпечено відповідні контролі, включаючи безпеку ваших даних та іншої особистої інформації.</p>

          <h3>Видалення ваших особистих даних</h3>
          <p>Ви маєте право видаляти або просити нас про допомогу у видаленні особистих даних, які ми зібрали про вас.</p>
          <p>Наш сервіс може надавати вам можливість видаляти певну інформацію про вас в межах сервісу.</p>
          <p>Ви можете оновлювати, змінювати або видаляти вашу інформацію в будь-який час, увійшовши в свій обліковий запис, якщо
            ви його маєте, і відвідуючи розділ налаштувань облікового запису, який дозволяє управляти вашою особистою
            інформацією. Ви також можете зв'язатися з нами, щоб запросити доступ, виправлення чи видалення будь-якої особистої
            інформації, яку ви нам надали.</p>
          <p>Зауважте, однак, що ми можемо зобов'язані зберігати певну інформацію, коли у нас є юридичний обов'язок чи законна
            підстава це зробити.</p>

          <h3>Розкриття вашої особистої інформації</h3>
          <h4>Бізнес-операції</h4>
          <p>Якщо Компанія бере участь у злитті, придбанні чи продажі активів, ваші особисті дані можуть бути передані.
            Ми повідомимо перед передачею ваших особистих даних та вони стануть предметом іншої політики конфіденційності.</p>
          <h4>Законоохоронні органи</h4>
          <p>В певних обставинах Компанія може бути зобов'язана розкрити ваші особисті дані, якщо це вимагається законом або у
            відповідь на обґрунтовані запити від публічних органів (наприклад, суду чи урядового агентства).</p>
          <h4>Інші юридичні вимоги</h4>
          <p>Компанія може розкривати ваші особисті дані у вірі в те, що такий крок є необхідним:</p>
          <ul>
            <li>Дотримуватися юридичного обов'язку</li>
            <li>Захист і захист прав або власності Компанії</li>
            <li>Запобігання чи розслідування можливого порушення в зв'язку із Сервісом</li>
            <li>Захист особистої безпеки користувачів Сервісу або громадської безпеки</li>
            <li>Захист від юридичної відповідальності</li>
          </ul>

          <h3>Безпека ваших особистих даних</h3>
          <p>Безпека ваших особистих даних для нас важлива, проте слід пам'ятати, що жоден метод передачі через Інтернет або метод
            електронного зберігання не є 100% надійним. Ми прагнемо використовувати комерційно прийнятні засоби для
            захисту ваших особистих даних, проте ми не можемо гарантувати абсолютну безпеку.</p>

          <h2>Конфіденційність дітей</h2>

          <p>Наш сервіс не надає послуги особам молодше 13 років. Ми свідомо не збираємо особисту ідентифікаційну інформацію від осіб молодше 13 років. Якщо ви батько або опікун і вам відомо, що ваша дитина надала нам особисті дані, будь ласка, зв'яжіться з нами. Якщо ми станемо відомі тому, що ми зібрали особисті дані від особи молодше 13 років без перевірки згоди батьків, ми вживемо заходів для видалення цієї інформації з наших серверів.</p>
          <p>Якщо нам потрібно полагоджуватися на згоду як на юридичну підставу для обробки вашої інформації, і ваша країна вимагає згоду від батьків, може знадобитися згода вашого батька перед тим, як ми зіберемо та використаємо цю інформацію.</p>
          <h2>Посилання на інші веб-сайти</h2>
          <p>Наш сервіс може містити посилання на інші веб-сайти, які не обслуговуються нами. Якщо ви клікнете на посилання на сторонній веб-сайт, вас перенаправлять на сайт цієї сторони. Ми настійно радимо вам розглянути Політику конфіденційності кожного відвідуваного вами сайту.</p>
          <p>Ми не маємо контролю і не несемо відповідальності за вміст, політику конфіденційності чи практики будь-яких сторонніх сайтів або послуг.</p>
          <h2>Зміни в цій Політиці конфіденційності</h2>
          <p>Ми можемо час від часу оновлювати нашу Політику конфіденційності. Ми повідомимо вас про будь-які зміни, розмістивши нову Політику конфіденційності на цій сторінці.</p>
          <p>Ми повідомимо вас по електронній пошті і/або помітному повідомленню на нашому сервісі перед тим, як зміни набудуть чинності, і оновимо дату "Останнє оновлення" у верхній частині цієї Політики конфіденційності.</p>
          <p>Вам рекомендується періодично переглядати цю Політику конфіденційності на предмет змін. Зміни в цій Політиці конфіденційності набирають чинності з моменту їх розміщення на цій сторінці.</p>
          <h2>Зв'яжіться з нами</h2>
          <p>Якщо у вас є питання щодо цієї Політики конфіденційності, ви можете зв'язатися з нами:</p>
          <ul>
            <li>За електронною поштою: <span style={styleRed}>office@hitek.kiev.ua</span> </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
}

export default PrivacyPolicy