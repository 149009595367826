import {Box, TableBody, TableRow, Typography, useMediaQuery} from "@mui/material";
import {useTranslation} from "react-i18next";
import { StyledTableCell} from "./style";
import useLang from "../../../../../../../../hooks/useLang";
import {useProjectStore} from "../../../../../../../../store/projectStore";



interface IProps {
  data: any[]
}
const ResultsTableBody = ({ data }: IProps) => {
  const { t } = useTranslation()
  const media = useMediaQuery('(min-width: 900px)');
  const { getTranslate } = useLang();

  const choosenItem = useProjectStore(state => state.choosenItem)
  const setChoosenItem = useProjectStore(state => state.setChoosenItem)
  const setCurrentPosition = useProjectStore(state => state.setCurrentPosition)


  const handleChoose = (item: any) => {
    setCurrentPosition(null)
    setChoosenItem(item);
  }

  return (<TableBody sx={{
    height: '100%',
    border: '1px solid #ccc',
  }}>
    {data
      ?.map((row) => {
       return (
          <TableRow
            hover
            role="checkbox"
            tabIndex={-1}
            key={row.id}
            onClick={() => handleChoose(row)}
            sx={{
              cursor: 'pointer',
              '& .MuiTypography-root': {
                fontWeight: media && choosenItem?.id === row?.id ? 600 : 400,
              },
              fontWeight: 500,
              '&.MuiTableRow-hover:hover': {
                opacity: 0.8,
              },
              '& .MuiTableCell-root': {
                backgroundColor: '#D8D9DA',
                color: 'black'
              },
              "&:nth-of-type(odd) .MuiTableCell-root": {
                backgroundColor: '#fff',
              }
            }}>
            <StyledTableCell>
              <Typography>{getTranslate('description', row)}</Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography>{row.article}</Typography>
            </StyledTableCell>
          </TableRow>
        );
      })}

  </TableBody>)
}

export default ResultsTableBody