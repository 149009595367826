import {Link} from "react-router-dom";
import {RouteUrls} from "../../../interfaces/enum";
import SecurityIcon from '@mui/icons-material/Security';

const ButtonPrivacyPolicy = () => {
  return (
    <Link
      to={RouteUrls.PRIVACY_POLICY}
      target={"_blank"}
      style={{
        position: 'fixed',
        bottom: '20px',
        right: '20px',
      }}
    >
      <SecurityIcon color={'error'}/>
    </Link>
  )
}

export default ButtonPrivacyPolicy;