import {Box, Button, TableBody, TableRow, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {StyledActionButton, StyledTableCell} from "./style";
import useAsyncWrapper from "../../../../../../hooks/useAsyncWrapper";
import ConfirmDelete from "../../../../Modals/ConfirmDelete";
import {useState} from "react";
import usePermission from "../../../../../../hooks/usePermission";
import {PermissionsUser} from "../../../../../../interfaces/enum";
import useToast from "../../../../../../hooks/useToast";
import EditLid from "../../../../Modals/EditLid";
import {useLidsStore} from "../../../../../../store/lidsStore";
import {arrayJoin} from "../../../../../../utils/arrayJoin";


interface IProps {
  data: any[]
}
const LidTableBody = ({ data }: IProps) => {
  const { t, i18n } = useTranslation()
  const { errorToast, successToast} = useToast()
  const { hasPermission } = usePermission()

  const currentLangEn = i18n.language === 'en';

  const [id, setId] = useState(0);
  const [open, setOpen] = useState({
    delete: false,
    edit: false
  });

  const handleOpenDelete = (id: number) => {
    setId(id)

    setOpen({
      ...open,
      delete: true
    })
  }
  const handleOpenEdit = (id: number) => {
    setId(id)
    setOpen({
      ...open,
      edit: true
    })
  }
  const handleCloseDelete = () => {
    setOpen({
      ...open,
      delete: false
    })
    setId(0)
  }
  const handleCloseEdit = () => {
    setOpen({
      ...open,
      edit: false
    })
    setId(0)
  }

  const deleteLid = useLidsStore(state => state.deleteLid);
  const deleteLidHandler = useAsyncWrapper(deleteLid);

  const handleDeleteUser = async (id: number) => {
    const {error} = await deleteLidHandler([id])
    if(!error) {
      successToast(t('deleteLid.success'))
      return;
    }
    errorToast(t('deleteLid.error'));  }


  return (<TableBody>

    {data
      ?.map((row) => {
        return (
          <TableRow
            hover
            role="checkbox"
            tabIndex={-1}
            key={row.id}
            sx={{
              '&.MuiTableRow-hover:hover': {
                opacity: 0.8,
              },
              '& .MuiTableCell-root': {
                backgroundColor: '#D8D9DA',
                color: 'black'
              }
            }}>
            <StyledTableCell>
              <Typography>{row.id}</Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography>{row.name_en}</Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography>{row.name_uk}</Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography>{row.article_prefix_1}</Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography>{row.article_prefix_2}</Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography>{t(`lids.${row.type}`)}</Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Box sx={{
                display: 'flex',
                gap: '5px'
              }}>
                <StyledActionButton disabled={!hasPermission(PermissionsUser.lidUpdate)} onClick={() => handleOpenEdit(row.id)}>
                  <EditIcon color={'warning'} />
                </StyledActionButton>
                <StyledActionButton disabled={!hasPermission(PermissionsUser.lidDestroy)} onClick={() => handleOpenDelete(row.id)}>
                  <DeleteIcon color={'error'}/>
                </StyledActionButton>
              </Box>
            </StyledTableCell>
          </TableRow>
        );
      })}
    <ConfirmDelete
      text={t('deleteLid.submissionText')}
      title={t('deleteLid.title')}
      handleSubmit={() => handleDeleteUser(id)}
      handleClose={handleCloseDelete}
      open={open.delete}
    />
    <EditLid
      id={id}
      open={open.edit}
      handleClose={handleCloseEdit}
    />
  </TableBody>)
}

export default LidTableBody