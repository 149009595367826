import {Box} from "@mui/material";
import {styled} from "@mui/system";

export const StyledWrapper = styled(Box)(({}) => ({
  marginTop: '45px',
  position: 'relative',
  width: '320px',
  height: '300px',
  border: '2px solid #ccc',
  borderRadius: '12px',
  padding: '10px',
  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  '@media (max-width: 1350px)': {
    width: '280px',
    marginTop: '35px',
    height: '260px',
    gap: '15px',
  },
  '@media (max-width: 1200px)': {
    marginTop: '30px',
    width: '260px',
    height: '240px',
    gap: '10px',
  }
}));