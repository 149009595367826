import {Autocomplete, Box, TextField, Typography} from "@mui/material";
import {useMemo, useRef, useState} from "react";
import {useProjectStore} from "../../../../../../../../store/projectStore";


interface IProps {
  label: string,
  list: any[]
  handleChange: (value: any) => void,
  inputValue: any
  disabled?: boolean

}

const CustomAutocomplete = ({  label, list, handleChange, inputValue, disabled = false }: IProps) => {
  // const hint = useRef("");

  const options = list ? list?.map((option: any) => option.toString()) : []

  return  (
      <Autocomplete
          options={options}
          // onKeyDown={(event) => {
          //   if (event.key === "Tab") {
          //     if (hint.current) {
          //       handleChange(hint.current);
          //       event.preventDefault();
          //     }
          //   }
          // }}
          // onBlur={() => {
          //   hint.current = "";
          // }}
          disabled={disabled}
          disableClearable={!inputValue}
          onChange={(event: any, newValue: any) => handleChange(newValue)}
          disablePortal
          value={inputValue}
          // inputValue={inputValue}
          // filterOptions={(options, state) => {
          //   const displayOptions = options.filter((option: any) =>
          //     option.toString().startsWith(state.inputValue)
          //   );
          //   return displayOptions;
          // }}
          sx={{
            minWidth: '100px',
          }}
          renderInput={(params) => (<Box sx={{ position: "relative" }}>
            {/*@ts-ignore*/}
            <TextField
              {...params}
              variant={"standard"}
              label={label}
            />
          </Box>
          )}
        />)
}

export default CustomAutocomplete