import {api} from "../index";
import {ICheckCode, ILogin, IRegister, IResetPassword} from "../../interfaces/general";



export const auth_api = {
  login: async (body: ILogin) => {
    return await api.post(`auth/login`, body);
  },
  loginTk: async (body: any) => {
    return await api.post(`/auth/login/with-token`, body);
  },
  register: async (body: IRegister) => {
    return await api.post(`auth/register`, body);
  },
  forgotPasswordEmail: async (email: string) => {
    return await api.post(`auth/recovery-code/send`, email);
  },
  forgotPasswordCheck: async (payload: ICheckCode) => {
    return await api.post(`auth/recovery-code/check`, payload);
  },
  forgotPasswordChange: async (payload: IResetPassword) => {
    return await api.post(`auth/reset-password`, payload);
  },
  refresh: async () => {
    return await api.post(`auth/refresh`);
  },
  logout: async () => {
    return await api.post(`auth/logout`);
  }

};

