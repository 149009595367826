import {Box, Dialog, DialogContent, Typography} from "@mui/material";
import {StyledDialogTitle} from "../../Auth/ForgotPassword/style";
import CloseSvg from "../../../../assets/svgs/common/CloseSvg";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import CreateRecommendationForm from "./CreateRecomendationForm";

interface ISettingsProps {
  open: boolean;
  handleClose: () => void;
}
const CreateRecommendation = ({ handleClose, open } : ISettingsProps ) => {
  const { t } = useTranslation()


  return <Dialog
    open={open}
    onClose={handleClose}
  >
    <StyledDialogTitle>
      <Typography
        fontWeight={600}
        variant={'h6'}
        color={'#373737'}
      >
        { t('createRecommendation.title')}
      </Typography>
      <Box onClick={handleClose}>
        <CloseSvg />
      </Box>
    </StyledDialogTitle>


    <DialogContent>
      <CreateRecommendationForm handleClose={handleClose} />
    </DialogContent>
  </Dialog>

}

export default CreateRecommendation