import {api} from "../index";
import {
  IFilter, Product,
} from "../../interfaces/general";

export const model_parameters_api = {
  modelParameters: async (params: IFilter, type: Product, id?: number | null) => {
    return await api.get(`model-parameters/${type}/${id || ''}`,{
          params: {
            ...params,
            filters: JSON.stringify(params.filters)
          }
      })
  },
  modelParametersAccessories: async (params: IFilter, payload: any ) => {
    console.log(params)
    return await api.post(`model-parameters/accessories`,payload, {
      params: {
        ...params,
        filters: JSON.stringify(params.filters)
      },
      headers: {
        'content-type': 'application/x-www-form-urlencoded'
      }
    }
    )
  },
  modelParameterLidByAccessory: async (id: number) => {
      return await api.get(`model-parameters/accessories/lid/${id}`)
  },
  modelValues: async (type: 'tray' | 'accessory', id: number) => {
    return await api.post(`model-parameters/available-values`,{
          model_type: type,
          model_id: id,
      })
  },


};

