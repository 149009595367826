import {api} from "../index";
import {IFilter} from "../../interfaces/general";



export const permissions_api = {
  permissions: async (payload: IFilter) => {
    return await api.get(`permissions`, {
      params: payload
    });
  },


};

