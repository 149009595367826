import React, {useEffect} from "react";
import useAsyncWrapper from "../../hooks/useAsyncWrapper";
import RecommendationsTable from "../../components/UI/Tables/RecomendationsTable";
import {useRecommendationsStore} from "../../store/recommendationsStore";
import {useCoatingsStore} from "../../store/coatingsStore";

let prevFilter = {}
const Recommendations = () => {

  const getRecommendations = useRecommendationsStore(state => state.getRecommendations)
  const filter = useRecommendationsStore(state => state.filter);
  const getCoatings = useCoatingsStore(state => state.getCoatings);
  const page = useRecommendationsStore(state => state.page);
  const filterSelect = useCoatingsStore(state => state.filterSelect);


  const getRecommendationsHandler = useAsyncWrapper(getRecommendations);
  const getCoatingsHandler = useAsyncWrapper(getCoatings);



  useEffect(() => {
    (async () => {
      if(JSON.stringify(prevFilter) !== JSON.stringify(filter) || filter.page !== page) {
        prevFilter = filter
        await getRecommendationsHandler([filter, page])
      }
    })();
  },[filter, page])

  useEffect(() => {
    (async () => {
      await getCoatingsHandler([filterSelect, 0])
    })();
  },[])

  return (
      <>
        <RecommendationsTable />
      </>
  );
};

export default Recommendations;
