import {Box, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import {useTranslation} from "react-i18next";
import {newObj} from "../../../../../utils/i18n";
import compaprer from "../../../../../utils/compaprer";
import UkraineSvg from "../../../../../assets/svgs/common/UkraineSvg";
import UKSvg from "../../../../../assets/svgs/common/UK";

const SelectLang = () => {
  const { i18n } = useTranslation();

  let currentLanguage = i18n.language;

  const handleChange = () => {
    const lang = currentLanguage === "ua" ? "en" : "ua"
    i18n.changeLanguage(lang);
    localStorage.setItem("lang", lang);
  };

  return (
    <Box
    sx={{
      padding: '0px',
      maxHeight: '40px',
      cursor: 'pointer',
      display: 'flex',
    }}
    onClick={handleChange}
  >
      {compaprer(currentLanguage, 'ua') ? <UkraineSvg /> : <UKSvg />}
  </Box>)
}
export default SelectLang