import {useTranslation} from "react-i18next";
import useToast from "../../../../../hooks/useToast";
import React, {useState} from "react";
import useAsyncWrapper from "../../../../../hooks/useAsyncWrapper";
import {Controller, useForm} from "react-hook-form";
import {
  IEditTray,
  ITray,
} from "../../../../../interfaces/general";
import {yupResolver} from "@hookform/resolvers/yup";
import {Box, Button, Checkbox, Grid, ListItemText, MenuItem, Select, TextField} from "@mui/material";
import {StyledFormControlLabel} from "../../Settings/SettingsForm/style";
import ButtonLoadingWrapper from "../../../../Wrappers/ButtonLoading";
import createFilteredPayload from "../../../../../utils/removeDuplicate";
import {useTraysStore} from "../../../../../store/traysStore";
import {getTrayEditValidationSchema} from "../../../../../validations/trays/editTray";
import {useImagesStore} from "../../../../../store/imagesStore";
import { trayTypes} from "../../../../../constants";
import CustomImageField from "../../../CustomImageField";
import {useLidsStore} from "../../../../../store/lidsStore";


interface IProps {
  item: ITray;
  handleClose: () => void;

}

const EditTrayForm = ({ item, handleClose } : IProps) => {
  const { t } = useTranslation()
  const { errorToast, successToast } = useToast();

  const [isLoading, setIsLoading] = useState(false);

  const editTray = useTraysStore(state => state.editTray);
  const lids = useLidsStore(state => state.tableData.data);
  const choosedImages = useImagesStore(state => state.modal.choosedImages)

  const editTrayHandler = useAsyncWrapper(editTray)

  const initialFormState = {
    name_uk: item?.name_uk ?? '',
    name_en: item?.name_en ?? '',
    article_prefix_2: item?.article_prefix_2 ?? '',
    article_prefix_1: item?.article_prefix_1 ?? '',
    type: item?.type ?? "tray",
    lid_ids: item?.lids?.map(el => el.id) ?? [],
    partition_ids: item?.partitions?.map(el => el.id) ?? [],
  }
  const schema = getTrayEditValidationSchema(t);
  const {handleSubmit, control, watch} = useForm<IEditTray>({
    defaultValues: initialFormState,
    resolver: yupResolver(schema),
  });

  const handleEditSubmit = async (body: IEditTray) => {
    const payload = createFilteredPayload(body, initialFormState)

    setIsLoading(true);
    const { error , status} = await editTrayHandler( [item?.id, {
      ...payload,
      image_ids: choosedImages,
    }]);
    setIsLoading(false);

    if (status === 400) {
      const errors = error?.response?.data?.message ?? [];
      if(error?.response?.data?.message.length > 0) {
        errors.forEach((err: any) => errorToast(t('error.error'), t('editTray.error.400.' + err?.field)))
      }

      return
    }
    if(!error) {
      successToast(t('editTray.success'))
      handleClose()
      return;
    }
    errorToast(t('editTray.error.default'))
  }


  return (
    <Box
      component={'form'}
      onSubmit={handleSubmit(handleEditSubmit)}
      sx={{
        '@media (min-width: 600px)': {
          minWidth: '350px',
        }
      }}
    >
      <Grid
        container
        spacing={1}
        mb={2}
      >

        <Grid item xs={12} md={6}>
          <Controller
            name={"name_uk"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                label={t("trays.name_uk")}
                control={
                  <TextField
                    fullWidth
                    onChange={onChange}
                    value={value}
                    error={error?.ref?.name === name}
                    helperText={error ? error.message : ' '}
                  />
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name={"name_en"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                label={t("trays.name_en")}
                control={
                  <TextField
                    fullWidth
                    onChange={onChange}
                    value={value}
                    error={error?.ref?.name === name}
                    helperText={error ? error.message : ' '}
                  />
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name={"article_prefix_1"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                label={t("trays.article_prefix_1")}
                control={
                  <TextField
                    fullWidth
                    onChange={onChange}
                    value={value}
                    error={error?.ref?.name === name}
                    helperText={error ? error.message : ' '}
                  />
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name={"article_prefix_2"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                label={t("trays.article_prefix_2")}
                control={
                  <TextField
                    fullWidth
                    onChange={onChange}
                    value={value}
                    error={error?.ref?.name === name}
                    helperText={error ? error.message : ' '}
                  />
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            name={"type"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                label={t("trays.type")}
                control={(
                  <Select
                    fullWidth
                    sx={{
                      padding: '0px',
                      '& .MuiSelect-select': {
                        padding: '10px 0 10px 10px',
                      }
                    }}
                    value={value}
                    onChange={onChange}
                    error={error?.ref?.name === name}
                  >
                    {trayTypes.map((trayType) => {
                      return (
                        <MenuItem key={trayType} value={trayType}>
                          {t('trays.'+trayType)}
                        </MenuItem>
                      );
                    })}
                  </Select>)
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            name={"lid_ids"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                label={t("trays.lids")}
                control={(
                  <Select
                    fullWidth
                    sx={{
                      padding: '0px',
                      '& .MuiSelect-select': {
                        padding: '10px 0 10px 10px',
                      }
                    }}
                    multiple
                    value={value}
                    renderValue={(selected) => selected.map(id => lids.find(el => el.id === id)?.name_uk).join(', ')}
                    onChange={onChange}
                    error={error?.ref?.name === name}
                  >
                    {lids.map((lid) => {
                      if(lid?.type === 'lid')
                        return (
                          <MenuItem key={lid.id} value={lid.id}>
                            <Checkbox checked={value?.includes(lid.id)}/>
                            <ListItemText primary={lid.name_uk}/>
                          </MenuItem>
                        );
                    })}
                  </Select>)
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            name={"partition_ids"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                label={t("trays.partitions")}
                control={(
                  <Select
                    fullWidth
                    sx={{
                      padding: '0px',
                      '& .MuiSelect-select': {
                        padding: '10px 0 10px 10px',
                      }
                    }}
                    multiple
                    value={value}
                    renderValue={(selected) => selected.map(id => lids.find(el => el.id === id)?.name_uk).join(', ')}
                    onChange={onChange}
                    error={error?.ref?.name === name}
                  >
                    {lids.map((lid) => {
                      if(lid?.type === 'partition')
                        return (
                          <MenuItem key={lid.id} value={lid.id}>
                            <Checkbox checked={value?.includes(lid.id)}/>
                            <ListItemText primary={lid.name_uk}/>
                          </MenuItem>
                        );
                    })}
                  </Select>)
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <StyledFormControlLabel
            labelPlacement="top"
            sx={{
              alignItems: 'flex-start',
            }}
            label={t("images.addImages")}
            control={
              <CustomImageField />
            }
          />
        </Grid>
      </Grid>
      <ButtonLoadingWrapper isLoading={isLoading}>
        <Button
          variant="contained"
          type="submit"
          fullWidth
          size="large"
          sx={{
            background: '#373737',
            '&:hover': {
              background: '#666',
            }
          }}
        >

          {t("handBook.submitEdit")}
        </Button>
      </ButtonLoadingWrapper>
    </Box>
  )
}
export default EditTrayForm