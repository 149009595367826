import {NavLink} from "react-router-dom";
import {styled} from "@mui/system";


export const StyledNavLink = styled(NavLink)(() => ({
  boxSizing: 'border-box',
  textDecoration: 'none',
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  color: '#666',
  padding: '5px 10px',
  borderBottom: '2px solid transparent',
  '&:hover, &.active': {
    borderBottom: '2px solid #666',
  },
  '@media (max-width: 600px)': {
    padding: 0
  }
}))
export const StyledHeader = styled('header')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingRight: '20px',
}))