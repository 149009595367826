import {Box} from "@mui/material";
import {useTranslation} from "react-i18next";
import NoTableData from "../../../NoTableData";
import ImageCard from "./ImageCard";
import {useImagesStore} from "../../../../../store/imagesStore";


const ImageList = () => {
  const { t } = useTranslation();

  const images = useImagesStore(state => state.tableData.data)

  return (<Box sx={{
    display: 'flex',
    gap: '30px',
    position: 'relative',
    flexWrap: 'wrap',
    height: images.length ? 'auto' : '100%',
    '@media (max-width: 600px)': {
      justifyContent: 'center'
    }
  }}>
    {images.length ? images?.map((el) => <ImageCard key={el.id} image={el}/>) : <NoTableData />}
  </Box>)
}

export default ImageList