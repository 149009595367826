import { create } from "zustand";
import {
  IAvailableValues,
  ICoating, ICurrentModelValues,
  IFilter, Installation, IPosition, IPositionItem,
  IResponseTable,
  IRole
} from "../interfaces/general";
import {trays_api} from "../api/apis/trays.api";
import {model_parameters_api} from "../api/apis/modelParmeters.api";
import {projects_api} from "../api/apis/projects.api";
import {positions_api} from "../api/apis/positions.api";
import {recommendations_api} from "../api/apis/recommendations.api";


interface IProjectState {
  isLoading: boolean,
  isTableLoading: boolean,
  traysData: IResponseTable
  filter: IFilter,
  page: number,
  search: string,
  currentTray: any,
  currentCoating: any,
  currentModelValues: ICurrentModelValues,
  model_values: IAvailableValues
  resultList: any[],
  isResultLoading: boolean,
  currentColor: any,
  choosenItem: any,
  projectLength: number,
  projectName: string,
  isLid: boolean,
  loadingCart: boolean,
  trayQuantity: number | string,
  accessoryQuantity: number | string,
  project_positions: IPositionItem[],
  currentPosition: IPositionItem | null,
  exportLoading: boolean,
  accessories: any[],
  choosenAccessory: any,
  trayAccessory: any,
  searchAccessory: string,
  accessoriesLoading: boolean,
  accessoryView: boolean,
  recommendation: {
    choosenRec: any,
    installation: Installation | null,
    recData: IResponseTable
    filter: IFilter,
    loadingRecommendation: boolean,
  },
  accessoriesFilterList: any[],
  currentTypeAccessory: any,
  currentModelValuesAccessory: {
    thickness: any,
  },
  choosenLid: any,
  choosenPartition: any,
  with_partition: boolean,
}
interface IProjectStore  extends IProjectState{
  setPage: (page: number) => void
  setSearch: (search: string) => void
  setFilter: (filter: IFilter) => void
  setCurrentTray: (currentTray: any) => void,
  setCurrentModelValues: (model_values: ICurrentModelValues) => void,
  clearModelValues: () => void,
  setCurrentCoating: (currentCoating: ICoating | null) => void
  getProjectTrays: (params: IFilter, page: number) => Promise<any>,
  getModelParametersById: (params: IFilter, id: number) => Promise<any>,
  traysParameters: (payload: any) => Promise<any>,
  getModelValues: (id: number, type: 'tray' | 'accessory') => Promise<any>,
  setColor: (currentColor: string | null) => void,
  setChoosenItem: (choosenItem: any) => void,
  setLid: (isLid: boolean) => void,
  setProjectLength: (projectLength: number) => void,
  setProjectName: (projectName: string) => void,
  getPositionsByProject: (id: number) => Promise<any>,
  setTrayQuantity: (trayQuantity: number | string) => void,
  addPosition: (body: IPosition) => Promise<any>,
  editPosition: (body: IPosition, id: number) => Promise<any>,
  setPositions: (positions: IPositionItem[]) => void,
  setCurrentPosition: (currentPosition: IPositionItem | null) => void,
  deletePosition: (id: number) => Promise<any>,
  resetStore: () => void,
  setRecommendation: (recommendation: any) => void,
  getRecommendations: (params: IFilter, id: number) => Promise<any>,
  getResult: (payload: any) => Promise<any>,
  getsAccessoriesById: (params: IFilter, tray_model_parameter_id: number, tray_accessory_id?: number | null) => Promise<any>,
  setChoosenAccessory: (choosenAccessory: any) => void,
  setTrayAccessory: (trayAccessory: any) => void,
  setSearchAccessory: (searchAccessory: string) => void,
  setAccessoryView: (accessoryView: boolean) => void,
  setAccessoryQuantity: (accessoryQuantity: number | string) => void,
  setCurrentModelValuesAccessory: (modelValuesAccessory: any) => void,
  setCurrentTypeAccessory: (modelValuesAccessory: any) => void,
  getLidByAccessory: (id: number) => Promise<any>,
  getLidsByTray: (payload: any) => Promise<any>,
  setChoosenLid: (choosenLid: any) => void,
  setPartition: (with_partition: boolean) => void,
  setChoosenPartition: (choosenPartition: any) => void,
  getPartitionsByTray: (payload: any) => Promise<any>,
}

export const initialState = {
  isLoading: false,
  accessoriesLoading: false,
  search: '',
  currentTray: null,
  currentCoating: null,
  loadingCart: false,
  choosenAccessory: null,
  choosenItem: null,
  trayQuantity: 1,
  accessoryQuantity: 1,
  projectName: '',
  accessories: [],
  trayAccessory: null,
  recommendation: {
    choosenRec: null,
    loadingRecommendation: false,
    installation: null,
    filter: {
      filters: [],
      perpage: 100,
      page: -1,
    },
    recData: {
      data: [],
      meta: {
        total: 0,
        current_page: 0,
        last_page: 0,
        perpage: 0,
      }
    }
  },
  currentModelValues: {
    width: '',
    height: '',
    length: '',
  },
  traysData: {
    data: [],
    meta: {
      total: 0,
      current_page: 0,
      last_page: 0,
      perpage: 0,
    }
  },
  filter: {
    filters: [],
    perpage: 50,
    page: -1,
    type: 'tray'
  },
  model_values: {
    unit_of_measurement: [],
    width: [],
    height: [],
    length: [],
    weight: [],
    angle: [],
    thickness: [],
  },
  currentColor: null,
  resultList: [],
  isResultLoading: false,
  page: 0,
  projectLength: 1,
  isLid: false,
  isTableLoading: true,
  currentPosition: null,
  project_positions: [],
  exportLoading: false,
  searchAccessory: '',
  accessoryView: false,
  accessoriesFilterList: [],
  currentTypeAccessory: null,
  choosenLid: null,
  choosenPartition: null,
  with_partition: false,
  currentModelValuesAccessory: {
    thickness: '',
  }
}

export const useProjectStore = create<IProjectStore>((set) => ({
  ...initialState,
  setPage: (page) => {
    set(state => ({...state, page}))
  },
  setPartition: (with_partition) => {
    set(state => ({...state, with_partition}))
  },
  setChoosenPartition: (choosenPartition) => {
    set(state => ({...state, choosenPartition}))
  },
  setAccessoryView: (accessoryView) => {
    set(state => ({...state, accessoryView}))
  },
  setCurrentModelValuesAccessory: (currentModelValuesAccessory) => {
    set(state => ({...state, currentModelValuesAccessory}))
  },
  setChoosenLid: (choosenLid) => {
    set(state => ({...state, choosenLid}))
  },
  setCurrentTypeAccessory: (currentTypeAccessory) => {
    set(state => ({...state, currentTypeAccessory}))
  },
  setAccessoryQuantity: (accessoryQuantity) => {
    set(state => ({...state, accessoryQuantity}))
  },
  setSearchAccessory: (searchAccessory) => {
    set(state => ({...state, searchAccessory}))
  },
  setRecommendation: (recommendation) => {
    set(state => ({...state,
      recommendation,
    }))
  },
  setChoosenAccessory: (choosenAccessory) => {
    set(state => ({...state,
      choosenAccessory,
    }))
  },
  setTrayAccessory: (trayAccessory) => {
    set(state => ({...state,
      trayAccessory,
    }))
  },
  setCurrentPosition: (currentPosition) => {
    set(state => ({
      ...state,
      currentPosition,
      projectLength: currentPosition?.length || initialState.projectLength,
      isLid: currentPosition?.with_lid || false,
      with_partition: currentPosition?.with_partition || false,
      trayQuantity: currentPosition?.quantity || initialState.trayQuantity,
      color: currentPosition?.color ?? '',
      choosenItem: currentPosition?.tray_model_parameter || initialState.choosenItem,

    }))
  },
  setPositions: (project_positions) => {
    set(state => ({...state, project_positions}))
  },
  setTrayQuantity: (trayQuantity) => {
    set(state => ({...state, trayQuantity}))
  },
  setLid: (isLid) => {
    set(state => ({...state, isLid}))
  },
  setProjectName: (projectName) => {
    set(state => ({...state, projectName}))
  },
  setProjectLength: (projectLength) => {
    set(state => ({...state, projectLength}))
  },
  setChoosenItem: (choosenItem) => {
    set(state => ({
      ...state,
      choosenItem,
      projectLength: choosenItem?.length / 1000
    }))
  },
  setFilter: (filter) => {
    set(state => ({...state, filter, page: 0 }))
  },
  setSearch: (search) => {
    set(state => ({...state, search}))
  },
  setColor: (currentColor) => {
    set(state => ({...state, currentColor}))
  },
  resetFilter: () => {
    set(state => ({...state, filter: initialState.filter, page: 0 }))
  },
  setCurrentTray: (currentTray) => {
    set(state => ({...state, currentTray}))
  },
  setCurrentCoating: (currentCoating) => {
    set(state => ({...state, currentCoating}))
  },
  setCurrentModelValues: (data) => {
    set(state => ({...state,
      currentModelValues: data }))
  },
  clearModelValues: () => {
    set(state => ({...state,
      currentModelValues: initialState.currentModelValues, currentCoating: initialState.currentCoating,
      currentModelValuesAccessory: initialState.currentModelValuesAccessory, currentTypeAccessory: initialState.currentTypeAccessory,
    }))
  },
  resetStore: () => {
    set(state => ({...state, ...initialState}))
  },
  getRecommendations: async (params) => {
    set(state => ({...state, recommendation: {...state.recommendation, loadingRecommendation: true}}))
    const response = await recommendations_api.recommendations({...params, page: 1})
      .finally(() => set(state => ({...state, recommendation: {...state.recommendation, loadingRecommendation: false}})));

    const { status, data } = response;

    if (!status || !data) {
      throw new Error("No status code or data returned from server.");
    }

    if (status !== 200) {
      throw new Error(data);
    }

    set((state) => ({
      ...state,
      recommendation: {
        ...state.recommendation,
        recData: data
      }
    }));
  },
  getResult: async (payload) => {
    set(state => ({...state, recommendation: {...state.recommendation, exportLoading: true}}))
    const response = await projects_api.getResult(payload)
      .finally(() => set(state => ({...state, recommendation: {...state.recommendation, exportLoading: false}})));

    const { status, data } = response;

    if (!status || !data) {
      throw new Error("No status code or data returned from server.");
    }

    if (status !== 200) {
      throw new Error(data);
    }

    set((state) => ({
      ...state,
    }));
    return data
  },
  traysParameters: async (payload) => {
    set(state => ({...state, loadingCart: true}))
    const response = await projects_api.traysParameters(payload)
      .finally(() => set(state => ({...state, loadingCart: false})));

    const { status, data } = response;

    if (!status || !data) {
      throw new Error("No status code or data returned from server.");
    }

    if (status !== 200) {
      throw new Error(data);
    }

    set((state) => ({
      ...state,
    }));
  },
  getProjectTrays: async (params, page) => {
    set(state => ({...state, isTableLoading: true}))
    const response = await trays_api.trays({...params, page: 1})
      .finally(() => set(state => ({...state, isTableLoading: false})));

    const { status, data } = response;

    if (!status || !data) {
      throw new Error("No status code or data returned from server.");
    }

    if (status !== 200) {
      throw new Error(data);
    }
    if(params.type === 'tray')
      set((state) => ({
        ...state,
        traysData: data
      }));
    else
      set((state) => ({
        ...state,
        accessoriesFilterList: data.data
      }));
  },
  getLidByAccessory: async (id) => {
    const response = await model_parameters_api.modelParameterLidByAccessory(id)

    const { status, data } = response;

    if (status !== 200) {
      throw new Error(data);
    }

    return data
  },
  getModelParametersById: async (params, id) => {
    set(state => ({...state, isResultLoading: true}))
    const isRecommendation = !!params?.recommendation_id

    const response = await model_parameters_api.modelParameters({...params, page: 1}, 'tray', isRecommendation ? null : id)
      .finally(() => set(state => ({...state, isResultLoading: false})));

    const { status, data } = response;

    if (!status || !data) {
      throw new Error("No status code or data returned from server.");
    }

    if (status !== 200) {
      throw new Error(data);
    }

    set((state) => ({
      ...state,
      resultList: data.data
    }));
  },
  getsAccessoriesById: async (params, tray_model_parameter_id, tray_accessory_id) => {
    set(state => ({...state, accessoriesLoading: true}))
    const payload = {
      tray_model_parameter_id,
      tray_accessory_id
    }
    const response = await model_parameters_api.modelParametersAccessories({...params, page: 1}, payload)
      .finally(() => set(state => ({...state, accessoriesLoading: false})));

    const { status, data } = response;

    if (!status || !data) {
      throw new Error("No status code or data returned from server.");
    }

    if (status !== 200) {
      throw new Error(data);
    }

    set((state) => ({
      ...state,
      accessories: data.data
    }));
  },
  getModelValues: async (id, type = 'tray') => {
    set(state => ({...state, isTableLoading: true}))

    const response = await model_parameters_api.modelValues( type, id)
      .finally(() => set(state => ({...state, isTableLoading: false})));

    const { status, data } = response;

    if (!status || !data) {
      throw new Error("No status code or data returned from server.");
    }

    if (status !== 200) {
      throw new Error(data);
    }
    set((state) => ({
      ...state,
      model_values: data.data
    }));
  },
  getPositionsByProject: async (id) => {
    set(state => ({...state, loadingCart: true}))

    const response = await positions_api.getPositionsByProject(id)
      .finally(() => set(state => ({...state, loadingCart: false})));

    const { status, data } = response;

    if (!status || !data) {
      throw new Error("No status code or data returned from server.");
    }

    if (status !== 200) {
      throw new Error(data);
    }

    set((state) => ({
      ...state,
      project_positions: data.data
    }));
  },
  getLidsByTray: async (payload) => {
    set(state => ({...state, loadingCart: true}))

    const response = await projects_api.getLidsByTray(payload)
      .finally(() => set(state => ({...state, loadingCart: false})));

    const { status, data } = response;

    if (!status || !data) {
      throw new Error("No status code or data returned from server.");
    }

    set((state) => ({
      ...state,
    }));
    return data;
  },
  getPartitionsByTray: async (payload) => {
    set(state => ({...state, loadingCart: true}))

    const response = await projects_api.getPartitionsByTray(payload)
      .finally(() => set(state => ({...state, loadingCart: false})));

    const { status, data } = response;

    if (!status || !data) {
      throw new Error("No status code or data returned from server.");
    }

    set((state) => ({
      ...state,
    }));
    return data;
  },
  addPosition: async (body) => {
    set(state => ({...state, loadingCart: true}))

    const response = await positions_api.createPositions(body)
      .finally(() => set(state => ({...state, loadingCart: false})));

    const { status, data } = response;

    if (!status || !data) {
      throw new Error("No status code or data returned from server.");
    }

    if (status !== 201) {
      throw new Error(data);
    }

    set((state) => ({
      ...state,
    }));
  },
  editPosition: async (body, id) => {
    set(state => ({...state, loadingCart: true}))

    const response = await positions_api.editPosition(id, body)
      .finally(() => set(state => ({...state, loadingCart: false})));

    const { status, data } = response;

    if (!status || !data) {
      throw new Error("No status code or data returned from server.");
    }

    if (status !== 200) {
      throw new Error(data);
    }

    set((state) => {
      return ({
        ...state,
        project_positions: state.project_positions.map(item => item.id === id ? data.data : item),
      })
    }
    )
  },
  deletePosition: async (id) => {
    set(state => ({...state, loadingCart: true}))

    const response = await positions_api.deletePosition(id)
      .finally(() => set(state => ({...state, loadingCart: false})));

    const { status, data } = response;


    if (status !== 204) {
      throw new Error(data);
    }

    set((state) => ({
      ...state,
      project_positions: state.project_positions.filter(item => item.id !== id)
    }));
  },

}));

export const useProjectsStoreOut = useProjectStore.getState();