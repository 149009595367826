import {Box, Dialog, DialogContent, Typography} from "@mui/material";
import {StyledDialogTitle} from "../../Auth/ForgotPassword/style";
import CloseSvg from "../../../../assets/svgs/common/CloseSvg";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import EditUserForm from "./EditUserForm";
import {useUsersTableStore} from "../../../../store/usersTableStore";
import useAsyncWrapper from "../../../../hooks/useAsyncWrapper";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";

interface ISettingsProps {
  open: boolean;
  handleClose: () => void;
  id: number;
}
const EditUser = ({ handleClose, open, id } : ISettingsProps ) => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = React.useState(true);
  const [data, setData] = React.useState(null);

  const getUser = useUsersTableStore(state => state.getUser);
  const getUserHandler = useAsyncWrapper(getUser)


  useEffect(() => {
    if(open) {
      (async () => {
        const { data } = await getUserHandler([id]);
        setData(data.data)
        setIsLoading(false)
      })()
    } else {
      setIsLoading(true)
    }
  },[open])

  return <Dialog
    open={open}
    onClose={handleClose}
  >
    <StyledDialogTitle>
      <Typography
        fontWeight={600}
        variant={'h6'}
        color={'#373737'}
      >
        {t('editUser.title')}
      </Typography>
      <Box onClick={handleClose}>
        <CloseSvg />
      </Box>
    </StyledDialogTitle>


    <DialogContent>
      {isLoading ? <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={0}
          sx={{
            width: '100%'
          }}
        >
          <CircularProgress size={30} />
        </Stack> :
        <EditUserForm user={data!} handleClose={handleClose}/>}
    </DialogContent>
  </Dialog>

}

export default EditUser