import React, { useState } from "react";
import {
  Paper,
  Table,
  TableContainer, TablePagination,
} from "@mui/material";
import MetalProductsTableHead from "./components/MetalProductsTableHead";
import MetalProductTableBody from "./components/MetalProductTableBody";
import {useTranslation} from "react-i18next";
import MetalProductsTableHeader from "./components/MetalProductsTableHeader";

import TableLoading from "../../TableLoading";
import NoTableData from "../../NoTableData";
import {useMetalProductStore} from "../../../../store/metalProductsStore";

const MetalProductsTable = () => {
  const { t } = useTranslation()

  const setFilter = useMetalProductStore(state => state.setFilter);
  const setPage = useMetalProductStore(state => state.setPage);
  const meta = useMetalProductStore(state => state.tableData.meta);
  const page = useMetalProductStore(state => state.page);
  const filter = useMetalProductStore(state => state.filter);
  const isTableLoading = useMetalProductStore(state => state.isTableLoading);

  const metalProducts = useMetalProductStore(state => state.tableData)

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({ ...filter, perpage: +event.target.value });
  };


  return (
    <Paper sx={{
      width: '100%',
      overflow: 'hidden',
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      boxShadow: 'none',
      maxHeight: 'calc(100vh - 68px)',
    }}>
     <MetalProductsTableHeader/>
      <TableContainer sx={{
        borderRadius: ' 0 0 12px 12px',
        flexGrow: 1,
        position: 'relative',
        height: '100%',
      }}>
        {isTableLoading ? <TableLoading /> : metalProducts.data?.length ?
        <Table
          stickyHeader
          aria-label="sticky table"
          sx={{
            borderSpacing: '0 8px'
          }}
        >
          <MetalProductsTableHead />
          <MetalProductTableBody data={metalProducts.data} />
        </Table> : <NoTableData />}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 15, 25, 100]}
        component="div"
        count={meta.total}
        rowsPerPage={filter.perpage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          overflow: 'hidden'
        }}
      />

    </Paper>
  )
}

export default MetalProductsTable