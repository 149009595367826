import {useTranslation} from "react-i18next";
import useToast from "../../../../../hooks/useToast";
import React, {useState} from "react";
import useAsyncWrapper from "../../../../../hooks/useAsyncWrapper";
import {Controller, useForm} from "react-hook-form";
import {IHandbook, } from "../../../../../interfaces/general";
import {yupResolver} from "@hookform/resolvers/yup";
import {Box, Button,  Grid, ListItemText, MenuItem, Select, Switch, TextField} from "@mui/material";
import {StyledFormControlLabel} from "../../Settings/SettingsForm/style";
import ButtonLoadingWrapper from "../../../../Wrappers/ButtonLoading";
import {useUsersTableStore} from "../../../../../store/usersTableStore";
import createFilteredPayload from "../../../../../utils/removeDuplicate";

import {handbookLangs, handbooksType} from "../../../../../constants";
import FileUpload from "../../../HandBooks/components/FileUpload";
import {getHandbooksEditValidationSchema} from "../../../../../validations/handbooks/edit";
import {useHandbooksStore} from "../../../../../store/handbooksStore";

interface IEditHandbookForm {
  name: string;
  description: string;
  locale: string,
  type: string
  hidden?: boolean
}

interface IProps {
  handbook: IHandbook;
  handleClose: () => void;

}

const EditHandbookForm = ({ handbook, handleClose } : IProps) => {
  const { t } = useTranslation()
  const { errorToast, successToast } = useToast();

  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState<any[]>([]);

  const editHandbook = useHandbooksStore(state => state.editHandbook);

  const editHandbookHandler = useAsyncWrapper(editHandbook)

  const initialFormState = {
    name: handbook?.name ?? '',
    description: handbook?.description ??  '',
    locale: handbook?.locale ??  'all',
    type: handbook?.type ??  1,
    hidden: handbook?.hidden ?? false
  }
  const schema = getHandbooksEditValidationSchema(t);
  const {handleSubmit, control, watch} = useForm<IEditHandbookForm>({
    defaultValues: initialFormState,
    resolver: yupResolver(schema),
  });

  const handleSetFiles = (files: any) => {
    if(files.length > 1) {
      errorToast(t('handBook.error.fileRequired'));
      return
    }
    setFiles(files)
  }

  const handleEditSubmit = async (body: IEditHandbookForm) => {
    const file = files[0];
    const formData = new FormData();

    const payload = createFilteredPayload(body, initialFormState)
    Object.entries(payload).forEach(([key, value]) => {
      //@ts-ignore
      formData.append(key, value);
    });
    if(file)
      formData.append('file', file)
    //ts-ignore
    if(body.hidden != initialFormState.hidden)
      formData.append('hidden', body.hidden ? '1' : '0')
    formData.append('_method', 'PATCH')

    setIsLoading(true);
    const { error , status} = await editHandbookHandler( [handbook?.id, formData] );
    setIsLoading(false);

    if (status === 400) {
      const errors = error?.response?.data?.message ?? [];
      if(error?.response?.data?.message.length > 0) {
        errors.forEach((err: any) => errorToast(t('error.error'), t('editHandbook.error.400.' + err?.field)))
      }

      return
    }
    if(!error) {
      successToast(t('editHandbook.success'))
      handleClose()
      return;
    }
    errorToast(t('editHandbook.error.default'))
  }


  return (
    <Box
      component={'form'}
      onSubmit={handleSubmit(handleEditSubmit)}
      sx={{
        '@media (min-width: 600px)': {
          minWidth: '350px',
        }
      }}
    >
      <Grid
        container
        spacing={1}
        mb={2}
      >
        <Grid item xs={12} md={12}>
          <Controller
            name={"name"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                label={t("handBook.name")}
                control={
                  <TextField
                    fullWidth
                    onChange={onChange}
                    value={value}
                    error={error?.ref?.name === name}
                    helperText={error ? error.message : ' '}
                  />
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            name={"description"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start',
                  '& .MuiInputBase-root': {
                    height: '120px',
                    alignItems: 'flex-start',
                    '& textarea': {
                      overflow: 'auto!important',
                      height: '100%!important'
                    }
                  }
                }}
                label={t("handBook.description")}
                control={
                  <TextField
                    fullWidth
                    onChange={onChange}
                    value={value}
                    multiline
                    error={error?.ref?.name === name}
                    helperText={error ? error.message : ' '}
                  />
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name={"locale"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                label={t("handBook.locale")}
                control={(
                  <Select
                    fullWidth
                    sx={{
                      padding: '0px',
                      maxHeight: '40px',
                      marginBottom: '10px',
                      '& .MuiSelect-select': {
                        padding: '10px 0 10px 10px',
                      }
                    }}
                    value={value}
                    onChange={onChange}
                    error={error?.ref?.name === name}
                  >
                    {handbookLangs.map((lang) => {
                      return (
                        <MenuItem key={lang} value={lang}>
                          {lang}
                        </MenuItem>
                      );
                    })}
                  </Select>)
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name={"type"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                label={t("handBook.type.title")}
                control={(
                  <Select
                    fullWidth
                    sx={{
                      padding: '0px',
                      maxHeight: '40px',
                      marginBottom: '10px',
                      '& .MuiSelect-select': {
                        padding: '10px 0 10px 10px',
                      }
                    }}
                    value={value}
                    onChange={onChange}
                    error={error?.ref?.name === name}
                  >
                    {handbooksType.map((type) => {
                      return (
                        <MenuItem key={type.value + type.label} value={type.value}>
                          {t('handBook.type.' + type.label)}
                        </MenuItem>
                      );
                    })}
                  </Select>)
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            name={"hidden"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="start"
                sx={{
                  justifyContent: 'flex-end',
                }}
                label={t("handBook.hide")}
                control={
                  <Switch
                    name={name}
                    checked={value}
                    onChange={onChange}
                  />
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <FileUpload handleChange={handleSetFiles} file={files}/>
        </Grid>
      </Grid>
      <ButtonLoadingWrapper isLoading={isLoading}>
        <Button
          variant="contained"
          type="submit"
          fullWidth
          size="large"
          sx={{
            background: '#373737',
            '&:hover': {
              background: '#666',
            }
          }}
        >

          {t("handBook.submitEdit")}
        </Button>
      </ButtonLoadingWrapper>
    </Box>
  )
}
export default EditHandbookForm