import {useTranslation} from "react-i18next";
import useToast from "../../../../../hooks/useToast";
import React, {useState} from "react";
import useAsyncWrapper from "../../../../../hooks/useAsyncWrapper";
import {Controller, useForm} from "react-hook-form";
import {
  ICoating,
  IEditCoating,
} from "../../../../../interfaces/general";
import {yupResolver} from "@hookform/resolvers/yup";
import {Box, Button,  Grid,  TextField} from "@mui/material";
import {StyledFormControlLabel} from "../../Settings/SettingsForm/style";
import ButtonLoadingWrapper from "../../../../Wrappers/ButtonLoading";
import createFilteredPayload from "../../../../../utils/removeDuplicate";
import {useCoatingsStore} from "../../../../../store/coatingsStore";
import {getCoatingEditValidationSchema} from "../../../../../validations/coatings/editCoating";


interface IProps {
  item: ICoating;
  handleClose: () => void;

}

const EditCoatingForm = ({ item, handleClose } : IProps) => {
  const { t } = useTranslation()
  const { errorToast, successToast } = useToast();

  const [isLoading, setIsLoading] = useState(false);

  const editCoating = useCoatingsStore(state => state.editCoating);
  const coatings = useCoatingsStore(state => state.tableData.data)

  const editCoatingHandler = useAsyncWrapper(editCoating)

  const initialFormState = {
    name_uk: item?.name_uk ?? '',
    name_en: item?.name_en ?? '',
    code: item?.code ?? '',
  }
  const schema = getCoatingEditValidationSchema(t);
  const {handleSubmit, control, watch} = useForm<IEditCoating>({
    defaultValues: initialFormState,
    resolver: yupResolver(schema),
  });


  const handleEditSubmit = async (body: IEditCoating) => {
    const payload = createFilteredPayload(body, initialFormState)

    setIsLoading(true);
    const { error , status} = await editCoatingHandler( [item?.id, payload] );
    setIsLoading(false);

    if (status === 400) {
      const errors = error?.response?.data?.message ?? [];
      if(error?.response?.data?.message.length > 0) {
        errors.forEach((err: any) => errorToast(t('error.error'), t('editCoating.error.400.' + err?.field)))
      }

      return
    }
    if(!error) {
      successToast(t('editCoating.success'))
      handleClose()
      return;
    }
    errorToast(t('editCoating.error.default'))
  }


  return (
    <Box
      component={'form'}
      onSubmit={handleSubmit(handleEditSubmit)}
      sx={{
        '@media (min-width: 600px)': {
          minWidth: '350px',
        }
      }}
    >
      <Grid
        container
        spacing={1}
        mb={2}
      >
        <Grid item xs={12} md={12}>
          <Controller
            name={"code"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                label={t("coatings.code")}
                control={
                  <TextField
                    fullWidth
                    onChange={onChange}
                    value={value}
                    error={error?.ref?.name === name}
                    helperText={error ? error.message : ' '}
                  />
                }
              />
            )}
          />
        </Grid><Grid item xs={12} md={12}>
        <Controller
          name={"name_uk"}
          control={control}
          render={({
                     fieldState: {error},
                     field: {onChange, value, name},
                   }) => (
            <StyledFormControlLabel
              labelPlacement="top"
              sx={{
                alignItems: 'flex-start'
              }}
              label={t("coatings.name_uk")}
              control={
                <TextField
                  fullWidth
                  onChange={onChange}
                  value={value}
                  error={error?.ref?.name === name}
                  helperText={error ? error.message : ' '}
                />
              }
            />
          )}
        />
      </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            name={"name_en"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                label={t("coatings.name_en")}
                control={
                  <TextField
                    fullWidth
                    onChange={onChange}
                    value={value}
                    error={error?.ref?.name === name}
                    helperText={error ? error.message : ' '}
                  />
                }
              />
            )}
          />
        </Grid>
      </Grid>
      <ButtonLoadingWrapper isLoading={isLoading}>
        <Button
          variant="contained"
          type="submit"
          fullWidth
          size="large"
          sx={{
            background: '#373737',
            '&:hover': {
              background: '#666',
            }
          }}
        >

          {t("handBook.submitEdit")}
        </Button>
      </ButtonLoadingWrapper>
    </Box>
  )
}
export default EditCoatingForm