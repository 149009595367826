import {api} from "../index";
import {
  IFilter,
} from "../../interfaces/general";

interface ICreatePositionApi {
}

export const positions_api = {
  getPositionsByProject: async (projectId: number) => {
    return await api.get(`projects/positions/${projectId}`, {
      params: {
        perpage: 50,
      }
    })
  },
  getPosition: async (id: number) => {
    return await api.get(`projects/positions/${id}`)
  },
  editPosition: async (id: number, payload: any) => {
    return await api.patch(`projects/positions/${id}`, payload)
  },
  createPositions: async (data: ICreatePositionApi) => {
    return await api.post(`projects/positions`, data)
  },
  deletePosition: async (id: number) => {
    return await api.delete(`projects/positions/${id}`)
  }

};

