import {api} from "../index";
import {
  IFilter,
} from "../../interfaces/general";

interface ICreateProjectApi {
}

export const projects_api = {
  projects: async (params: IFilter) => {
    return await api.get(`projects`,{
          params: {
            ...params,
            filters: JSON.stringify(params.filters)
          }
      })
  },
  getProject: async (id: number) => {
    return await api.get(`projects/${id}`)
  },
  editProject: async (id: number, payload: any) => {
    return await api.patch(`projects/${id}`, payload)
  },
  createProject: async (data: ICreateProjectApi) => {
    return await api.post(`projects`, data)
  },
  deleteProject: async (id: number) => {
    return await api.delete(`projects/${id}`)
  },
  traysParameters: async (data: any) => {
    return await api.post(`projects/trays/additional-parameter`, data)
  },
  getLidsByTray: async (data: any) => {
    return await api.post(`projects/trays/model-parameter-lid`, data)
  },
  getPartitionsByTray: async (data: any) => {
    return await api.post(`projects/trays/model-parameter-partition`, data)
  },
  getResult: async (data: any) => {
    return await api.post(`projects/get-result`, data, {
      responseType: 'blob'
    })
  },

};

