import { create } from "zustand";
import {
  ICreateLidForm,
  IEditLid,
  IFilter,
  IResponseTable,
} from "../interfaces/general";

import {lids_api} from "../api/apis/lids.api";

interface ILidsStoreState {
  isLoading: boolean,
  isTableLoading: boolean,
  tableData: IResponseTable
  filter: IFilter,
  page: number,
}
interface ILidsStore  extends ILidsStoreState{
  setPage: (page: number) => void
  setFilter: (filter: IFilter) => void
  getLids: (params: IFilter, page: number) => Promise<any>,
  createLid: (payload: ICreateLidForm) => Promise<any>,
  deleteLid: (id: number) => Promise<any>,
  resetFilter: () => void,
  getLid: (id: number) => Promise<any>,
  editLid: (id: number, payload: IEditLid) => Promise<any>,
}

const initialState = {
  isLoading: false,
  tableData: {
    data: [],
    meta: {
      total: 0,
      current_page: 0,
      last_page: 0,
      perpage: 0,
    }
  },
  filter: {
    filters: [],
    perpage: 15,
    page: -1,
  },
  page: 0,
  isTableLoading: true,
}

export const useLidsStore = create<ILidsStore>((set) => ({
  ...initialState,
  setPage: (page) => {
    set(state => ({...state, page}))
  },
  setFilter: (filter) => {
    set(state => ({...state, filter, page: 0 }))
  },
  resetFilter: () => {
    set(state => ({...state, filter: initialState.filter, page: 0 }))
  },
  getLids: async (params, page) => {
    set(state => ({...state, isTableLoading: true}))
    const response = await lids_api.lids({...params, page: page + 1})
      .finally(() => set(state => ({...state, isTableLoading: false})));

    const { status, data } = response;

    if (!status || !data) {
      throw new Error("No status code or data returned from server.");
    }

    if (status !== 200) {
      throw new Error(data);
    }

    set((state) => ({
      ...state,
      tableData: data
    }));
  },
  createLid: async (payload: ICreateLidForm) => {
    const response = await lids_api.createLid({
      ...payload
    });

    const { status, data } = response;

    if (!status || !data) {
      throw new Error("No status code or data returned from server.");
    }

    if (status !== 201) {
      throw new Error(data);
    }

    set((state) => ({
      ...state,
      tableData: {
        ...state.tableData,
        data: [data.data, ...state.tableData.data]
      }
    }));

    return data;

  },
  deleteLid: async (id) => {
    const response = await lids_api.deleteLid(id);

    const { status, data } = response;

    if (status !== 204) {
      throw new Error(data);
    }
    set((state) => ({
      ...state,
      tableData: {
        ...state.tableData,
        data: state.tableData.data.filter((item) => item.id !== id),
      },
    }));
  },
  getLid: async (id) => {
    const response = await lids_api.getLid(id);

    const { status, data } = response;

    if (status !== 200) {
      throw new Error(data);
    }
    set((state) => ({
      ...state,
      // tableData: {
      //   ...state.tableData,
      //   data: state.tableData.data.filter((item) => item.id !== id),
      // },
    }));
    return data;
  },
  editLid: async (id, payload) => {
    const response = await lids_api.editLid(id, payload);

    const { status, data } = response;


    set((state) => ({
      ...state,
      tableData: {
        ...state.tableData,
        data: state.tableData.data.map((item) => item.id === id ? data.data : item),
      },
    }));
    return data;
  }
}));

export const useLidsTableStoreOut = useLidsStore.getState();