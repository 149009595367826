import HandBooks from "../../components/UI/HandBooks";
import {useEffect} from "react";
import {useHandbooksStore} from "../../store/handbooksStore";
import useAsyncWrapper from "../../hooks/useAsyncWrapper";

let prevFilter = {}
const HandBooksPage = () => {

  const getHandBooks = useHandbooksStore(state => state.getHandbooks);
  const filter = useHandbooksStore(state => state.filter);
  const page = useHandbooksStore(state => state.page);
  const getHandBooksHandler = useAsyncWrapper(getHandBooks)

  useEffect(() => {
    (async () => {
      if(JSON.stringify(prevFilter) !== JSON.stringify(filter) || filter.page !== page) {
        prevFilter = filter
        await getHandBooksHandler([filter, page])
      }

    })();
  },[filter, page])


  return (<>
    <HandBooks />
  </>)
}
export default HandBooksPage