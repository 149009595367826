import {useTranslation} from "react-i18next";
import useToast from "../../../../../hooks/useToast";
import React, {useState} from "react";
import useAsyncWrapper from "../../../../../hooks/useAsyncWrapper";
import {Controller, useForm} from "react-hook-form";
import {
  ICreateMetalProductForm,
  IEditLid,
  IEditMetalProduct,
  IHandbook, ILid,
  IMetalProduct,
} from "../../../../../interfaces/general";
import {yupResolver} from "@hookform/resolvers/yup";
import {Box, Button,  Grid, ListItemText, MenuItem, Select, Switch, TextField} from "@mui/material";
import {StyledFormControlLabel} from "../../Settings/SettingsForm/style";
import ButtonLoadingWrapper from "../../../../Wrappers/ButtonLoading";
import createFilteredPayload from "../../../../../utils/removeDuplicate";
import {useLidsStore} from "../../../../../store/lidsStore";
import {getLidEditValidationSchema} from "../../../../../validations/lids/editLid";
import CustomNumberField from "../../../CustomNumberField";
import {useHelperStore} from "../../../../../store/helperStore";
import {lidTypes, measurements} from "../../../../../constants";
import CustomImageField from "../../../CustomImageField";
import {useCoatingsStore} from "../../../../../store/coatingsStore";
import {useImagesStore} from "../../../../../store/imagesStore";


interface IProps {
  item: ILid;
  handleClose: () => void;

}

const EditLidForm = ({ item, handleClose } : IProps) => {
  const { t } = useTranslation()
  const { errorToast, successToast } = useToast();

  const [isLoading, setIsLoading] = useState(false);

  const editLid = useLidsStore(state => state.editLid);
  const coatings = useCoatingsStore(state => state.tableData.data)

  const editLidHandler = useAsyncWrapper(editLid)
  const choosedImages = useImagesStore(state => state.modal.choosedImages)

  const initialFormState = {
    name_uk: item?.name_uk ?? '',
    name_en: item?.name_en ?? '',
    article_prefix_2: item?.article_prefix_2 ?? '',
    article_prefix_1: item?.article_prefix_1 ?? '',
    type: item?.type ?? '',
  }
  const schema = getLidEditValidationSchema(t);
  const {handleSubmit, control, watch} = useForm<IEditLid>({
    defaultValues: initialFormState,
    resolver: yupResolver(schema),
  });


  const handleEditSubmit = async (body: IEditLid) => {
    const payload = createFilteredPayload(body, initialFormState)

    setIsLoading(true);
    const { error , status} = await editLidHandler( [item?.id, {
      ...payload,
      image_ids: choosedImages,
    }] );
    setIsLoading(false);

    if (status === 400) {
      const errors = error?.response?.data?.message ?? [];
      if(error?.response?.data?.message.length > 0) {
        errors.forEach((err: any) => errorToast(t('error.error'), t('editLid.error.400.' + err?.field)))
      }

      return
    }
    if(!error) {
      successToast(t('editLid.success'))
      handleClose()
      return;
    }
    errorToast(t('editLid.error.default'))
  }


  return (
    <Box
      component={'form'}
      onSubmit={handleSubmit(handleEditSubmit)}
      sx={{
        '@media (min-width: 600px)': {
          minWidth: '350px',
        }
      }}
    >
      <Grid
        container
        spacing={1}
        mb={2}
      >
        <Grid item xs={12} md={6}>
          <Controller
            name={"name_uk"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                label={t("trays.name_uk")}
                control={
                  <TextField
                    fullWidth
                    onChange={onChange}
                    value={value}
                    error={error?.ref?.name === name}
                    helperText={error ? error.message : ' '}
                  />
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name={"name_en"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                label={t("trays.name_en")}
                control={
                  <TextField
                    fullWidth
                    onChange={onChange}
                    value={value}
                    error={error?.ref?.name === name}
                    helperText={error ? error.message : ' '}
                  />
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name={"article_prefix_1"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                label={t("trays.article_prefix_1")}
                control={
                  <TextField
                    fullWidth
                    onChange={onChange}
                    value={value}
                    error={error?.ref?.name === name}
                    helperText={error ? error.message : ' '}
                  />
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name={"article_prefix_2"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                label={t("trays.article_prefix_2")}
                control={
                  <TextField
                    fullWidth
                    onChange={onChange}
                    value={value}
                    error={error?.ref?.name === name}
                    helperText={error ? error.message : ' '}
                  />
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            name={"type"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                label={t("lids.type")}
                control={(
                  <Select
                    fullWidth
                    sx={{
                      padding: '0px',
                      '& .MuiSelect-select': {
                        padding: '10px 0 10px 10px',
                      }
                    }}
                    value={value}
                    onChange={onChange}
                    defaultValue={lidTypes[0]}
                    error={error?.ref?.name === name}
                  >
                    {lidTypes.map((lidType) => {
                      return (
                        <MenuItem key={lidType} value={lidType}>
                          {t('lids.'+lidType)}
                        </MenuItem>
                      );
                    })}
                  </Select>)
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <StyledFormControlLabel
            labelPlacement="top"
            sx={{
              alignItems: 'flex-start',
            }}
            label={t("images.addImages")}
            control={
              <CustomImageField />
            }
          />
        </Grid>
      </Grid>
      <ButtonLoadingWrapper isLoading={isLoading}>
        <Button
          variant="contained"
          type="submit"
          fullWidth
          size="large"
          sx={{
            background: '#373737',
            '&:hover': {
              background: '#666',
            }
          }}
        >

          {t("handBook.submitEdit")}
        </Button>
      </ButtonLoadingWrapper>
    </Box>
  )
}
export default EditLidForm