import {TFunction} from "i18next";
import * as Yup from "yup";
import isEmail from "../../utils/isEmail";
import {phoneRegExp} from "../general";

export const getEditUserValidationSchema = (t: TFunction<"translation", undefined>) =>
  Yup.object().shape({
    first_name: Yup.string(),//.required(t("signUp.error.first_name"))
    last_name: Yup.string(),//.required(t("signUp.error.last_name"))
    phone_number: Yup.string().matches(phoneRegExp, t("signUp.error.phone_number")).required(t("signUp.error.phone_number")),
    company: Yup.string(),//.required(t("signUp.error.company"))
    patronymic: Yup.string(),//.required(t("signUp.error.patronymic"))
    email: Yup.string()
      .required(t("signUp.error.email"))
      .test("pattern", t("signUp.error.emailPattern"), (email) => isEmail(email)),
  });