import {Box, Tooltip, Typography} from "@mui/material";
import {IProject} from "../../../../../../interfaces/general";
import {useProjectsStore} from "../../../../../../store/projectsStore";
import {useNavigate} from "react-router-dom";
import {RouteUrls} from "../../../../../../interfaces/enum";
import {useHelperStore} from "../../../../../../store/helperStore";

const bgs = ['purple', 'orange', 'green', 'darkblue', 'black', 'pink', 'blue', 'darkred', 'darkgray', 'cadetblue', 'chocolate']

interface IProps {
  index: number,
  item: IProject
}
const ListItem = ({ index, item }: IProps) => {
  const nav = useNavigate()
  const isCreateLoading = useProjectsStore(state => state.isCreateLoading);


  const handleOpenProject = () => {
      nav(RouteUrls.PROJECTS + '/' + item?.id);
  }



  return (<Box
      onClick={handleOpenProject}
      sx={{
        maxWidth: '180px',
        opacity: isCreateLoading  ? 0.5 : 1,
        "@media (max-width: 600px)": {
          maxWidth: '130px',
        }
      }}>
      <Tooltip title={item?.user?.email}>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '180px',
            width: '180px',
            borderRadius: '16px',
            boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
            background: bgs[index % bgs.length],
            cursor: 'pointer',
            '&:hover': {
              opacity: 0.8
            },
            "& .MuiTypography-root": {
              fontSize: '120px',
            },
            "@media (max-width: 600px)": {
              height: '130px',
              width: '130px',
              "& .MuiTypography-root": {
                fontSize: '80px',
              },
            }
          }}>
            <Typography fontSize={{
              color: '#fff',
              fontWeight: 700,
            }}>
              {item?.name[0]?.toUpperCase()}
            </Typography>
        </Box>
      </Tooltip>
      <Tooltip title={item.name} placement="bottom" arrow>
        <Typography  fontSize={{
           xs: 14,
           sm: 18,
         }}
         textAlign={'center'} color={'#666'}
         sx={{
           display: '-webkit-box',
           WebkitLineClamp: '2',
           WebkitBoxOrient: 'vertical',
           overflow: 'hidden',
         }}

        >{item.name}</Typography>
      </Tooltip>
  </Box>)
}

export default ListItem