import {Controller, useForm} from "react-hook-form";
import {
  ICreateLidForm,
  ICreateMetalProductForm,
  ICreateUserForm,
  IEditUserForm
} from "../../../../../interfaces/general";
import {yupResolver} from "@hookform/resolvers/yup";
import {StyledFormControlLabel} from "./style";
import {Box, Button, Checkbox, Grid, ListItemText, MenuItem, OutlinedInput, Select, TextField} from "@mui/material";
import ButtonLoadingWrapper from "../../../../Wrappers/ButtonLoading";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import useAsyncWrapper from "../../../../../hooks/useAsyncWrapper";
import useToast from "hooks/useToast";

import {useMetalProductStore} from "../../../../../store/metalProductsStore";
import {getMetalProductCreateValidationSchema} from "../../../../../validations/metalProducts/createMetalProduct";
import {useCoatingsStore} from "../../../../../store/coatingsStore";
import CustomNumberField from "../../../CustomNumberField";
import {useHelperStore} from "../../../../../store/helperStore";
import {measurements} from "../../../../../constants";
import CustomImageField from "../../../CustomImageField";
import {useImagesStore} from "../../../../../store/imagesStore";


interface IProps {
  handleClose: () => void;
}
const initialFormState = {
  name_uk: '',
  name_en: '',
  article_prefix_2: '',
  article_prefix_1: '',
  // article: '',
  // unit_of_measurement: measurements[0],
  // coating_ids: [],
  // length: [],
  // width: [],
  // height: [],
  // weight: [],
}

const CreateMetalProductForm = ({ handleClose }: IProps) => {
  const { t } = useTranslation()
  const { errorToast, successToast } = useToast();

  const [isLoading, setIsLoading] = useState(false);

  const createMetalProduct = useMetalProductStore(state => state.createMetalProduct)
  const coatings = useCoatingsStore(state => state.tableData.data)

  const createMetalProductHandler = useAsyncWrapper(createMetalProduct)
  const choosedImages = useImagesStore(state => state.modal.choosedImages)

  const schema = getMetalProductCreateValidationSchema(t);

  const {handleSubmit, control, watch } = useForm<ICreateMetalProductForm>({
    defaultValues: initialFormState,
    resolver: yupResolver(schema),
  });


  const handleCreateSubmit = async (body: ICreateMetalProductForm) => {
    const payload = {
      ...body,
      image_ids: choosedImages,
    }
    setIsLoading(true)
    const { error, status } = await createMetalProductHandler( [payload] )
    setIsLoading(false)

    if(!error) {
      successToast(t('createMetalProduct.success'))
      handleClose()
      return;
    }
    if (status === 400) {
      const errors = error?.response?.data?.message ?? [];
      if(error?.response?.data?.message.length > 0) {
        errors.forEach((err: any) => errorToast(t('error.error'), t('createMetalProduct.error.400.' + err?.field)))
      }

      return
    }

    errorToast(t('createMetalProduct.error.default'))
  }


  return (
    <Box
      component={'form'}
      onSubmit={handleSubmit(handleCreateSubmit)}
      sx={{
        '@media (min-width: 600px)': {
          minWidth: '350px',
        }
      }}
    >
      <Grid
        container
        spacing={1}
        mb={2}
      >
        <Grid item xs={12} md={6}>
          <Controller
            name={"name_uk"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                label={t("trays.name_uk")}
                control={
                  <TextField
                    fullWidth
                    onChange={onChange}
                    value={value}
                    error={error?.ref?.name === name}
                    helperText={error ? error.message : ' '}
                  />
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name={"name_en"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                label={t("trays.name_en")}
                control={
                  <TextField
                    fullWidth
                    onChange={onChange}
                    value={value}
                    error={error?.ref?.name === name}
                    helperText={error ? error.message : ' '}
                  />
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name={"article_prefix_1"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                label={t("trays.article_prefix_1")}
                control={
                  <TextField
                    fullWidth
                    onChange={onChange}
                    value={value}
                    error={error?.ref?.name === name}
                    helperText={error ? error.message : ' '}
                  />
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name={"article_prefix_2"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                label={t("trays.article_prefix_2")}
                control={
                  <TextField
                    fullWidth
                    onChange={onChange}
                    value={value}
                    error={error?.ref?.name === name}
                    helperText={error ? error.message : ' '}
                  />
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <StyledFormControlLabel
            labelPlacement="top"
            sx={{
              alignItems: 'flex-start',
            }}
            label={t("images.addImages")}
            control={
              <CustomImageField />
            }
          />
        </Grid>
      </Grid>
      <ButtonLoadingWrapper isLoading={isLoading}>
        <Button
          variant="contained"
          type="submit"
          fullWidth
          size="large"
          sx={{
            background: '#373737',
            '&:hover': {
              background: '#666',
            }
          }}
        >

          {t("createMetalProduct.submit")}
        </Button>
      </ButtonLoadingWrapper>
    </Box>
  )
}
export default CreateMetalProductForm