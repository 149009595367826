import {NavLink} from "react-router-dom";
import {styled} from "@mui/system";
import {Box} from "@mui/material";


export const StyledNavLink = styled(NavLink)(() => ({
  boxSizing: 'border-box',
  textDecoration: 'none',
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  color: 'lightgray',
  padding: '5px 10px',
  borderBottom: '2px solid transparent',
  transition: 'all 0.1s ease-in-out',
  fontWeight: 800,
  '&:hover, &.active': {
    color: '#fff',
    transition: 'all 0.1s ease-in-out',
    '& svg': {
      color: '#fff'
    }
  },
  '@media (max-width: 600px)': {
    padding: 0
  }
}))
export const StyledMenuWrapper = styled(Box)<any>(({ isOpen }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '30px',
  borderRadius: '12px',
  padding: isOpen ? '20px 25px' : '20px 5px',
  minWidth: isOpen ? '250px' : '50px',
  maxWidth: isOpen ? 'auto' : '50px',
  backgroundColor: '#242531',
  transition: 'all 0.15s ease-in-out',
  position: 'relative'
}))
