import {TableCell} from "@mui/material";
import {styled} from "@mui/system";

export const StyledTableCell = styled(TableCell)(() => ({
  color: '#fff',
  fontWeight: 600,
  padding: '10px 12px',
  whiteSpace: 'nowrap',
  backgroundColor: '#242531',
  '&:first-of-type': {
    borderRadius: '5px 0 0 5px'
  },
  '&:last-of-type': {
    borderRadius: '0 5px 5px 0'
  },

}))