
import React from 'react';


import {CircularProgress, Backdrop} from '@mui/material';

const Preloader = () => {

  return (
    <Backdrop
      sx={{
        color: '#666',
        zIndex: 999999,
        backgroundColor: 'rgba(19, 23, 34, CategoryMobile)',
      }}
      open={true}
    >
      <CircularProgress color='inherit' />
    </Backdrop>
  );
};

export default Preloader;