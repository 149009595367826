import { create } from "zustand";
import {
  IFilter, IProject, IProjectCreate,
  IResponseTable,
  IRole
} from "../interfaces/general";
import {projects_api} from "../api/apis/projects.api";


interface IProjectState {
  isLoading: boolean,
  isTableLoading: boolean,
  tableData: IResponseTable
  filter: IFilter,
  page: number,
  currentProject: IProject | null,
  isCreateLoading: boolean,
}

interface IProjectStore  extends IProjectState{
  setPage: (page: number) => void
  setFilter: (filter: IFilter) => void
  getProjects: (params: IFilter, page: number) => Promise<any>,
  createProject: (payload: IProjectCreate) => Promise<any>,
  deleteProject: (id: number) => Promise<any>,
  resetFilter: () => void,
  getProject: (id: number) => Promise<any>,
  setCurrentProject: (currentProject: IProject) => void,
  editProject: (id: number, payload: any) => Promise<any>,
  resetStore: () => void
}

const initialState = {
  isLoading: false,
  tableData: {
    data: [],
    meta: {
      total: 0,
      current_page: 0,
      last_page: 0,
      perpage: 0,
    }
  },
  currentProject: null,
  filter: {
    filters: [],
    perpage: 15,
    page: -1,
    search: "",
  },
  page: 0,
  isTableLoading: true,
  isCreateLoading: false,
}

export const useProjectsStore = create<IProjectStore>((set) => ({
  ...initialState,
  resetStore: () => set(initialState),
  setPage: (page) => {
    set(state => ({...state, page}))
  },
  setFilter: (filter) => {
    set(state => ({...state, filter, page: 0 }))
  },
  resetFilter: () => {
    set(state => ({...state, filter: initialState.filter, page: 0 }))
  },
  setCurrentProject: (currentProject) => {
    set(state => ({...state, currentProject }))
  },
  getProjects: async (params, page) => {
    set(state => ({...state, isTableLoading: true, }))
    const response = await projects_api.projects({...params, page: page + 1})
      .finally(() => set(state => ({...state, isTableLoading: false})));

    const { status, data } = response;

    if (!status || !data) {
      throw new Error("No status code or data returned from server.");
    }

    if (status !== 200) {
      throw new Error(data);
    }

    set((state) => ({
      ...state,
      tableData: data
    }));
  },
  createProject: async (payload) => {
    set(state => ({...state, isCreateLoading: true}))
    const response = await projects_api.createProject({
      ...payload,
    }).finally(() => set(state => ({...state, isCreateLoading: false})));

    const { status, data } = response;

    if (!status || !data) {
      throw new Error("No status code or data returned from server.");
    }

    if (status !== 201) {
      throw new Error(data);
    }

    set((state) => ({
      ...state,
      currentProject: data.data
    }));

    return data;

  },
  deleteProject: async (id) => {
    const response = await projects_api.deleteProject(id);

    const { status, data } = response;

    if (status !== 204) {
      throw new Error(data);
    }
    set((state) => ({
      ...state,
      tableData: {
        ...state.tableData,
        data: state.tableData.data.filter((item) => item.id !== id),
      },
    }));
  },
  getProject: async (id) => {
    const response = await projects_api.getProject(id);

    const { status, data } = response;

    if (status !== 200) {
      throw new Error(data);
    }
    set((state) => ({
      ...state,
      currentProject: data.data
    }));
    return data;
  },
  editProject: async (id, payload) => {
    const response = await projects_api.editProject(id, payload);

    const { status, data } = response;


    set((state) => ({
      ...state,
      currentProject: data.data
    }));
    return data;
  }
}));

export const useProjectsStoreOut = useProjectsStore.getState();