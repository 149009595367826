import { styled } from "@mui/system";
import ButtonMui from "@mui/material/Button";


const Button = styled(ButtonMui)({

});


export { Button };
