import React, {useEffect} from "react";

import MyProjects from "../../components/UI/MyProjects";
import useAsyncWrapper from "../../hooks/useAsyncWrapper";
import {useProjectsStore} from "../../store/projectsStore";
import {useLocation} from "react-router-dom";
import {RouteUrls} from "../../interfaces/enum";

let prevFilter: any = {}
let prevPage: any = -1
const Projects = () => {


  const getProjects = useProjectsStore(state => state.getProjects);
  const filter = useProjectsStore(state => state.filter);
  const page = useProjectsStore(state => state.page);
  const getProjectsHandler = useAsyncWrapper(getProjects)


  useEffect(() => {
    (async () => {
      if(JSON.stringify(prevFilter) !== JSON.stringify(filter) || prevPage !== page) {
        prevFilter = filter
        prevPage = page
        await getProjectsHandler([filter, page])
      }
    })();

  },[filter, page])

  useEffect(() => () => {
      prevFilter = {}
      prevPage = -1
  },[])

  return (
      <>
        <MyProjects />
      </>

  );
};

export default Projects;
