import CreateHandbook from "../Modals/CreateHandbook";
import React, {useState} from "react";
import {Box, Button, TablePagination} from "@mui/material";
import usePermission from "../../../hooks/usePermission";
import {PermissionsUser} from "../../../interfaces/enum";
import {useTranslation} from "react-i18next";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import HandbookList from "./components/HandbookList";
import Loader from "../Loader";
import {useHandbooksStore} from "../../../store/handbooksStore";
import Filter from "./components/Filter";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

const HandBooks = () => {
  const { hasPermission, isUser } = usePermission()
  const { t } = useTranslation()
  const [createUserModalOpen, setCreateUserModalOpen] = useState(false);

  const page = useHandbooksStore(state => state.page);
  const setFilter = useHandbooksStore(state => state.setFilter);
  const meta = useHandbooksStore(state => state.tableData.meta);
  const setPage = useHandbooksStore(state => state.setPage);
  const resetFilter = useHandbooksStore(state => state.resetFilter);
  const filter = useHandbooksStore(state => state.filter);
  const isTableLoading = useHandbooksStore(state => state.isTableLoading)
  const isFilters = filter.filters?.length > 0 &&  filter.filters?.some(el => el.value !== "")



  const handleCloseModal = () => {
    setCreateUserModalOpen(false)
  }

  const handleOpenModal = () => {
    setCreateUserModalOpen(true)
  }
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({ ...filter, perpage: +event.target.value });
  };


  return (<Box sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        gap: '15px',
        maxHeight: 'calc(100vh - 68px)',
        overflowY: 'auto',
    }}>
      <Filter />
      <Box sx={{
        display: 'flex',
        gap: '15px',
        width: '100%',
        flexWrap: 'wrap'
      }}>
        {!isUser && <Button
          color={'error'}
          sx={{
            padding: '8px 10px',
            fontSize: '12px',
            fontWeight: '600',
            borderRadius: '8px',
            lineHeight: '1.5em',
            maxWidth: '200px',
          }}
          variant={'contained'}
          startIcon={<AddBoxOutlinedIcon/>}
          disabled={!hasPermission(PermissionsUser.handBookCreate)}
          onClick={handleOpenModal}>
          {t('handBook.createHandbook')}
        </Button>}
        {isFilters && <Button
            onClick={resetFilter}
            variant={'outlined'}
            color={'error'}
            sx={{
              padding: '8px 10px',
              fontSize: '12px',
              fontWeight: '600',
              borderRadius: '8px',
              lineHeight: '1.5em'
            }}
            startIcon={<RestartAltIcon/>}
        >
          {t('common.reset')}
        </Button>}
      </Box>
      {isTableLoading ?
        <Box sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <Loader/>
        </Box> :
        <Box sx={{
          flexGrow: 1,
          overflowY: 'auto',
        }}>
          <HandbookList/>
        </Box>}
      <TablePagination
        rowsPerPageOptions={[10, 15, 25, 100]}
        component="div"
        count={meta.total}
        rowsPerPage={filter.perpage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          overflow: 'hidden'
        }}
      />
      <CreateHandbook  open={createUserModalOpen} handleClose={handleCloseModal} />
  </Box>
  )
}

export default HandBooks