import {Box, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {RouteUrls} from "../../../interfaces/enum";
import {Link, useLocation} from "react-router-dom";


const AlreadyHaveAnAccount = () => {
    const { t } = useTranslation()
    const isLogin = useLocation().pathname === RouteUrls.SIGN_IN

    return <Box sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '5px'
    }}>
        <Box sx={{
            height: '1px',
            background: '#373737',
            flexGrow: '1',

        }}/>
        <Typography sx={{
            letterSpacing: '2px',
            '& .link': {
                textDecoration: 'none',
                fontWeight: '600',
                color: '#373737',
                letterSpacing: 'initial',
            }
        }}>
            {t(!isLogin ? 'signUp.haveAccount' : 'signIn.dontHaveAccount')}
            <Link
                className={'link'}
                to={!isLogin ? RouteUrls.SIGN_IN : RouteUrls.SIGN_UP}>
                {t(!isLogin ? 'signUp.login' : 'signIn.signUp')}
            </Link>
        </Typography>
        <Box sx={{
            height: '1px',
            background: '#373737',
            flexGrow: '1',
        }}/>
    </Box>
}

export default AlreadyHaveAnAccount