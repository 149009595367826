import {TableHead, TableRow} from "@mui/material";
import {useTranslation} from "react-i18next";
import {StyledTableCell} from "./style";

const UsersTableHead = () => {
  const { t } = useTranslation()

  return <TableHead sx={{
    borderBottom: '3px double #ccc'
  }}>
    <TableRow>
      <StyledTableCell>
        {t('usersTable.id')}
      </StyledTableCell>
      <StyledTableCell>
        {t('usersTable.email')}
      </StyledTableCell>
      <StyledTableCell>
        {t('usersTable.first_name')}
      </StyledTableCell>
      <StyledTableCell>
        {t('usersTable.last_name')}
      </StyledTableCell>
      <StyledTableCell>
        {t('usersTable.patronymic')}
      </StyledTableCell>
      <StyledTableCell>
        {t('usersTable.company')}
      </StyledTableCell>
      <StyledTableCell>
        {t('usersTable.phone_number')}
      </StyledTableCell>
      <StyledTableCell>
        {t('usersTable.role')}
      </StyledTableCell>
      <StyledTableCell>
        {t('usersTable.status')}
      </StyledTableCell>
      <StyledTableCell>
        {t('usersTable.last_login')}
      </StyledTableCell>
      <StyledTableCell>
      </StyledTableCell>
    </TableRow>
  </TableHead>
}

export default UsersTableHead