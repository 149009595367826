import ColorPicker from "../ColorPicker";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField
} from "@mui/material";
import {useProjectStore} from "../../../../../../../../store/projectStore";
import {useTranslation} from "react-i18next";

import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import React, {memo, useCallback, useEffect} from "react";
import useAsyncWrapper from "../../../../../../../../hooks/useAsyncWrapper";
import ChooseTypeLid from "../../../../../../Modals/ChooseTypeLid";
import ChooseTypePartition from "../../../../../../Modals/ChooseTypePartition";

const ChoosenFooter = () => {
  const { t } = useTranslation()

  const [isOpen, setIsOpen] = React.useState({
    lid: false,
    partition: false
  });
  //
  const projectLength = useProjectStore(state => state.projectLength)
  const setProjectLength = useProjectStore(state => state.setProjectLength)
  const choosenItem = useProjectStore(state => state.choosenItem)
  const currentTray = useProjectStore(state => state.currentTray)
  //
  const setTrayQuantity = useProjectStore(state => state.setTrayQuantity)
  const trayQuantity = useProjectStore(state => state.trayQuantity)
  //
  const isLid = useProjectStore(state => state.isLid)
  const setLid = useProjectStore(state => state.setLid)
  //
  const isPartition = useProjectStore(state => state.with_partition)
  const setPartition = useProjectStore(state => state.setPartition)


  const handleClose = useCallback(() => {
    setIsOpen({
      lid: false,
      partition: false
    });
  },[])

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    const lidValue = value === 'true';
    if(lidValue) {
      setIsOpen({
        ...isOpen,
        lid: true,
      });
    }
    setLid(lidValue);
  }

  const handleChangePartition = async (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    const partitionValue = value === 'true';
    if(partitionValue) {
      setIsOpen({
        ...isOpen,
        partition: true,
      });
    }
    setPartition(partitionValue);
  }

  const handleChangeLength = (type: 'down' | 'up') => {
    let length = choosenItem?.length / 1000;

    let value = type === 'down'
      ? (projectLength - length) > 0 ? (projectLength - length) : length
      : (projectLength + length)

    setProjectLength(value);
    setTrayQuantity(value / length);
  }

  const handleChangeTrayQuantity = (event: any) => {
    if(!Number.isNaN(+event.target.value % 1)) {
      const value = +event.target.value === 0 ? '' : +event.target.value;
      setTrayQuantity(value);

      if(value)
        setProjectLength(choosenItem?.length / 1000 * value);
    }
  }

  useEffect(() => () => {
    setLid(false);
    setPartition(false);
  },[])

  return <Box sx={{
    display: 'flex',
    gap: '0 20px',
    marginTop: '10px',
    borderTop: '1px solid #ccc',
    paddingTop: '10px',
    height: '100%',
    flexWrap: 'wrap',
    "@media (max-width: 900px)": {
      height: 'auto',
    }
  }}>
    <Box sx={{
      display: 'flex',
      gap: 'inherit',
      flexWrap: 'wrap',
    }}>
      <FormControl
        sx={{
          '& .MuiFormControlLabel-root': {
            width: '170px',
            fontSize: '14px',
            marginRight: '4px'
          },
          "& .MuiRadio-root": {
            padding: '4px 6px 4px 10px'
          }
        }}>
        <FormLabel>{t('project.complete')}</FormLabel>
        <RadioGroup
          defaultValue="false"
          name="radio-buttons-group"
          row
          onChange={handleChange}
          value={isLid?.toString()}
        >
          <FormControlLabel
            disabled={!choosenItem?.tray?.lids?.length}
            value="true"
            control={<Radio />}
            label={t('project.withLid')}
          />
          <FormControlLabel value="false" control={<Radio />} label={t('project.noLid')} />
        </RadioGroup>
        <RadioGroup
          defaultValue="false"
          row
          onChange={handleChangePartition}
          value={isPartition?.toString()}
        >
          <FormControlLabel
            disabled={!choosenItem?.tray?.partitions?.length}
            value="true"
            control={<Radio />}
            label={t('project.withPartition')}
          />
          <FormControlLabel value="false" control={<Radio />} label={t('project.noPartition')} />
        </RadioGroup>
      </FormControl>
      <ChooseTypeLid open={isOpen.lid} handleClose={handleClose}/>
      <ChooseTypePartition open={isOpen.partition} handleClose={handleClose}/>
    </Box>
    <Box sx={{
      display: 'flex',
      gap: 'inherit',
      flexWrap: 'wrap',
      '& .MuiInputBase-input, .MuiFormLabel-root': {
        fontSize: '18px!important',
      }
    }}>
      <TextField
        label={t('project.projectLength')}
        value={projectLength}
        type={'text'}
        sx={{
          maxWidth: '140px',
        }}
        InputProps={{
          readOnly: true,
          endAdornment: (
              <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  "& .MuiSvgIcon-root" : {
                    fontSize: '14px',
                    cursor: 'pointer'
                  }
                }}>
                <ArrowDropUpIcon onClick={() => handleChangeLength('up')}/>
                <ArrowDropDownIcon  onClick={() => handleChangeLength('down')}/>
              </Box>
          ),

        }}
        variant="standard" />
      <TextField
        label={t('project.trayQuantity')}
        value={trayQuantity}
        type={'text'}
        onBlur={() => {
          if(!trayQuantity) {
            setTrayQuantity(1);
            setProjectLength(choosenItem?.length / 1000);
          }
        }}
        sx={{
          maxWidth: '140px',
        }}
        onChange={handleChangeTrayQuantity}
        variant="standard" />
    </Box>
  </Box>

}

export default memo(ChoosenFooter)