import React, { useState } from "react";
import {
  Paper,
  Table,
  TableContainer, TablePagination,
} from "@mui/material";
import AdminsTableHead from "./components/AdminsTableHead";
import AdminsTableBody from "./components/AdminsTableBody";
import {useAdminsTableStore} from "../../../../store/adminsTableStore";
import {useTranslation} from "react-i18next";
import AdminsTableHeader from "./components/AdminsTableHeader";
import TableLoading from "../../TableLoading";
import NoTableData from "../../NoTableData";
const AdminsTable = () => {
  const { t } = useTranslation()

  const setFilter = useAdminsTableStore(state => state.setFilter);
  const setPage = useAdminsTableStore(state => state.setPage);
  const meta = useAdminsTableStore(state => state.tableData.meta);
  const page = useAdminsTableStore(state => state.page);
  const filter = useAdminsTableStore(state => state.filter);
  const isTableLoading = useAdminsTableStore(state => state.isTableLoading);

  const users = useAdminsTableStore(state => state.tableData)

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({ ...filter, perpage: +event.target.value });
  };


  return (
    <Paper sx={{
      width: '100%',
      overflow: 'hidden',
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      boxShadow: 'none',
      maxHeight: 'calc(100vh - 68px)',
    }}>
     <AdminsTableHeader/>
      <TableContainer sx={{
        borderRadius: ' 0 0 12px 12px',
        flexGrow: 1,
        height: '100%',
      }}>
        {isTableLoading ? <TableLoading /> : users.data?.length ?
        <Table
          stickyHeader
          aria-label="sticky table"
          sx={{
            borderSpacing: '0 8px'
          }}
        >
          <AdminsTableHead />
          <AdminsTableBody data={users.data} />
        </Table> : <NoTableData />}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 15, 25, 100]}
        component="div"
        count={meta.total}
        rowsPerPage={filter.perpage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          overflow: 'hidden'
        }}
      />

    </Paper>
  )
}

export default AdminsTable