import {Box, Table, TableContainer} from "@mui/material";
import TableLoading from "../../../../TableLoading";
import NoTableData from "../../../../NoTableData";
import React, {useEffect} from "react";
import ResultsTableBody from "./components/ResultsTableBody";
import ResultsTableHead from "./components/ResultsTableHead";
import {useProjectStore} from "../../../../../../store/projectStore";
import useAsyncWrapper from "../../../../../../hooks/useAsyncWrapper";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import {useDebounce} from "../../../../../../hooks/useDebounce";

let firstLoad = true;

const ResultList = () => {
  const resultList = useProjectStore(state => state.resultList)
  const currentTray = useProjectStore(state => state.currentTray)
  const currentModelValues = useProjectStore(state => state.currentModelValues)
  const currentCoating = useProjectStore(state => state.currentCoating)
  const isTableLoading = useProjectStore(state => state.isResultLoading)
  const search = useProjectStore(state => state.search)
  const debouncedSearch = useDebounce(search, 500)

  const choosenRec = useProjectStore(state => state.recommendation.choosenRec)

  const getModelParametersById = useProjectStore(state => state.getModelParametersById)
  const getModelParametersByIdHandler = useAsyncWrapper(getModelParametersById)

  useEffect(() => {
      (async () => {
        const filters = Object.keys(currentModelValues).map(key => ({
          // @ts-ignore
          field_name: key,rule:'contains',value: currentModelValues[key] ? +currentModelValues[key] : ''
        })).concat([{ field_name: 'coating_id', rule: 'contains', value: currentCoating?.id || '' }])

        const filter = {
          filters: filters,
          perpage: 50,
          page: 1,
          recommendation_id: choosenRec?.id,
          search: search
        }
        if(currentTray?.id || !firstLoad) {
          await getModelParametersByIdHandler([filter, currentTray?.id])
          firstLoad = false
        }
      })()

      return () => {
        firstLoad = false
      }
  },[JSON.stringify(currentModelValues), currentTray, currentCoating, choosenRec, debouncedSearch])

  return (<Box>
    <TableContainer sx={{
      borderRadius: ' 0 0 12px 12px',
      flexGrow: 1,
      position: 'relative',
      // height: '100%',
      height: 'calc(38vh)',
      border: '1px solid #ccc',
      // minWidth: '500px',
      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
      "@media (max-width: 1100px)": {
        height: 'calc(25vh)',
      },
      "@media (max-width: 900px)": {
        maxHeight: '50vh',
        height: '100%',
        minHeight: '20vh',
      }
    }}>
        <Table
          stickyHeader
          aria-label="sticky table"
          sx={{
            // borderSpacing: '0 8px',
            height: '100%',
            "&.MuiTable-root": {
              height: 'auto',
            }


          }}
        >
          <ResultsTableHead />
          {isTableLoading ? <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={0}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <CircularProgress size={30} />
          </Stack> : resultList.length ? <ResultsTableBody data={resultList} /> : <NoTableData />}
        </Table>
    </TableContainer>
  </Box>)
}

export default ResultList