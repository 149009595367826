import {useEffect} from "react";
import useAsyncWrapper from "../../hooks/useAsyncWrapper";
import Images from "../../components/UI/Images";
import {useImagesStore} from "../../store/imagesStore";

let prevFilter = {}
const ImagesPage = () => {

  const getImages = useImagesStore(state => state.getImages);
  const filter = useImagesStore(state => state.filter);
  const page = useImagesStore(state => state.page);
  const getImagesHandler = useAsyncWrapper(getImages)

  useEffect(() => {
    (async () => {
      if(JSON.stringify(prevFilter) !== JSON.stringify(filter) || filter.page !== page) {
        prevFilter = filter
        await getImagesHandler([filter, page])
      }

    })();
  },[filter, page])


  return (<>
    <Images />
  </>)
}
export default ImagesPage