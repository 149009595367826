import {useTranslation} from "react-i18next";
import useToast from "../../../../../hooks/useToast";
import React, {useState} from "react";
import useAsyncWrapper from "../../../../../hooks/useAsyncWrapper";
import {getEditUserValidationSchema} from "validations/usersTable/editUser";
import {Controller, useForm} from "react-hook-form";
import {IUser} from "../../../../../interfaces/general";
import {yupResolver} from "@hookform/resolvers/yup";
import {Box, Button, Checkbox, Grid, ListItemText, MenuItem, Select, Switch, TextField, Tooltip} from "@mui/material";
import {StyledFormControlLabel} from "../../Settings/SettingsForm/style";
import ButtonLoadingWrapper from "../../../../Wrappers/ButtonLoading";
import {useUsersTableStore} from "../../../../../store/usersTableStore";
import createFilteredPayload from "../../../../../utils/removeDuplicate";
import {useGeneralStore} from "../../../../../store/generalStore";
import {useAdminsTableStore} from "../../../../../store/adminsTableStore";
import {useLocation} from "react-router-dom";

interface IEditUserForm {
  email: string;
  first_name?: string;
  last_name?: string;
  phone_number: string;
  company?: string;
  patronymic?: string;
  role_id: number;
  password: string | null;
  blocked?: boolean;
  active?: boolean;
  permissions?: any[];
  permission_ids?: any[];
}

interface IProps {
  user: IUser;
  handleClose: () => void;
}

const EditUserForm = ({ user, handleClose } : IProps) => {
  const { t } = useTranslation()
  const { errorToast, successToast } = useToast();
  const isAdmin = useLocation().pathname === '/admins'

  const [isLoading, setIsLoading] = useState(false);
  const roles = useGeneralStore(state => state.roles);
  const permissions = useGeneralStore(state => state.permissions);

  const editUser = useUsersTableStore(state => state.editUser);
  const getUsers = useUsersTableStore(state => state.getUsers)
  const filter = useUsersTableStore(state => state.filter);
  const page = useUsersTableStore(state => state.page);

  const getAdmins = useAdminsTableStore(state => state.getUsers)
  const filterAdmins = useAdminsTableStore(state => state.filter);
  const pageAdmins = useAdminsTableStore(state => state.page);

  const getAdminsHandler = useAsyncWrapper(getAdmins);
  const getUsersHandler = useAsyncWrapper(getUsers);
  const editUserHandler = useAsyncWrapper(editUser)

  const initialFormState = {
    email: user?.email ?? '',
    first_name: user?.first_name ?? '',
    last_name: user?.last_name ?? '',
    phone_number: user?.phone_number ?? '',
    company: user?.company ?? '',
    patronymic: user?.patronymic ?? '',
    role_id: user?.role.id ?? 1,
    password: null,
    blocked: user?.blocked ?? false,
    active: user?.active ?? false,
    permission_ids: user?.permissions?.map(el => el?.id) ?? [],
  }

  const schema = getEditUserValidationSchema(t);
  const {handleSubmit, control, watch} = useForm<IEditUserForm>({
    defaultValues: initialFormState,
    resolver: yupResolver(schema),
  });


  const handleEditSubmit = async (body: IEditUserForm) => {

    const payload = createFilteredPayload(body, initialFormState)

    setIsLoading(true);
    const { error , status} = await editUserHandler( [user?.id, payload] );
    setIsLoading(false);


    if (status === 400) {
      const errors = error?.response?.data?.message ?? [];
      if(error?.response?.data?.message.length > 0) {
        errors.forEach((err: any) => errorToast(t('error.error'), t('createUser.error.400.' + err?.field)))
      }

      return
    }
    if(!error) {
      successToast(t('editUser.success'))
      handleClose()
      if(isAdmin) {
        const customFilter = [
          {"field_name":"role_id","rule":"contains","value": `${roles?.find(el => el.name === 'ROLE_SUPER_ADMIN')?.id ?? ''}`},
          {"field_name":"role_id","rule":"contains","value": `${roles?.find(el => el.name === 'ROLE_ADMIN')?.id ?? ''}`}]

        const props = {
          ...filter,
          filters: [...customFilter, ...filterAdmins.filters]
        }
        await getAdminsHandler([props, pageAdmins])
        return;
      }
      const customFilter = [
        {"field_name":"role_id","rule":"contains","value": `${roles?.find(el => el.name === 'ROLE_USER')?.id ?? ''}`}]

      const props = {
        ...filter,
        filters: [...customFilter, ...filter.filters]
      }

      await getUsersHandler([props, page]);
      return;
    }
    errorToast(t('editUser.error.default'))
  }


  return (
    <Box
      component={'form'}
      onSubmit={handleSubmit(handleEditSubmit)}
      sx={{
        '@media (min-width: 600px)': {
          minWidth: '350px',
        }
      }}
    >
      <Grid
        container
        spacing={1}
        mb={2}
      >
        <Grid item xs={12} md={12}>
          <Controller
            name={"role_id"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                label={t("createUser.role")}
                control={(
                  <Select
                    fullWidth
                    sx={{
                      padding: '0px',
                      maxHeight: '40px',
                      '& .MuiSelect-select': {
                        padding: '10px 0 10px 10px',
                      }
                    }}
                    value={value}
                    onChange={onChange}
                    error={error?.ref?.name === name}
                  >
                    {roles.map((role) => {
                      return (
                        <MenuItem key={role.id} value={role.id}>
                          {role.name}
                        </MenuItem>
                      );
                    })}
                  </Select>)
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name={"first_name"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                label={t("editUser.first_name")}
                control={
                  <TextField
                    fullWidth
                    onChange={onChange}
                    value={value}
                    error={error?.ref?.name === name}
                    helperText={error ? error.message : ' '}
                  />
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name={"last_name"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                label={t("editUser.last_name")}
                control={
                  <TextField
                    fullWidth
                    onChange={onChange}
                    value={value}
                    error={error?.ref?.name === name}
                    helperText={error ? error.message : ' '}
                  />
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name={"patronymic"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                label={t("editUser.patronymic")}
                control={
                  <TextField
                    fullWidth
                    onChange={onChange}
                    value={value}
                    error={error?.ref?.name === name}
                    helperText={error ? error.message : ' '}
                  />
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name={"email"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                label={t("editUser.email")}
                control={
                  <TextField
                    fullWidth
                    onChange={onChange}
                    value={value}
                    error={error?.ref?.name === name}
                    helperText={error ? error.message : ' '}
                  />
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name={"company"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                label={t("editUser.company")}
                control={
                  <TextField
                    fullWidth
                    onChange={onChange}
                    value={value}
                    error={error?.ref?.name === name}
                    helperText={error ? error.message : ' '}
                  />
                }
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            name={"phone_number"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                label={t("editUser.phone_number")}
                control={
                  <TextField
                    fullWidth
                    onChange={onChange}
                    value={value}
                    error={error?.ref?.name === name}
                    helperText={error ? error.message : ' '}
                  />
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            name={"password"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                label={t("editUser.password")}
                control={
                  <TextField
                    fullWidth
                    type={'password'}
                    onChange={onChange}
                    value={value ?? ''}
                    error={error?.ref?.name === name}
                    helperText={error ? error.message : ' '}
                  />
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={12} sx={{
          display: 'flex',
        }}>
          <Controller
            name={"blocked"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="start"
                sx={{
                  justifyContent: 'flex-end',
                }}
                label={t("editUser.blocked")}
                control={
                  <Switch
                    name={name}
                    checked={value}
                    onChange={onChange}
                  />
                }
              />
            )}
          />
          <Controller
            name={"active"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="start"
                sx={{
                  justifyContent: 'flex-end',
                }}
                label={t("editUser.active")}
                control={
                  <Switch
                    name={name}
                    checked={value}
                    onChange={onChange}
                  />
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            name={"permission_ids"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <Tooltip title={t(watch('role_id') === roles?.find(el => el?.name === 'ROLE_ADMIN')?.id  ? '' : watch('role_id') === roles?.find(el => el?.name === 'ROLE_SUPER_ADMIN')?.id ? 'common.allPermissions' : 'common.userPermissions')}>
                    <StyledFormControlLabel
                      labelPlacement="top"
                      sx={{
                        alignItems: 'flex-start'
                      }}
                      label={t("createUser.permissions")}
                      control={(
                        <Select
                          fullWidth
                          disabled={roles.find(el => el.name === 'ROLE_ADMIN')?.id !== watch('role_id')}
                          sx={{
                            padding: '0px',
                            maxHeight: '40px',
                            '& .MuiSelect-select': {
                              padding: '10px 0 10px 10px',
                            }
                          }}
                          value={value}
                          onChange={onChange}
                          multiple
                          renderValue={(selected) => selected.map((id: any) => t('permissions.' + permissions.find(el => el.id === id)?.technical_name)).join(', ')}
                          error={error?.ref?.name === name}
                        >
                          {permissions.map((per) => {
                            return (
                              <MenuItem key={per.id} value={per.id}>
                                <Checkbox checked={value?.includes(per.id)} />
                                <ListItemText primary={t('permissions.' +per.technical_name)}/>
                              </MenuItem>
                            );
                          })}
                        </Select>)
                      }
                    />
                  </Tooltip>
            )}
          />
        </Grid>
      </Grid>
      <ButtonLoadingWrapper isLoading={isLoading}>
        <Button
          variant="contained"
          type="submit"
          fullWidth
          size="large"
          sx={{
            marginTop: '10px',
            background: '#373737',
            '&:hover': {
              background: '#666',
            }
          }}
        >

          {t("editUser.submit")}
        </Button>
      </ButtonLoadingWrapper>
    </Box>
  )
}
export default EditUserForm