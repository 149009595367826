import {Box, TableBody, TableRow, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {StyledActionButton, StyledTableCell} from "./style";
import useAsyncWrapper from "../../../../../../hooks/useAsyncWrapper";
import ConfirmDelete from "../../../../Modals/ConfirmDelete";
import {useState} from "react";
import usePermission from "../../../../../../hooks/usePermission";
import {PermissionsUser} from "../../../../../../interfaces/enum";
import useToast from "../../../../../../hooks/useToast";
import {useRecommendationsStore} from "../../../../../../store/recommendationsStore";
import EditRecommendation from "../../../../Modals/EditRecommendation";


interface IProps {
  data: any[]
}
const RecommendationTableBody = ({ data }: IProps) => {
  const { t } = useTranslation()
  const { errorToast, successToast} = useToast()
  const [id, setId] = useState(0);
  const { hasPermission } = usePermission()

  const [open, setOpen] = useState({
    delete: false,
    edit: false
  });

  const handleOpenDelete = (id: number) => {
    setId(id)

    setOpen({
      ...open,
      delete: true
    })
  }
  const handleOpenEdit = (id: number) => {
    setId(id)
    setOpen({
      ...open,
      edit: true
    })
  }
  const handleCloseDelete = () => {
    setOpen({
      ...open,
      delete: false
    })
    setId(0)
  }
  const handleCloseEdit = () => {
    setOpen({
      ...open,
      edit: false
    })
    setId(0)
  }

  const deleteRecommendation = useRecommendationsStore(state => state.deleteRecommendation);
  const deleteRecommendationHandler = useAsyncWrapper(deleteRecommendation);

  const handleDeleteUser = async (id: number) => {
    const {error} = await deleteRecommendationHandler([id])
    if(!error) {
      successToast(t('deleteRecommendation.success'))
      return;
    }
    errorToast(t('deleteRecommendation.error'));  }


  return (<TableBody>

    {data
      ?.map((row) => {
        return (
          <TableRow
            hover
            role="checkbox"
            tabIndex={-1}
            key={row.id}
            sx={{
              '&.MuiTableRow-hover:hover': {
                opacity: 0.8,
              },
              '& .MuiTableCell-root': {
                backgroundColor: '#D8D9DA',
                color: 'black'
              }
            }}>
            <StyledTableCell>
              <Typography>{row.id}</Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography fontWeight={600}>{row?.coatings?.map(( el:any) => el?.code).join(', ')}</Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography>{t('common.'+ row.installation)}</Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography>{t('common.'+ row.product)}</Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography>{row.place_installation_uk}</Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography>{row.place_installation_en}</Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Box sx={{
                display: 'flex',
                gap: '5px'
              }}>
                <StyledActionButton disabled={!hasPermission(PermissionsUser.recommendationUpdate)} onClick={() => handleOpenEdit(row.id)}>
                  <EditIcon color={'warning'} />
                </StyledActionButton>
                <StyledActionButton disabled={!hasPermission(PermissionsUser.recommendationDestroy)} onClick={() => handleOpenDelete(row.id)}>
                  <DeleteIcon color={'error'}/>
                </StyledActionButton>
              </Box>
            </StyledTableCell>
          </TableRow>
        );
      })}
    <ConfirmDelete
      text={t('deleteRecommendation.submissionText')}
      title={t('deleteRecommendation.title')}
      handleSubmit={() => handleDeleteUser(id)}
      handleClose={handleCloseDelete}
      open={open.delete}
    />
    <EditRecommendation
      id={id}
      open={open.edit}
      handleClose={handleCloseEdit}
    />
  </TableBody>)
}

export default RecommendationTableBody