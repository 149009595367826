import {useTranslation} from "react-i18next";


const useLang = () => {
  const { i18n } = useTranslation()
  const lang = i18n.language === 'en' ? 'en' : 'uk'


  function getTranslate(key: string, object: any, defaultLang = 'uk') {
    const keyToShow = Object.keys(object)?.find((el) => el?.includes(key) && el?.includes(lang));
    const keyToShowDefault = Object.keys(object)?.find((el) => el?.includes(key) && el?.includes(defaultLang));


    const content = keyToShow ? object[keyToShow] || (keyToShowDefault ? object[keyToShowDefault] : 'no content') : 'no content'

    return content

  }


 return {getTranslate}
}

export default useLang