import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {StyledFormControlLabel} from "./style";
import {Box, Button, Grid, TextField} from "@mui/material";
import ButtonLoadingWrapper from "../../../../Wrappers/ButtonLoading";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useUserStore} from "../../../../../store/userStore";
import useAsyncWrapper from "../../../../../hooks/useAsyncWrapper";
import useToast from "hooks/useToast";
import {getResetPasswordValidationSchema} from "../../../../../validations/editUser/resetPassword";

interface IResetPasswordForm {
  password_old: string;
  password: string;
  passwordConfirm?: string;
}

const ResetPasswordForm = () => {
  const { t } = useTranslation()
  const { errorToast, successToast } = useToast();

  const [isLoading, setIsLoading] = useState(false);

  const profileSettings = useUserStore(state => state.profileSettings);

  const profileSettingsHandler = useAsyncWrapper(profileSettings)

  const initialFormState = {
    password_old: '',
    password: '',
    passwordConfirm: '',
  }

  const schema = getResetPasswordValidationSchema(t);
  const {handleSubmit, control } = useForm<IResetPasswordForm>({
    defaultValues: initialFormState,
    resolver: yupResolver(schema),
  });


  const handleResetSubmit = async (body: IResetPasswordForm) => {
    delete body.passwordConfirm;

    setIsLoading(true)
    const { error } = await profileSettingsHandler( [body] )
    setIsLoading(false)

    if(!error) {
      successToast(t('resetPassword.success'))
      return;
    }
    errorToast(t('resetPassword.error.default'))
  }





  return (
    <Box
      component={'form'}
      onSubmit={handleSubmit(handleResetSubmit)}
      sx={{
        '@media (min-width: 600px)': {
          minWidth: '350px',
        }
      }}
    >
      <Grid
        container
        spacing={1}
      >
        <Grid item xs={12} md={12}>
          <Controller
            name={"password_old"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                label={t("signUp.password_old")}
                control={
                  <TextField
                    fullWidth
                    type={'password'}
                    onChange={onChange}
                    value={value}
                    error={error?.ref?.name === name}
                    helperText={error ? error.message : ' '}
                  />
                }
              />
            )}
          />
        </Grid> <Grid item xs={12} md={12}>
          <Controller
            name={"password"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                label={t("signUp.password")}
                control={
                  <TextField
                    fullWidth
                    type={'password'}
                    onChange={onChange}
                    value={value}
                    error={error?.ref?.name === name}
                    helperText={error ? error.message : ' '}
                  />
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            name={"passwordConfirm"}
            control={control}
            render={({
                       fieldState: {error},
                       field: {onChange, value, name},
                     }) => (
              <StyledFormControlLabel
                labelPlacement="top"
                sx={{
                  alignItems: 'flex-start'
                }}
                label={t("signUp.passwordConfirm")}
                control={
                  <TextField
                    fullWidth
                    type={'password'}
                    onChange={onChange}
                    value={value}
                    error={error?.ref?.name === name}
                    helperText={error ? error.message : ' '}
                  />
                }
              />
            )}
          />
        </Grid>
      </Grid>
      <ButtonLoadingWrapper isLoading={isLoading}>
        <Button
          variant="contained"
          type="submit"
          fullWidth
          size="large"
          sx={{
            background: '#373737',
            '&:hover': {
              background: '#666',
            }
          }}
        >

          {t("forgotPassword.submit")}
        </Button>
      </ButtonLoadingWrapper>
    </Box>
  )
}
export default ResetPasswordForm