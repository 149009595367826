import {Box, Button, IconButton, InputAdornment, TextField} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditNumbers from "../Modals/EditNumbers";
import React from "react";
import {useImagesStore} from "../../../store/imagesStore";

interface IProps {

}
const CustomImageField = ({
                     }:IProps) => {

  const images = useImagesStore(state => state.modal.tableDataModal.data)
  const choosedImages = useImagesStore(state => state.modal.choosedImages)
  const toggleModal = useImagesStore(state => state.toggleModal)

  return<Box sx={{
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%'
  }}>
    <TextField
      fullWidth
      aria-readonly={"true"}
      type={'text'}
      disabled={true}
      value={images.filter(el => choosedImages.includes(el.id)).map(el => el.file_name).join(', ')}
    />
    <IconButton onClick={() => toggleModal(true)}>
      <AddIcon />
    </IconButton>
  </Box>
}

export default CustomImageField