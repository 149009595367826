import { create } from "zustand";
import {
  ICreateCoatingForm,
  IEditCoating,
  IFilter,
  IResponseTable,
} from "../interfaces/general";
import {coatings_api} from "../api/apis/coatings.api";


interface ICoatingsStoreState {
  isLoading: boolean,
  isTableLoading: boolean,
  tableData: IResponseTable
  filter: IFilter,
  filterSelect: IFilter,
  page: number,
}
interface ICoatingsStore  extends ICoatingsStoreState{
  setPage: (page: number) => void
  setFilter: (filter: IFilter) => void
  getCoatings: (params: IFilter, page: number) => Promise<any>,
  createCoating: (payload: ICreateCoatingForm) => Promise<any>,
  deleteCoating: (id: number) => Promise<any>,
  resetFilter: () => void,
  getCoating: (id: number) => Promise<any>,
  editCoating: (id: number, payload: IEditCoating) => Promise<any>,
}

const initialState = {
  isLoading: false,
  tableData: {
    data: [],
    meta: {
      total: 0,
      current_page: 0,
      last_page: 0,
      perpage: 0,
    }
  },
  filter: {
    filters: [],
    perpage: 15,
    page: -1,
  },
  filterSelect: {
    filters: [],
    perpage: 100,
    page: 0,
  },
  page: 0,
  isTableLoading: true,
}

export const useCoatingsStore = create<ICoatingsStore>((set) => ({
  ...initialState,
  setPage: (page) => {
    set(state => ({...state, page}))
  },
  setFilter: (filter) => {
    set(state => ({...state, filter, page: 0 }))
  },
  resetFilter: () => {
    set(state => ({...state, filter: initialState.filter, page: 0 }))
  },
  getCoatings: async (params, page) => {
    set(state => ({...state, isTableLoading: true}))
    const response = await coatings_api.coatings({...params, page: page + 1})
      .finally(() => set(state => ({...state, isTableLoading: false})));

    const { status, data } = response;

    if (!status || !data) {
      throw new Error("No status code or data returned from server.");
    }

    if (status !== 200) {
      throw new Error(data);
    }

    set((state) => ({
      ...state,
      tableData: data
    }));
  },
  createCoating: async (payload: ICreateCoatingForm) => {
    const response = await coatings_api.createCoating({
      ...payload
    });

    const { status, data } = response;

    if (!status || !data) {
      throw new Error("No status code or data returned from server.");
    }

    if (status !== 201) {
      throw new Error(data);
    }

    set((state) => ({
      ...state,
      tableData: {
        ...state.tableData,
        data: [data.data, ...state.tableData.data]
      }
    }));

    return data;

  },
  deleteCoating: async (id) => {
    const response = await coatings_api.deleteCoating(id);

    const { status, data } = response;

    if (status !== 204) {
      throw new Error(data);
    }
    set((state) => ({
      ...state,
      tableData: {
        ...state.tableData,
        data: state.tableData.data.filter((item) => item.id !== id),
      },
    }));
  },
  getCoating: async (id) => {
    const response = await coatings_api.getCoating(id);

    const { status, data } = response;

    if (status !== 200) {
      throw new Error(data);
    }
    set((state) => ({
      ...state,
      // tableData: {
      //   ...state.tableData,
      //   data: state.tableData.data.filter((item) => item.id !== id),
      // },
    }));
    return data;
  },
  editCoating: async (id, payload) => {
    const response = await coatings_api.editCoating(id, payload);

    const { status, data } = response;


    set((state) => ({
      ...state,
      tableData: {
        ...state.tableData,
        data: state.tableData.data.map((item) => item.id === id ? data.data : item),
      },
    }));
    return data;
  }
}));

export const useCoatingsTableStoreOut = useCoatingsStore.getState();