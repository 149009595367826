import {Box, Button, Dialog, DialogContent, InputAdornment, TextField, Typography} from "@mui/material";
import {StyledDialogTitle} from "../../Auth/ForgotPassword/style";
import CloseSvg from "../../../../assets/svgs/common/CloseSvg";
import React, {ChangeEventHandler, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import ChooseImageList from "./ChooseImageList";
import {useImagesStore} from "../../../../store/imagesStore";
import SearchIcon from "@mui/icons-material/Search";

interface IProps {
}
const ChooseImage = ({  } : IProps ) => {
  const { t } = useTranslation();
  const open = useImagesStore(state => state.modal.isOpen)

  const images = useImagesStore(state => state.modal.tableDataModal.data)
  const choosedImages = useImagesStore(state => state.modal.choosedImages)
  const setChoosedImages = useImagesStore(state => state.setChoosedImages)
  const toggleModal = useImagesStore(state => state.toggleModal)

  const [filteredImages, setFilteredImages] = useState(images)
  const [search, setSearch] = useState('');

  const handleClose = () => {
    toggleModal(false)
  }
  const handleSearch = (e: any) => {
    const value = e.target.value
    setSearch(value);
    const newData = images.filter((item: any) => {
      const values = Object.values(item).map(el => el?.toString()?.toLowerCase());

      if(values.some(el => el?.includes(value?.toLowerCase()))) {
        return item
      }
    })
    setFilteredImages(newData)

  }

  const handleCancel = () => {
    setChoosedImages([])
    setSearch('')
    setFilteredImages(images)
    handleClose()
  }

  useEffect(() => {
    if(!open) {
      setSearch('')
      setFilteredImages(images)
    }
  },[open])
  useEffect(() => {
    setFilteredImages(images)
  },[images.length])

  return <Dialog
    open={open}
    onClose={handleCancel}
  >
    <StyledDialogTitle>
      <Typography
        fontWeight={600}
        variant={'h6'}
        color={'#373737'}
      >
        {t('images.addImages')}
      </Typography>
      <Box onClick={handleCancel}>
        <CloseSvg />
      </Box>
    </StyledDialogTitle>

    <DialogContent sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: '5px',
    }}>
      <TextField
        label={t('common.search')}
        type="search"
        variant="standard"
        onChange={handleSearch}
        sx={{
          minWidth: '250px',
        }}
        value={search}
        helperText={`${t('count')}: ${choosedImages.length}`}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <ChooseImageList images={filteredImages}/>
      <Box sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '15px',
      }}>
        <Button
          color={'error'}
          onClick={handleCancel}
        >
          {t('common.cancel')}
        </Button>
        <Button
          variant={'contained'}
          onClick={handleClose}
        >
          {t('common.save')}
        </Button>
      </Box>
    </DialogContent>
  </Dialog>

}

export default ChooseImage