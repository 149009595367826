
export enum RouteUrls {
    PROJECTS = "/projects",
    PROJECT_VIEW = "projects/:id",
    USERS = "/users",
    SIGN_IN = "/sign-in",
    SIGN_UP = "/sign-up",
    ADMINS = "/admins",
    HAND_BOOKS = "/handBooks",
    METAL_PRODUCT = "/metalProducts",
    LID = "/lids",
    TRAY = "/trays",
    COATINGS = "/coatings",
    FILES = "/files",
    RECOMMENDATIONS = "/recommendations",
    IMAGES = "/images",
    PRIVACY_POLICY = "/privacy-policy",
}

export const PermissionsUser = {
  "imageDestroy": "imageDestroy",
  "imageList": "imageList",
  "imageUpdate": "imageUpdate",
  "imageCreate": "imageCreate",
  "metalProductDestroy": "metalProductDestroy",
  "metalProductUpdate": "metalProductUpdate",
  "metalProductCreate": "metalProductCreate",
  "metalProductList": "metalProductList",
  "coatingDestroy": "coatingDestroy",
  "coatingUpdate": "coatingUpdate",
  "coatingCreate": "coatingCreate",
  "coatingList": "coatingList",
  "recommendationDestroy": "recommendationDestroy",
  "recommendationUpdate": "recommendationUpdate",
  "recommendationCreate": "recommendationCreate",
  "recommendationList": "recommendationList",
  "lidDestroy": "lidDestroy",
  "lidUpdate": "lidUpdate",
  "lidCreate": "lidCreate",
  "lidList": "lidList",
  "trayDestroy": "trayDestroy",
  "trayUpdate": "trayUpdate",
  "trayCreate": "trayCreate",
  "trayList": "trayList",
  "handBookDestroy": "handBookDestroy",
  "handBookUpdate": "handBookUpdate",
  "handBookCreate": "handBookCreate",
  "handBookList": "handBookList",
  "userDestroy": "userDestroy",
  "userUpdate": "userUpdate",
  "userCreate": "userCreate",
  "userList": "userList",
  "systemLogList": "systemLogList",
  "sendMessageAboutNewRegistration": "sendMessageAboutNewRegistration",
  "fileExport": "fileExport",
  "fileImport": "fileImport",
  "fileHistory": "fileHistory",
  "projectDestroy": "projectDestroy",
  "projectUpdate": "projectUpdate",
  "projectCreate": "projectCreate",
  "typeCreate": "typeCreate",
  "typeUpdate": "typeUpdate",
  "systemConfiguration": "systemConfiguration",


}