import React, {useEffect} from "react";
import useAsyncWrapper from "../../hooks/useAsyncWrapper";
import {useMetalProductStore} from "../../store/metalProductsStore";
import MetalProductsTable from "../../components/UI/Tables/MetalProductsTable";
import {useCoatingsStore} from "../../store/coatingsStore";
import ChooseImage from "../../components/UI/Modals/ChooseImages";
import {useImagesStore} from "../../store/imagesStore";

let prevFilter = {}
const MetalProducts = () => {

  const getMetalProducts = useMetalProductStore(state => state.getMetalProducts)
  const filter = useMetalProductStore(state => state.filter);

  const getCoatings = useCoatingsStore(state => state.getCoatings);
  const filterSelect = useCoatingsStore(state => state.filterSelect);
  const getImages = useImagesStore(state => state.getImages);
  const filterImages = useImagesStore(state => state.modal.filterModal);

  const getMetalProductsHandler = useAsyncWrapper(getMetalProducts);
  const getCoatingsHandler = useAsyncWrapper(getCoatings);
  const getImagesHandler = useAsyncWrapper(getImages);



  const page = useMetalProductStore(state => state.page);


  useEffect(() => {
    (async () => {
      if(JSON.stringify(prevFilter) !== JSON.stringify(filter) || filter.page !== page) {
        prevFilter = filter
        await getMetalProductsHandler([filter, page])
      }
    })();
  },[filter, page])

  useEffect(() => {
    (async () => {
      await getCoatingsHandler([filterSelect, 0])
      await getImagesHandler([filterImages, 0, true])
    })();
  },[])
  return (
    <>
      <MetalProductsTable />
      <ChooseImage />

    </>

  );
};

export default MetalProducts;
