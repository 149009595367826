import {Box} from "@mui/material";

interface IProps {
  width?: string
  height?: string
}

const Logo = ({ width, height } : IProps) => {

  return (<Box>
    <Box sx={{
            width: width ?? 'auto',
            height: height ?? 'auto',
         }}
         component={'img'}
         src="./logo_footer.png"
         alt="logo"
    />
  </Box>)
}

export default Logo