import {Box, Button, IconButton, MobileStepper, Paper, Typography} from "@mui/material";
import React, {memo, useEffect, useState} from "react";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import {useProjectStore} from "../../../../../store/projectStore";
import {env} from "../../../../../constants";


interface IProps {
  images: any[];
}

const hoverStyles = {
  transform: 'scale(1.6)',
  zIndex: '999',
  position: 'absolute',
  top: '25%',
  left: '25%',
  transition: 'all 0.2s ease-in-out',
  boxShadow: '0 0 4px 5px rgba(0,0,0,0.1)',
}

const ImageCarousel = ({ images }: IProps) => {
  const [activeStep, setActiveStep] = useState(0);


  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep < (images.length - 1)? prevActiveStep + 1 : 0);
  }
  const handlePrev = () => {
    setActiveStep((prevActiveStep) => prevActiveStep ? prevActiveStep - 1 : images.length - 1);
  }
  return (
    <Box sx={{
      position: 'relative',
      minWidth: '145px',
      height: '145px',
      padding: '10px',
      border: '2px solid #ccc',
      borderRadius: '5px',
    }}>
      {images.length > 1 && <IconButton
          onClick={handlePrev}
          sx={{
        position: 'absolute',
        top: '50%',
        left: '-14px',
        transform: 'translateY(-50%)',
        background: 'rgba(255,255,255,0.5)',
        padding: '3px',
        borderLeft: '2px solid #ccc',
        "&:hover": {
          background: 'rgba(255,255,255,0.8)',
        }
      }}>
        <ArrowBackIosNewIcon/>
      </IconButton>}
      <Box
        component={'img'}
        sx={{
          width: '100%',
          height: '100%',
          transition: 'all 0.2s ease-in-out',
          "&:hover": {
            ...(images[activeStep]?.file_url ? hoverStyles : {})
          }
        }}
        src={images[activeStep]?.file_url ? (env.REACT_APP_DEV_BASE_API_URL + '/' + images[activeStep]?.file_url) : '/no-content.jpg'}
      />
      {images.length > 1 && <IconButton
        onClick={handleNext}
        sx={{
          position: 'absolute',
          padding: '3px',
          top: '50%',
          right: '-14px',
          transform: 'translateY(-50%) rotate(180deg)',
          background: 'rgba(255,255,255,0.5)',
          borderLeft: '2px solid #ccc',
          "&:hover": {
            background: 'rgba(255,255,255,0.8)',
          }
        }}>
        <ArrowBackIosNewIcon />
      </IconButton>}
    </Box>
  )

}

export default memo(ImageCarousel)