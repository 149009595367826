import {Button, TableCell} from "@mui/material";
import {styled} from "@mui/system";

export const StyledTableCell = styled(TableCell)(() => ({
  padding: '6px 10px',
  background: '#D8D9DA',
  '&:first-of-type': {
    borderRadius: '5px 0 0 5px'
  },
  '&:last-of-type': {
    borderRadius: '0 5px 5px 0'
  },
  '& .MuiTypography-root ': {
    fontSize: '14px'
  }
}))
export const StyledActionButton = styled(Button)(() => ({
  padding: '0',
  minWidth: 'auto'
}))