import {TableHead, TableRow} from "@mui/material";
import {useTranslation} from "react-i18next";
import {StyledTableCell} from "./style";

const FilesTableHead = () => {
  const { t } = useTranslation()

  return <TableHead sx={{
    borderBottom: '3px double #ccc'
  }}>
    <TableRow>
      <StyledTableCell>
        {t('files.id')}
      </StyledTableCell>

      <StyledTableCell>
          {t('files.model')}
      </StyledTableCell>
      <StyledTableCell>
          {t('files.file_status')}
      </StyledTableCell>
      <StyledTableCell>
        {t('files.action')}
      </StyledTableCell>
      <StyledTableCell>
        {t('files.file_name')}
      </StyledTableCell>
      <StyledTableCell>
        {t('files.error')}
      </StyledTableCell>
      <StyledTableCell>
        {t('files.created_at')}
      </StyledTableCell>
      <StyledTableCell>
        {/* Add any additional columns if needed */}
      </StyledTableCell>
    </TableRow>
  </TableHead>
}

export default FilesTableHead