import { create } from "zustand";
import {
  ICreateMetalProductForm,
  IEditMetalProduct,
  IFilter,
  IResponseTable,
  IRole
} from "../interfaces/general";
import {metalProducts_api} from "../api/apis/metalProducts.api";


interface IMetalProductsState {
  isLoading: boolean,
  isTableLoading: boolean,
  tableData: IResponseTable
  filter: IFilter,
  page: number,
}
interface IMetalProductsStore  extends IMetalProductsState{
  setPage: (page: number) => void
  setFilter: (filter: IFilter) => void
  getMetalProducts: (params: IFilter, page: number) => Promise<any>,
  createMetalProduct: (payload: ICreateMetalProductForm) => Promise<any>,
  deleteMetalProduct: (id: number) => Promise<any>,
  resetFilter: () => void,
  getMetalProduct: (id: number) => Promise<any>,
  editMetalProduct: (id: number, payload: IEditMetalProduct) => Promise<any>,
}

const initialState = {
  isLoading: false,
  tableData: {
    data: [],
    meta: {
      total: 0,
      current_page: 0,
      last_page: 0,
      perpage: 0,
    }
  },
  filter: {
    filters: [],
    perpage: 15,
    page: -1,
  },
  page: 0,
  isTableLoading: true,
}

export const useMetalProductStore = create<IMetalProductsStore>((set) => ({
  ...initialState,
  setPage: (page) => {
    set(state => ({...state, page}))
  },
  setFilter: (filter) => {
    set(state => ({...state, filter, page: 0 }))
  },
  resetFilter: () => {
    set(state => ({...state, filter: initialState.filter, page: 0 }))
  },
  getMetalProducts: async (params, page) => {
    set(state => ({...state, isTableLoading: true}))
    const response = await metalProducts_api.metalProducts({...params, page: page + 1})
      .finally(() => set(state => ({...state, isTableLoading: false})));

    const { status, data } = response;

    if (!status || !data) {
      throw new Error("No status code or data returned from server.");
    }

    if (status !== 200) {
      throw new Error(data);
    }

    set((state) => ({
      ...state,
      tableData: data
    }));
  },
  createMetalProduct: async (payload: ICreateMetalProductForm) => {
    const response = await metalProducts_api.createMetalProduct({
      ...payload
    });

    const { status, data } = response;

    if (!status || !data) {
      throw new Error("No status code or data returned from server.");
    }

    if (status !== 201) {
      throw new Error(data);
    }

    set((state) => ({
      ...state,
      tableData: {
        ...state.tableData,
        data: [data.data, ...state.tableData.data]
      }
    }));

    return data;

  },
  deleteMetalProduct: async (id) => {
    const response = await metalProducts_api.deleteMetalProduct(id);

    const { status, data } = response;

    if (status !== 204) {
      throw new Error(data);
    }
    set((state) => ({
      ...state,
      tableData: {
        ...state.tableData,
        data: state.tableData.data.filter((item) => item.id !== id),
      },
    }));
  },
  getMetalProduct: async (id) => {
    const response = await metalProducts_api.getMetalProduct(id);

    const { status, data } = response;

    if (status !== 200) {
      throw new Error(data);
    }
    set((state) => ({
      ...state,
      // tableData: {
      //   ...state.tableData,
      //   data: state.tableData.data.filter((item) => item.id !== id),
      // },
    }));
    return data;
  },
  editMetalProduct: async (id, payload) => {
    const response = await metalProducts_api.editMetalProduct(id, payload);

    const { status, data } = response;


    set((state) => ({
      ...state,
      tableData: {
        ...state.tableData,
        data: state.tableData.data.map((item) => item.id === id ? data.data : item),
      },
    }));
    return data;
  }
}));

export const useMetalProductStoreOut = useMetalProductStore.getState();