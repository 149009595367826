import {Avatar, Box, Divider, ListItemIcon, Menu, MenuItem, Typography} from "@mui/material";
import {useUserStore} from "store/userStore";
import {useState} from "react";
import DropdownList from "../DropdownList";

const UserProfile = () => {
  const user = useUserStore(state => state.user)

  const getFirstLetters = () => {
    if(user?.first_name && user?.last_name)
      return user?.first_name[0] + user?.last_name[0]

    return user?.email[0] + user?.email[1]
  }
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  ;
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return <>
    <Box
      onClick={handleClick}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        cursor: 'pointer',
        height: '45px',
        width: '45px',
        backgroundColor: 'lightpink',
      }}
    >
      <Typography
        sx={{
        textTransform: 'uppercase',
        fontWeight: 'bold',
      }}>{getFirstLetters()}</Typography>
    </Box>
    <DropdownList handleClose={handleClose} anchorEl={anchorEl}/>
  </>
}

export default UserProfile