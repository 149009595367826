import {api} from "../index";
import {IEditUserForm, ILogin, IRegister} from "../../interfaces/general";



export const profile_api = {
  profile: async () => {
    return await api.get(`profile`);
  },
  profileSettings: async (body: IEditUserForm) => {
    return await api.patch(`profile/setting`, body, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  },



};

