import {
  Box,
  TextField
} from "@mui/material";
import {useProjectStore} from "../../../../../../../../store/projectStore";
import {useTranslation} from "react-i18next";
import React, {memo} from "react";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const ChoosenFooter = () => {
  const { t } = useTranslation()

  //
  const choosenItem = useProjectStore(state => state.choosenAccessory)
  //
  const setAccessoryQuantity = useProjectStore(state => state.setAccessoryQuantity)
  const accessoryQuantity = useProjectStore(state => state.accessoryQuantity)


  const handleChangeTrayQuantity = (event: any) => {
    if(!Number.isNaN(+event.target.value % 1)) {
      setAccessoryQuantity(+event.target.value === 0 ? '' : +event.target.value);
    }
  }
  const handleChangeLength = (type: 'down' | 'up') => {

    let value = type === 'down'
      ? (+accessoryQuantity - 1) > 0 ? (+accessoryQuantity - 1) : accessoryQuantity
      : (+accessoryQuantity + 1)

    setAccessoryQuantity(value);
  }

  return <Box sx={{
    marginTop: '10px',
    borderTop: '1px solid #ccc',
    paddingTop: '10px',
    display: 'flex',
    justifyContent: 'flex-end',
    height: '100%',
    '& .MuiInputBase-input, .MuiFormLabel-root': {
      fontSize: '18px!important',
    }
  }}>
      <TextField
        label={t('project.trayQuantity')}
        value={accessoryQuantity}
        type={'text'}
        onChange={handleChangeTrayQuantity}
        variant="standard"
        onBlur={() => {
          if(accessoryQuantity === '') {
            setAccessoryQuantity(1)
          }
        }}
        InputProps={{
          endAdornment: (
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              "& .MuiSvgIcon-root" : {
                fontSize: '14px',
                cursor: 'pointer'
              }
            }}>
              <ArrowDropUpIcon onClick={() => handleChangeLength('up')}/>
              <ArrowDropDownIcon  onClick={() => handleChangeLength('down')}/>
            </Box>
          ),

        }}
      />
  </Box>

}

export default memo( ChoosenFooter)