export const translationUa = {
  header: {
    account: "Аккаунт",
    projects: "Проекти",
    users: "Користувачі",
    logout: "Вийти",
    settings: "Налаштування",
    admins: "Адміни",
    resetPassword: "Змінити пароль",
    handBooks: 'Довідники',
    lids: 'Кришки/перегородки',
    trays: 'Лотки/аксесуари',
    metalProducts: 'Металопродукція',
    coatings: 'Покриття',
    recommendations: 'Рекомендації',
    files: 'Історія файлів',
    images: 'Зображення',
    emailSettings: 'Список електронних адрес',
  },
  error: {
    error: 'Помилка',
    required: "* Обов'язкове поле",
    451: 'Аккаунт деактивований',
    452: 'Аккаунт тимчасово заблокований',
    901: 'Мінімальна довжина символів',
    902: 'Максимальна довжина символів',
    invalidEmail: 'Невірна електронна пошта',
    somethingWentWrong: 'Щось пішло не так',
  },
  deleteHandbook: {
    title: 'Видалити довідник',
    submissionText: 'Ви впевнені, що хочете видалити довідник?',
    error: 'Помилка при видаленні довідника',
    success: 'Довідник успішно видалений',
  },
  editHandbook: {
    success: 'Довідник успішно відредагований',
    error: {
      default: 'Помилка при редагуванні довідника',
    },
  },
  handBook: {
    title: 'Довідник',
    create: 'Створити довідник',
    edit: 'Редагувати довідник',
    submitCreate: 'Створити',
    submitEdit: 'Редагувати',
    submitDelete: 'Видалити',
    name: 'Назва',
    hide: 'Приховати',
    createHandbook: 'Створити довідник',
    description: 'Опис',
    locale: 'Локалізація',
    hidden: 'Вимкнутий',
    nonHidden: 'Увімкнутий',
    type: {
      title: 'Тип',
      album: 'Альбом',
      catalog: 'Каталог',
      certificate: 'Сертифікат',
    },
    error: {
      default: 'Помилка при редагуванні довідника',
      filesLength: 'Ви можете завантажити лише 1 файл',
      fileRequired: 'Файл обов\'язковий',
      400: {
        file: 'Файл не відповідає критеріям',
      }
    },
  },
  signUp: {
    title: "Реєстрація",
    first_name: "Ім'я",
    last_name: "Прізвище",
    email: "Електронна пошта",
    password: "Пароль",
    patronymic: "По батькові",
    phone_number: "Номер телефону",
    password_old: "Старий пароль",
    passwordConfirm: "Підтвердити пароль",
    submit: "Зареєструватися",
    haveAccount : "Вже маєте аккаунт?",
    login: "Увійти",
    success: 'Ви успішно зареєструвалися',

    error: {
      userExists: 'Користувач з такою електронною поштою вже існує',
      passwordConfirm: 'Паролі не співпадають',
      phone_number: 'Невірний номер телефону',
      email: 'Невірна електронна пошта',
      password: 'Пароль повинен бути не менше 6 символів',
      first_name: "Ім'я повинно бути не менше 2 символів",
      last_name: "Прізвище повинно бути не менше 2 символів",
      patronymic: "По батькові повинно бути не менше 2 символів",
      company: "Компанія повинна бути не менше 2 символів",
      minLength: 'Поле повинно бути більше 6 символів',
      maxLength: 'Поле повинно бути не більше 20 символів',
      emailPattern: 'Невірна електронна пошта',
      400: {
        email: 'Електронна пошта вже використовується',
        phone_number: 'Номер телефону вже використовується',
      }

    }
  },
  signIn: {
    title: "Увійти",
    login: "Логін / Номер телефону",
    password: "Пароль",
    submit: "Увійти",
    dontHaveAccount: "Не маєте аккаунту?",
    signUp: "Зареєструватися",
    forgotPassword: "Забули пароль?",
    error: {
      login: "Невірний логін або пароль",
      234: 'Забагато спроб входу, спробуйте пізніше',
      googleFail: 'Помилка при вході через Google',
      accountNotRegistered: 'Користувача з такою електронною поштою не існує',
    },
    success: 'Ви успішно увійшли в систему'
  },
  forgotPassword: {
    title: "Забули пароль",
    email: "Електронна пошта",
    submit: "Відправити",
    code: "Код",
    new_password: "Новий пароль",
    success: {
      email: 'Код успішно відправлено на електронну пошту',
      code: 'Код правильний, введіть пароль',
      password: 'Пароль успішно змінено',
    },
    error: {
      email: 'Користувача з такою електронною поштою не існує',
      code: 'Невірний код',
      new_password: 'Спробуйте інший пароль',
    }
  },
  editUser: {
    title: "Редагувати користувача",
    first_name: "Ім'я",
    last_name: "Прізвище",
    email: "Електронна пошта",
    phone_number: "Номер телефону",
    patronymic: "По батькові",
    company: "Компанія",
    permissions: "Права",
    submit: "Зберегти",
    success: 'Користувач успішно відредагований',
    blocked: "Заблокований",
    password: "Пароль",
    active: "Активний",
    role: "Роль",
    error: {
      default: 'Помилка при редагуванні користувача',
    }
  },
  usersTable: {
    title: "Користувачі",
    id: "ID",
    email: "Електронна пошта",
    first_name: "Ім'я",
    last_name: "Прізвище",
    patronymic: "По батькові",
    last_login: "Останній вхід",
    phone_number: "Номер телефону",
    role: "Роль",
    company: "Компанія",
    active: "Активний",
    nonActive: "Неактивний",
    status: "Статус",
    blocked: "Заблокований",
    nonBlocked: "Не заблокований",
  },
  createUser: {
    title: "Створити користувача",
    createAdmin: "Створити адміна",
    first_name: "Ім'я",
    last_name: "Прізвище",
    email: "Електронна пошта",
    phone_number: "Номер телефону",
    password: "Пароль",
    company: "Компанія",
    patronymic: "По батькові",
    submit: "Створити",
    permissions: "Права",
    success: 'Користувач успішно створений',
    error: {
      default: 'Помилка при створенні користувача',
      400: {
        email: 'Користувач з такою електронною поштою вже існує',
        phone_number: 'Користувач з таким номером телефону вже існує',
      }
    },
    role: "Роль",
  },
  deleteUser: {
    title: 'Видалити користувача',
    submissionText: 'Ви впевнені, що хочете видалити користувача?',
    cancel: 'Скасувати',
    submit: 'Видалити',
    error: 'Помилка при видаленні користувача',
    success: 'Користувач успішно видалений',
  },
  resetPassword: {
    title: "Змінити пароль",
    password_old: "Старий пароль",
    password: "Новий пароль",
    passwordConfirm: "Підтвердити новий пароль",
    submit: "Зберегти",
    success: 'Пароль успішно змінено',
    error: {
      default: 'Пароль не вірний',
    }
  },
  projects: {
    create: 'Створити проект',
  },
  noTableData: {
    title: 'Немає даних',
  },
  coatings: {
    title: 'Покриття',
    create: 'Створити покриття',
    edit: 'Редагувати покриття',
    id: 'ID',
    code: 'Код',
    descriptionUa: 'Місце монтажу (укр)',
    descriptionEn: 'Місце монтажу (англ)',
  },
  createCoating: {
    title: 'Створити покриття',
    submit: 'Створити',
    success: 'Покриття успішно створене',
  },
  deleteCoating: {
    title: 'Видалити покриття',
    submissionText: 'Ви впевнені, що хочете видалити покриття?',
    cancel: 'Скасувати',
    submit: 'Видалити',
    error: 'Помилка при видаленні покриття',
    error430: "Покриття використовується",
    error430add: "Видалість спочатку сутності, які використовують це покриття",
    errorCoatingUsing: "Це покриття використовується в {{value}} сутностях, ви справді хочете видалити його?",
    success: 'Покриття успішно видалене',
  },
  recommendation: {
    title: 'Рекомендації',
    create: 'Створити рекомендацію',
    edit: 'Редагувати рекомендацію',
    id: 'ID',
    code: 'Код',
    installation: 'Місце монтажу',
    product: 'Продукт',
    place_installation_uk: 'Місце монтажу (укр)',
    place_installation_en: 'Місце монтажу (англ)',
    coating: 'Покриття',
  },
  createRecommendation: {
    title: 'Створити рекомендацію',
    submit: 'Створити',
    success: 'Рекомендація успішно створена',
  },
  deleteRecommendation: {
    title: 'Видалити рекомендацію',
    submissionText: 'Ви впевнені, що хочете видалити рекомендацію?',
    cancel: 'Скасувати',
    submit: 'Видалити',
    error: 'Помилка при видаленні рекомендації',
    success: 'Рекомендація успішно видалена',
  },
  lids: {
    title: 'Кришки',
    create: 'Створити кришку',
    edit: 'Редагувати кришку',
    id: 'ID',
    name: 'Назва',
    name_en: 'Назва (англ)',
    name_uk: 'Назва (укр)',
    article: 'Артикул',
    length: 'Довжина',
    width: 'Ширина',
    height: 'Висота',
    thickness: 'Товщина',
    weight: 'Вага',
    coating: 'Покриття',
    unit_of_measurement: 'Одиниця виміру',
    article_prefix_1: 'Префікс артикулу 1',
    article_prefix_2: 'Префікс артикулу 2',
    type: "Кришка / перегородка",
    lid: "Кришка",
    partition: "Перегородка",
  },
  createLid: {
    title: 'Створити кришку',
    submit: 'Створити',
    success: 'Кришка успішно створена',
  },
  deleteLid: {
    title: 'Видалити кришку',
    submissionText: 'Ви впевнені, що хочете видалити кришку?',
    cancel: 'Скасувати',
    submit: 'Видалити',
    error: 'Помилка при видаленні кришки',
    success: 'Кришка успішно видалена',
  },
  metalProducts: {
    title: 'Кришки',
    create: 'Створити метоловиріб',
    edit: 'Редагувати метоловиріб',
    id: 'ID',
    name: 'Назва',
    name_en: 'Назва (англ)',
    name_uk: 'Назва (укр)',
    article: 'Артикул',
    length: 'Довжина',
    width: 'Ширина',
    height: 'Висота',
    thickness: 'Товщина',
    weight: 'Вага',
    coating: 'Покриття',
    unit_of_measurement: 'Одиниця виміру',
    article_prefix_1: 'Префікс артикулу 1',
    article_prefix_2: 'Префікс артикулу 2',
  },
  createMetalProduct: {
    title: 'Створити метоловиріб',
    submit: 'Створити',
    success: 'Металовиріб успішно створений',
  },
  deleteMetalProduct: {
    title: 'Видалити виріб з металу',
    submissionText: 'Ви впевнені, що хочете видалити виріб з металу?',
    cancel: 'Скасувати',
    submit: 'Видалити',
    error: 'Помилка при видаленні виробу з металу',
    success: 'Виріб з металу успішно видалений',
  },
  trays: {
    title: 'Кришки',
    create: 'Створити лоток',
    edit: 'Редагувати лоток',
    id: 'ID',
    name: 'Назва',
    name_en: 'Назва (англ)',
    name_uk: 'Назва (укр)',
    article: 'Артикул',
    length: 'Довжина',
    type: "Лоток чи аксесуар",
    tray: "Лоток",
    accessory: "Аксесуар",
    width: 'Ширина',
    height: 'Висота',
    thickness: 'Товщина',
    weight: 'Вага',
    coating: 'Покриття',
    angle: 'Кут',
    lids: 'Кришки',
    unit_of_measurement: 'Одиниця виміру',
    article_prefix_1: 'Префікс артикулу 1',
    article_prefix_2: 'Префікс артикулу 2',
    accessories: 'Аксесуари',
    partitions: 'Перегородки',

  },
  createTray: {
    title: 'Створити лоток',
    submit: 'Створити',
    success: 'Лоток успішно створений',
  },
  editTray: {
    success: 'Лоток успішно відредагований',
    error: {
      default: 'Помилка редагування лотка'
    }
  },
  deleteTray: {
    title: 'Видалити лоток',
    submissionText: 'Ви впевнені, що хочете видалити лоток?',
    cancel: 'Скасувати',
    submit: 'Видалити',
    error: 'Помилка при видаленні лотка',
    success: 'Лоток успішно видалений',
  },
  export: {
    success: {
      lid: 'Файл з кришками готовий до завантаження',
      tray: 'Файл з лотками готовий до завантаження',
      metalProduct: 'Файл з металевими виробами готовий до завантаження',
    },
    error: {
      lid: 'Помилка при експорті файлу з кришками',
      tray: 'Помилка при експорті файлу з лотками',
      metalProduct: 'Помилка при експорті файлу з металевими виробами',
    }
  },
  import: {
    success: {
      lid: 'Файл з кришками успішно імпортований',
      tray: 'Файл з лотками успішно імпортований',
      metalProduct: 'Файл з металевими виробами успішно імпортований',
    },
    error: {
      lid: 'Помилка при імпорті файлу з кришками',
      tray: 'Помилка при імпорті файлу з лотками',
      metalProduct: 'Помилка при імпорті файлу з металевими виробами',
    }
  },
  images: {
    title: 'Зображення',
    createImage: 'Створити зображення',
    create: 'Створити зображення',
    edit: 'Редагувати зображення',
    id: 'ID',
    name: 'Назва',
    description: 'Опис',
    image: 'Зображення',
    submitCreate: 'Створити',
    addImages: 'Додати зображення',
    submitEdit: 'Редагувати',
    success: 'Зображення успішно створене',
    error: {
      default: 'Помилка при створенні зображення',
      filesLength: 'Ви можете завантажити лише 1 файл',
      fileRequired: 'Файл обов\'язковий',
      400: {
        file: 'Файл не відповідає критеріям',
      }
    }
  },
  deleteImage: {
    title: 'Видалити зображення',
    submissionText: 'Ви впевнені, що хочете видалити зображення?',
    cancel: 'Скасувати',
    submit: 'Видалити',
    error: 'Помилка при видаленні зображення',
    success: 'Зображення успішно видалене',

  },
  files: {
    title: 'Файли',
    id: 'ID',
    model: 'Модель',
    file_status: 'Статус',
    file_type: 'Тип',
    file_name: 'Назва',
    file_size: 'Розмір',
    action: 'Маніпуляція',
    error: 'Помилка',
    created_at: 'Дата створення',
    status: {
      1: 'В черзі',
      2: 'В обробці',
      3: 'Завершено',
      4: 'Помилка',
    },


    export: {
      successProcess: 'Процес експорту запущено',
      error: 'Помилка при експорті файлу',
    },
    import: {
      successProcess: 'Процес імпорту запущено',
      error: 'Помилка при імпорті файлу',
    }
  },
  unit_of_measurement: {
    meter: 'м',
    millimeters: 'мм',
    units: 'шт',
  },
  project: {
    withLid: 'З кришкою',
    noLid: 'Без кришки',
    withPartition: 'З перегородкою',
    noPartition: 'Без перегородки',
    isLid: 'Присутість кришки',
    complete: 'Комплектація',
    addToCart: 'Додати до кошторису',
    projectName: 'Назва проекту',
    projectHelper: 'Потрібна допомога?',
    newProject: "Новий проєкт",
    createError: 'Помилка при створенні проєкту',
    createSuccess: 'Проєкт успішно створений',
    projectUpdateFailed: 'Помилка при оновленні проєкту',
    projectUpdated: 'Проєкт успішно оновлений',
    trayQuantity: 'Кількість',
    projectLength: 'Довжина траси (м)',
    formulaRequired: "Ці аксесуари є необхідні для даного лотка",
    successDelete: 'Елемент успішно видалений',
    errorDelete: 'Помилка при видаленні елементу',
    positionAddSuccess: 'Елемент успішно доданий',
    positionAddError: 'Помилка при додаванні елементу',
    positionEditSuccess: 'Елемент успішно відредагований',
    positionEditError: 'Помилка при редагуванні елементу',
    editCart: 'Редагувати елемент',
    createErrorDeactivated: 'Помилка при створенні проєкту, аккаунт деактивований',
    trayView: "Лотки",
    accessoryView: "Аксесуари",
    disabledAccessories: "Ви не можете додавати аксесуари з пустим кошторисом",
    chooseTypeLid: "Виберіть тип кришки",
    noLidFound: "Кришка для цього аксесуара не знайдена",
    noLidFoundForTray: "Кришка для цього лотка не знайдена",
    noPartitionFoundForTray: "Перегородка для цього лотка не знайдена",
    chooseTypePartition: "Виберіть тип перегородки",
    noPartitionFound: "Перегородка для цього аксесуара не знайдена",
    recommendation: {
      placementTitle: 'Виберіть розміщення лотка',
      titleChooseRec: "Виберіть промисловість",
      successChoose: "Хороший вибір, тепер ви можете вибрати підхожий лоток",
    }
  },
  common: {
    edit: "Редагувати",
    delete: "Видалити",
    cancel: "Скасувати",
    save: "Зберегти",
    search: "Пошук",
    reset: "Скинути",
    fieldForSearch: "Поле",
    showMore: "Показати більше",
    showLess: "Показати менше",
    import: "Імпортувати",
    export: "Експортувати",
    external: "Зовнішній",
    internal: "Внутрішній",
    millimeters: "мм",
    tray: "Лоток",
    lid: "Кришка",
    metalProduct: "Металевий виріб",
    handbook: "Довідник",
    notification: "Повідомлення",
    addNumber: "Додати значення",
    editNumbers: "Редагувати значення",
    meters: "Метри",
    meter: "Метр",
    unit: "Штука",
    units: "Штуки",
    allPermissions: "Ви вже маєте вже всі права",
    userPermissions: "Користувач не може мати права адміна",
    width: "Ширина",
    height: "Висота",
    length: "Довжина",
    thickness: "Товщина",
    type: "Тип",
    angle: "Кут",
    coating: "Покриття",
    article: "Артикул",
    description: "Опис",
    weight: "Вага",
    color: "Колір",
    newProject: "Новий проект",
    use: "Застосувати",
    unit_of_measurement: "Од. вим.",
    emails_saved: "Електронні адреси успішно збережені",
    emailsSystem: "Системні електронні адреси",
    email: "Електронна адреса",
    count: "К-сть",
    send_to_emails: "Відправити на email для опрацювання",
    send: "Відправити",
    download: "Завантажити",
    emailSent: "Повідомлення успішно відправлено",
    exportWithEmail: "Експортувати на електронну адресу",
    computation: "Розрахунок",
    item: "Предмет",
    chooseColor: "Виберіть колір",
    users: "Користувачі",
    searchBy: {
      id: "ID",
      title: 'Пошук за',
      email: "email",
      active: "Статусом",
      blocked: "Блокування",
      first_name: "ім'ям",
      last_name: "прізвищем",
      phone_number: "телефоном",
      company: "компанією",
      patronymic: "по батькові",
      status: "Статус",
      name: "назвою",
      locale: "Локалізація",
      type: "Тип",
      description: "описом",
      role: "Роль",
      unit_of_measurement: "одиницею виміру",
      length: "довжиною",
      width: "шириною",
      height: "висотою",
      thickness: "товщиною",
      weight: "вагою",
      coating: "покриттям",
      article: "артикулом",
      code: "кодом",
      installation: "місцем монтажу",
      product: "продуктом",
      angle: "кутом",
      description_en: "описом (англ)",
      description_uk: "описом (укр)",
      save: "Зберегти",
    },
    chooseElement: 'Виберіть елемент',

  },
  permissions: {
    "imageLoad": "Завантажити зображення",
    "imageDestroy": "Видалити зображення",
    "imageUpdate": "Оновити зображення",
    "imageCreate": "Створити зображення",
    "imageList": "Список зображень",
    "metalProductDestroy": "Видалити металевий виріб",
    "metalProductUpdate": "Оновити металевий виріб",
    "metalProductCreate": "Створити металевий виріб",
    "metalProductList": "Список металевих продуктів",
    "metalProductImport": "Імпортувати металевий виріб",
    "metalProductExport": "Експортувати металевий виріб",
    "coatingDestroy": "Видалити покриття",
    "coatingUpdate": "Оновити покриття",
    "coatingCreate": "Створити покриття",
    "coatingList": "Список покриттів",
    "recommendationDestroy": "Видалити рекомендацію",
    "recommendationUpdate": "Оновити рекомендацію",
    "recommendationCreate": "Створити рекомендацію",
    "recommendationList": "Список рекомендацій",
    "lidDestroy": "Видалити кришку",
    "lidUpdate": "Оновити кришку",
    "lidCreate": "Створити кришку",
    "lidList": "Список кришок",
    "lidImport": "Імпортувати кришку",
    "lidExport": "Експортувати кришку",
    "trayDestroy": "Видалити лоток",
    "trayUpdate": "Оновити лоток",
    "trayCreate": "Створити лоток",
    "trayList": "Список лотків",
    "trayImport": "Імпортувати лоток",
    "trayExport": "Експортувати лоток",
    "handBookDestroy": "Видалити посібник",
    "handBookUpdate": "Оновити посібник",
    "handBookCreate": "Створити посібник",
    "handBookList": "Список посібників",
    "userDestroy": "Видалити користувача",
    "userUpdate": "Оновити користувача",
    "userCreate": "Створити користувача",
    "userList": "Список користувачів",
    "systemLogList": "Список системних журналів",
    "sendMessageAboutNewRegistration": "Надіслати повідомлення про нову реєстрацію",
    "fileExport": "Експортувати файл",
    "fileImport": "Імпортувати файл",
    "fileHistory": "Історія файлу",
    "systemConfiguration": "Системна конфігурація",
  }
};
