import { socketEvents } from "./events";
import {pusher} from "../api/socket";
let channelName = 'private-message'

export const initSockets = ({ setValue }: any) => {

  const channel = pusher.subscribe(channelName);
  socketEvents({ setValue, channel });
};


export {}