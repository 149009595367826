import {api} from "../index";

export const system_api = {
  getSystem: async () => {
    return await api.get(`system-configurations`)
  },
  editSystem: async (payload: any) => {
    return await api.patch(`system-configurations`, payload)
  },

};

