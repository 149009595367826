import {StyledFormControlLabel} from "./style";
import {Box, Button, Checkbox, Grid, TextField, Typography} from "@mui/material";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useImagesStore} from "../../../../../store/imagesStore";
import {IImage} from "../../../../../interfaces/general";
import {env} from "../../../../../constants";

interface IProps {
  images: IImage[];
}

const ChooseImageList
  = ({ images } : IProps ) => {
  const { t } = useTranslation();

  const choosedImages = useImagesStore(state => state.modal.choosedImages)
  const setChoosedImages = useImagesStore(state => state.setChoosedImages)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
    setChoosedImages(choosedImages.includes(id) ? choosedImages.filter(el => el !== id) : [...choosedImages, id]);
  };



  return (
    <Box
      sx={{
        width: '30vw',
        minWidth: '500px',
        maxWidth: '540px',
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
        height: '60vh',
        overflowY: 'auto',
        paddingBottom: '15px',
        '@media (max-width: 600px)': {
          minWidth: '300px',
        }
      }}
    >
      {images?.map((image: IImage) => (
        <Box
          key={image.id}
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: '80px',
            gap: '15px',
            borderBottom: '2px solid #F5F5F5',
          }}
        >
          <Checkbox
            checked={choosedImages?.includes(image.id)}
            onChange={(e) => handleChange(e, image.id)}
          />
          <Box
            component={'img'}
            src={env.REACT_APP_DEV_BASE_API_URL +'/'+ image.file_url}
            sx={{
              height: '70px',
              width: '70px',
            }}
          />
          <Typography>
            {image.file_name}
          </Typography>
      </Box>))}


    </Box>
  )
}
export default ChooseImageList
