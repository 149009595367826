import React, {useEffect, useState} from "react";
import useAsyncWrapper from "../../hooks/useAsyncWrapper";
import {useProjectsStore} from "../../store/projectsStore";
import ProjectInfo from "../../components/UI/ProjectInfo";
import {useNavigate, useParams} from "react-router-dom";
import {RouteUrls} from "../../interfaces/enum";
import useToast from "../../hooks/useToast";
import {useCoatingsStore} from "../../store/coatingsStore";
import Loader from "../../components/UI/Loader";
import TableLoading from "../../components/UI/TableLoading";
import {useHelperStore} from "../../store/helperStore";
import {useProjectStore} from "../../store/projectStore";

let prevFilter = {}
const Project = () => {
  const { id } = useParams();
  const nav = useNavigate()
  const { errorToast } = useToast()

  const [isLoading, setIsLoading] = useState(true);

  const filterSelect = useCoatingsStore(state => state.filterSelect);
  const getCoatings = useCoatingsStore(state => state.getCoatings);

  const getProject = useProjectsStore(state => state.getProject)
  const filter = useProjectsStore(state => state.filter);
  const setPositions = useProjectStore(state => state.setPositions);
  const setColor = useProjectStore(state => state.setColor);
  const resetStore = useProjectStore(state => state.resetStore);


  const setIsOpen = useHelperStore(state => state.setMenuOpen)

  const getProjectHandler = useAsyncWrapper(getProject);
  const getCoatingsHandler = useAsyncWrapper(getCoatings);


  useEffect(() => {
    (async () => {
        setIsLoading(true);
        await getCoatingsHandler([filterSelect, 0])
        if(id) {
            const { error, data } = await getProjectHandler([id]);
            if(!error) {
                setIsOpen(false)
                setPositions(data?.data?.project_positions)
                setColor(data?.data?.color)
            } else {
              errorToast(error)
              nav(RouteUrls.PROJECTS)
            }
        } else {
          nav(RouteUrls.PROJECTS)
        }
        setIsLoading(false);
    })();

  }, [id]);

  useEffect(() => {

    return () => {
      resetStore()
    }
  },[])

  return (
      <>
        {isLoading ? <TableLoading/> :
        <ProjectInfo/>
        }
      </>
  );
};

export default Project;
