import {Box, Table, TableContainer} from "@mui/material";
import TableLoading from "../../../../TableLoading";
import NoTableData from "../../../../NoTableData";
import React, {memo, useEffect} from "react";
import ResultsTableBody from "./components/ResultsTableBody";
import ResultsTableHead from "./components/ResultsTableHead";
import {useProjectStore} from "../../../../../../store/projectStore";
import useAsyncWrapper from "../../../../../../hooks/useAsyncWrapper";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";


const ResultListAccessory = () => {
  const resultList = useProjectStore(state => state.accessories)

  const isTableLoading = useProjectStore(state => state.accessoriesLoading)
  const trayAccessory = useProjectStore(state => state.trayAccessory)
  const searchAccessory = useProjectStore(state => state.searchAccessory)
  const thickness = useProjectStore(state => state.currentModelValuesAccessory.thickness)
  const currentTypeAccessory = useProjectStore(state => state.currentTypeAccessory)

  const getsAccessoriesById = useProjectStore(state => state.getsAccessoriesById)
  const getsAccessoriesByIdHandler = useAsyncWrapper(getsAccessoriesById)


  useEffect(() => {
      (async () => {
        const thicknessFilter = { field_name: 'thickness', rule: 'equal', value: thickness || '' }

        const filter = {
          filters: [thicknessFilter],
          perpage: 50,
          page: 1,
          search: searchAccessory,
        }
        if(trayAccessory?.tray_model_parameter?.id) {
          await getsAccessoriesByIdHandler([filter, trayAccessory?.tray_model_parameter?.id, currentTypeAccessory?.id])
        }
      })()
  },[trayAccessory?.id, searchAccessory, thickness, currentTypeAccessory])

  return (<Box>
    <TableContainer sx={{
      borderRadius: ' 0 0 12px 12px',
      flexGrow: 1,
      position: 'relative',
      // height: '100%',
      height: 'calc(38vh)',
      border: '1px solid #ccc',
      // minWidth: '500px',
      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
      "@media (max-width: 1100px)": {
        height: 'calc(25vh)',
      },
      "@media (max-width: 900px)": {
        maxHeight: '50vh',
        height: '100%',
        minHeight: '20vh',
      }
    }}>
        <Table
          stickyHeader
          aria-label="sticky table"
          sx={{
            // borderSpacing: '0 8px',
            height: '100%',
            "&.MuiTable-root": {
              height: 'auto',
            }

          }}
        >
          <ResultsTableHead />
          {isTableLoading ? <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={0}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <CircularProgress size={30} />
          </Stack> : resultList.length ? <ResultsTableBody data={resultList} /> : <NoTableData />}
        </Table>
    </TableContainer>
  </Box>)
}

export default memo(ResultListAccessory)