import {styled} from "@mui/system";
import {DialogActions, DialogTitle} from "@mui/material";

export const StyledDialogTitle = styled(DialogActions)({
  display: 'flex',
  justifyContent: 'space-between',
  borderBottom: '2px solid #373737',
  padding: '20px',
  marginBottom: '10px',
  fontWeight: 'bold',
})