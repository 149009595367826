import {Box, Button, Dialog, DialogContent, DialogTitle, Typography} from "@mui/material";
import CloseSvg from "../../../../assets/svgs/common/CloseSvg";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import FileUpload from "../../FileUpload";
import useToast from "../../../../hooks/useToast";
import ButtonLoadingWrapper from "../../../Wrappers/ButtonLoading";
import ProjectComputation from "../../ProjectInfo/components/ProjectComputation";
import ChoosenItem from "../../ProjectInfo/components/Trays/ChoosenItem";
import {useProjectStore} from "../../../../store/projectStore";
import ChoosenAccessory from "../../ProjectInfo/components/Accessory/ChoosenAccessory";

interface ISettingsProps {
  open: boolean;
  handleClose: () => void;
}
const ChoosenItemModal = ({ handleClose, open} : ISettingsProps ) => {
  const { t } = useTranslation()

  const setChoosenItem = useProjectStore(state => state.setChoosenItem);
  const setChoosenAccessory = useProjectStore(state => state.setChoosenAccessory);
  const accessoryView = useProjectStore(state => state.accessoryView);


  useEffect(() => {
    if(!open) {
      setChoosenItem(null)
      setChoosenAccessory(null)
    }
  },[open])

  return <Dialog
    open={open}
    onClose={handleClose}
    fullScreen
    sx={{
      '& .MuiBackdrop-root': {
        backgroundColor: 'rgba(0,0,0,0.1)' // Try to remove this to see the result
      },
      '& .MuiPaper-root': {
        width: '100%'
      }

    }}
  >
    <DialogTitle sx={{
      display: 'flex',
      justifyContent: 'space-between',
      fontWeight: 600,
      color:'#373737',
      borderBottom: '2px solid #373737',
      marginBottom: '20px',
    }}>
      {t('common.item')}
      <Box onClick={handleClose}>
        <CloseSvg />
      </Box>
    </DialogTitle>


    <DialogContent sx={{
      padding: '0px 5px',
    }}>
      {accessoryView ? <ChoosenAccessory closeModal={handleClose} /> : <ChoosenItem closeModal={handleClose}/>}
    </DialogContent>
  </Dialog>

}

export default ChoosenItemModal