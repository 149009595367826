import {
  Box,
  Button,
} from "@mui/material";
import {StyledHeaderWrapper} from "./style";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {useUsersTableStore} from "../../../../../../store/usersTableStore";
import CreateUser from "../../../../Modals/CreateUser";
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import Filter from "../Filter";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import usePermission from "../../../../../../hooks/usePermission";
import {PermissionsUser} from "../../../../../../interfaces/enum";
interface IProps {

}

const UsersTableHeader = ( {  }: IProps ) => {
  const { t } = useTranslation();
  const [createUserModalOpen, setCreateUserModalOpen] = useState(false);
  const { hasPermission } = usePermission()

  const filters = useUsersTableStore(state => state.filter.filters);
  const resetFilter = useUsersTableStore(state => state.resetFilter)
  const isFilters = filters?.length > 0 && filters?.some(el => el.value !== "")

  const handleCloseModal = () => {
    setCreateUserModalOpen(false)
  }

  const handleOpenModal = () => {
    setCreateUserModalOpen(true)
  }

  return (
    <StyledHeaderWrapper>
      <Filter />
      <Box sx={{
        display: 'flex',
        gap: '15px'
      }}>
        <Button
          onClick={handleOpenModal}
          variant={'contained'}
          disabled={!hasPermission(PermissionsUser.userCreate)}
          color={'error'}
          sx={{
            padding: '8px 10px',
            fontSize: '12px',
            fontWeight: '600',
            borderRadius: '8px',
            lineHeight: '1.5em'
          }}
          startIcon={<AddBoxOutlinedIcon/>}
        >
          {t('createUser.title')}
        </Button>
        {isFilters && <Button
            onClick={resetFilter}
            variant={'outlined'}
            color={'error'}
            sx={{
              padding: '8px 10px',
              fontSize: '12px',
              fontWeight: '600',
              borderRadius: '8px',
              lineHeight: '1.5em'
            }}
            startIcon={<RestartAltIcon/>}
        >
          {t('common.reset')}
        </Button>}
      </Box>
      <CreateUser open={createUserModalOpen} handleClose={handleCloseModal} />

    </StyledHeaderWrapper>
  )
}

export default UsersTableHeader
