import {styled} from "@mui/system";
import {FormControlLabel} from "@mui/material";

const StyledFormControlLabel = styled(FormControlLabel)({
  width: "100%",
  margin: 0,
  '& .MuiInputBase-root': {
    height: "50px"
  }

});


export { StyledFormControlLabel };
