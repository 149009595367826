import { create } from "zustand";
import {
  IFilter, IHandbookCreate,
  IResponseTable, Product,

} from "../interfaces/general";

import {handbooks_api} from "../api/apis/handbooks.api";
import {files_api} from "../api/apis/files.api";
import {coatings_api} from "../api/apis/coatings.api";

interface IFilesState {
  isTableLoading: boolean,
  tableData: IResponseTable
  filter: IFilter,
  page: number,
}
interface IFilesStore extends IFilesState{
  setPage: (page: number) => void
  setFilter: (filter: IFilter) => void
  importFile: (type: Product, file: any) => Promise<any>,
  exportFile: (model: Product) => Promise<any>,
  getFiles: (params: IFilter, page: number) => Promise<any>,

}

const initialState = {
  tableData: {
    data: [],
    meta: {
      total: 0,
      current_page: 0,
      last_page: 0,
      perpage: 0,
    }
  },
  filter: {
    filters: [],
    perpage: 15,
    page: -1,
  },
  page: 0,
  isTableLoading: true,
}

export const useFilesStore = create<IFilesStore>((set) => ({
  ...initialState,
  setPage: (page) => {
    set(state => ({...state, page}))
  },
  setFilter: (filter) => {
    set(state => ({...state, filter, page: 0 }))
  },
  importFile: async (model, file) => {
    set(state => ({...state, isTableLoading: true}))

    const formData = new FormData();
    formData.append('file', file)
    formData.append('model', model)

    const response = await  files_api.import(formData)
      .finally(() => set(state => ({...state, isTableLoading: false})));

    const { status, data } = response;

    if (!status || !data) {
      throw new Error("No status code or data returned from server.");
    }

    if (status !== 200) {
      throw new Error(data);
    }

    set((state) => ({
      ...state,
    }));
  },
  exportFile: async (model) => {
    const response = await files_api.export({ model });

    const { status, data } = response;

    if (!status || !data) {
      throw new Error("No status code or data returned from server.");
    }

    if (status !== 200) {
      throw new Error(data);
    }

    return data;

  },
  getFiles: async (params, page) => {
    set(state => ({...state, isTableLoading: true}))
    const response = await files_api.files({...params, page: page + 1})
      .finally(() => set(state => ({...state, isTableLoading: false})));

    const { status, data } = response;

    if (!status || !data) {
      throw new Error("No status code or data returned from server.");
    }

    if (status !== 200) {
      throw new Error(data);
    }

    set((state) => ({
      ...state,
      tableData: data
    }));
  },
}));

export const useFilesStoreStoreOut = useFilesStore.getState();